import React from "react";
import FAQImg1 from "../../assets/images/FAQ-1.png";
import NidhiImg1 from "../../assets/images/Nidhi-1.jpg";
import NidhiImg2 from "../../assets/images/Nidhi-2.jpg";
import CheckImg from "../../assets/images/Check.png";
import Footer from "../Navigation/footer";
import { CardImg, Col } from "react-bootstrap";
class Nidhi extends React.Component {
  /*  constructor(){
        super();this.stat
    } */

  render() {
    return (
      <div>
        <section id="page content" className=" p-3  bg-light">
          <div className="sidebar-right background-grey ">
            <div className="container pt-4 ">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row align-items-center">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive"
                          src="https://images.unsplash.com/photo-1553484771-4e29a68540f4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDV8fGVudHJlcHJlbmV1cnxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=500&q=60"
                          alt="Lopamudra"
                        />
                      </div>

                      <div className="col-lg-7">
                        <h5 className="title">Nidhi Company </h5>
                        <hr />
                        <p className=" text-justify">
                          Lopamudra in India has a dedicated team of company
                          secretarial, legal professionals and Chartered
                          Accountants who possess extensive experience in
                          various corporate laws and commercial / legal
                          documentation. Our team is highly business oriented in
                          approach and has experience across sectors and the
                          acumen to provide complete solutions for all corporate
                          secretarial matters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 ">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container  d-flex sidebar-right ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50 ">
                <h1>Registration of Nidhi Company</h1>
                <p className="text-justify">
                  Nidhi Companies in India are created for cultivating the habit
                  of thrift and savings amongst its members. Nidhi companies are
                  allowed to borrow from its members and lend to its members.
                  Therefore, the funds contributed to a Nidhi company are only
                  from its members (shareholders). Hence, Nidhi companies are
                  mainly used to cultivate a habit of savings amongst a group of
                  people.
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={NidhiImg1}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="contetnt1" className="bg-light py-2">
          <div className="container d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid p-3 "
                  src={NidhiImg2}
                  alt="Lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50  ">
                <h1>Nidhi Company?</h1>

                <p>
                  A Public Limited Company is defined under Section 2(71) of the
                  Companies Act, 2013 as:
                </p>
                <div className="text-warning">
                  <ul>
                    <li>a company which is not a private company</li>
                    <li>
                      a company with a minimum paid-up share capital of INR 5
                      lakhs.
                    </li>
                  </ul>
                </div>
                <p className="text-justify m-t-20 m-b-35">
                  <strong className="text-dark">Note :</strong> Under the
                  Companies Act, 2013, a subsidiary company shall be deemed to
                  be a public company if it is not a subsidiary to a private
                  company, even if it is a private company as per its articles.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="documents" className="py-2">
          <div className="container  py-4">
            <div className="d-flex container col-md-7 text-center">
              <h1 className=" mb-4 secu text-dark pb-2">
                Documents Required for Nidhi Company
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow p-3">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3 shadow p-3 ">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="cards">
          <div className="container text-justify pb-3">
            <h1 className="text-center py-3 secu container col-md-7">
              Advantages of Nidhi Company
            </h1>
            <h5 className="text-light text-center py-3">
              {" "}
              Points to make your decision easy
            </h5>
            <div className="row py-3">
              <div className="col-md-4 pb-4">
                <div className="card  h-100  item1">
                  <div className="title text-center">
                    <h4 className="text-light"> Separate Legal Entity</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      A Nidhi Company enjoys the benefit of Separate Legal
                      Identity in the eyes of law which clearly states that
                      assets and liabilities of the business are not the assets
                      and liabilities of the Directors.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 pb-4">
                <div className="card h-100  item2">
                  <div className="title text-center">
                    <h4 className="text-light">
                      <strong> Cultivates Savings</strong>
                    </h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light">
                      A Nidhi Co. encourages its members to save money and
                      encourages a thrifty lifestyle. It is a company wherein
                      members can lend or borrow money and accept financial
                      loans amongst themselves.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 pb-4">
                <div className="card   item3 h-100 ">
                  <div className="title text-center ">
                    <h4 className="text-light">Transferability</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      Interest in Nidhi Company can be easily transferred by
                      transferring its shares. Its board of management is easily
                      transferable as such.This is effected through filing of
                      forms with ROC.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-4 mb-4">
              <div className="col-md-4 pb-4">
                <div className="card  item4 h-100 ">
                  <div id="title" className="title text-center">
                    <h4 className="text-light"> Cheap Borrowings</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light ">
                      Members, when in need, can borrow from the company at bank
                      rates. This can really come handy in case of need and
                      rates are comparatively very cheap in comparison to other
                      sources of finance.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 pb-4">
                <div className="card  h-100 item5">
                  <div className="title text-center">
                    <h4 className="text-light"> Mutual Benefits</h4>
                  </div>
                  <div className="body p-3 ">
                    <p className="text-light">
                      A Nidhi Company operates on principles of mutual benefits.
                      No one member or a group of members get benefited. Nidhi
                      Company allows right to every member to access credit
                      facilities when in need.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 pb-4">
                <div className="card  item6  h-100">
                  <div className="title text-center ">
                    <h4 className="text-light"> Builds Credibility</h4>
                  </div>
                  <div className="body p-3 ">
                    <p className="text-light">
                      All the information relating to the company are made
                      available in a public database. This feature makes it easy
                      to authenticate the existence of the business that
                      ultimately helps in improving business credibility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="faqs">
            <div className="container py-3">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid">
                  <h1 className="pb-3 text-light secu">
                    FAQs on Nidhi Company
                  </h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div className="myBox shadow p-3 mb-3">
                    <ul>
                      <li className="col-lg-12 faq_par">
                        <h4> What is a sole proprietorship??</h4>
                        <p className="text-light">
                          A sole proprietorship qualifies as a company, but it
                          only has one owner and isn’t required to register with
                          state enemies. Beginning a sole proprietorship is
                          extremely simple and doesn’t require a formal filing
                          or paperwork process. All you have to do is simply go
                          into business.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Is GST mandatory for proprietorship??</h4>
                        <p className="text-light">
                          Sole Proprietorship Registration through GST
                          Registration. ... Every registered business has to
                          compulsorily collect the tax from the customers and
                          file the GST returns. If a sole proprietor has a
                          turnover of less than Rs. 20 Lakhs, it is not
                          mandatory for him to get registered and collect GST.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>How many owners are in a proprietorship?</h4>
                        <p className="text-light">
                          A proprietorship can legally have only one owner.
                          That's because the business serves as part of the
                          business owner's personal identity and vice versa.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>What is the advantage of proprietorship?</h4>
                        <p className="text-light">
                          One of the functional advantages of sole
                          proprietorships is that they are easier to set up than
                          other business entities. A person becomes a sole
                          proprietor simply by running a business. Another
                          functional advantage of a sole proprietorship is that
                          the owner maintains 100% control and ownership of the
                          business.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Still, have Confusion ?</h4>
                        <p className="text-light">
                          Don’t worry!! we are here to help you .Get in touch
                          with our team to get all your queries resolved. Write
                          to us at info@lopamudracs.com or 9966534699
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Nidhi;
