import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./components/home";
import Navbar from "./components/Navigation/TopNav";
import Proprietor from "./components/Enterpreneur/Proprietor";
import Partnership from "./components/Enterpreneur/Partnership";
import LLP from "./components/Enterpreneur/LLP";
import OPC from "./components/Enterpreneur/OPC";
import PBLC from "./components/Enterpreneur/PBLC";
import PVLC from "./components/Enterpreneur/PVLC";
import Nidhi from "./components/Enterpreneur/Nidhi";
import Producer from "./components/Enterpreneur/Producer";
import Section8 from "./components/Enterpreneur/Section8";
import Indian from "./components/Enterpreneur/Indian";
import USA from "./components/Enterpreneur/USA";
import DSC from "./components/Enterpreneur/DSC";
import Secretarialservices from "./components/compliances/Secretarialservices";
import ESI from "./components/compliances/ESI";
import Contract from "./components/compliances/Contract";
import PayrollServices from "./components/compliances/PayrollServices";
import HR from "./components/compliances/HR";
import Auditcompliances from "./components/compliances/Auditcompliances";
import PF from "./components/Registrations/PF";
import GST from "./components/Registrations/GST";
import Firms from "./components/Registrations/firms";
import RERA from "./components/Registrations/RERA";
import PTR from "./components/Registrations/PTR";
import MSME from "./components/Registrations/MSME";
import SandE from "./components/Registrations/SandE";
import CLLR from "./components/Registrations/CLLR";
import IEC from "./components/Registrations/IEC";
import DMHO from "./components/Registrations/DMHO";
import Drug from "./components/Registrations/Drug";
import FSSAI from "./components/Registrations/FSSAI";
import PCB from "./components/Registrations/PCB";
import BWL from "./components/Registrations/BWL";
import Annual from "./components/Taxfiling/Annual";
import ROC from "./components/Taxfiling/ROC";
import GSTreturns from "./components/Taxfiling/GSTreturns";
import TDSfiling from "./components/Taxfiling/TDSfiling";
import TDSfilingreturns from "./components/Taxfiling/TDSfilingreturns";
import PFreturns from "./components/Taxfiling/PFreturns";
import ESIreturns from "./components/Taxfiling/ESIreturns";
import ITR1 from "./components/Taxfiling/ITR1";
import ITR2 from "./components/Taxfiling/ITR2";
import ITR3 from "./components/Taxfiling/ITR3";
import ITR4 from "./components/Taxfiling/ITR4";
import ITR5 from "./components/Taxfiling/ITR5";
import ITR6 from "./components/Taxfiling/ITR6";
import ITR7 from "./components/Taxfiling/ITR7";
import Advisory from "./components/legal and advisory/Advisory";
import NCLT from "./components/legal and advisory/NCLT";
import RD from "./components/legal and advisory/RD";
import Liquidator from "./components/legal and advisory/Liquidator";
import Rocl from "./components/legal and advisory/Rocl";
import DGFT from "./components/legal and advisory/DGFT";
import Tribunals from "./components/legal and advisory/Tribunals";
import IPR from "./components/legal and advisory/IPR";
import Labourlaws from "./components/legal and advisory/Labourlaws";
import Factories from "./components/legal and advisory/Factories";
import EPA from "./components/legal and advisory/EPA";
import PCA from "./components/legal and advisory/PCA";
import Legalopenions from "./components/legal and advisory/Legalopenions";
import Drafting from "./components/legal and advisory/Drafting";
import Trademark from "./components/Secure/Trademark";
import Trademarkobjection from "./components/Secure/Trademarkobjection";
import Trademarkopposition from "./components/Secure/Trademarkopposition";
import Trademarkrenewal from "./components/Secure/Trademarkrenewal";
import Contact from "./components/contact";
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      menu: false,
    };
  }

  collapseTogglehandler = () => {
    this.setState({ menu: !this.state.menu });
  };

  render() {
    AOS.init();
    return (
      <div>
        <Navbar action={this.collapseTogglehandler} menu={this.state.menu} />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          {/*------------------------------ Enterprenuer----------------------*/}
          <Route exact path="/Proprietor">
            <Proprietor />
          </Route>
          <Route exact path="/Partnership">
            <Partnership />
          </Route>
          <Route exact path="/LLP">
            <LLP />
          </Route>
          <Route exact path="/OPC">
            <OPC />
          </Route>
          <Route exact path="/PVLC">
            <PVLC />
          </Route>
          <Route exact path="/PBLC">
            <PBLC />
          </Route>
          <Route exact path="/Nidhi">
            <Nidhi />
          </Route>
          <Route exact path="/Producer">
            <Producer />
          </Route>
          <Route exact path="/Section8">
            <Section8 />
          </Route>
          <Route exact path="/USA">
            <USA />
          </Route>
          <Route exact path="/Indian">
            <Indian />
          </Route>
          <Route exact path="/DSC">
            <DSC />
          </Route>

          {/*-------------------------------- registratoions ----------------------------------------*/}
          <Route exact path="/PF">
            <PF />
          </Route>
          <Route exact path="/firms">
            <Firms />
          </Route>
          <Route exact path="/GST">
            <GST />
          </Route>
          <Route exact path="/RERA">
            <RERA />
          </Route>
          <Route exact path="/PTR">
            <PTR />
          </Route>
          <Route exact path="/MSME">
            <MSME />
          </Route>
          <Route exact path="/SandE">
            <SandE />
          </Route>
          <Route exact path="/CLLR">
            <CLLR />
          </Route>
          <Route exact path="/IEC">
            <IEC />
          </Route>
          <Route exact path="/DMHO">
            <DMHO />
          </Route>
          <Route exact path="/Drug">
            <Drug />
          </Route>
          <Route exact path="/FSSAI">
            <FSSAI />
          </Route>
          <Route exact path="/PCB">
            <PCB />
          </Route>
          <Route exact path="/BWL">
            <BWL />
          </Route>
          <Route exact path="/PBLC">
            <PBLC />
          </Route>

          {/* ------------------------------------Compliances----------------------------*/}
          <Route exact path="/Secretarialservices">
            <Secretarialservices />
          </Route>
          <Route exact path="/ESI">
            <ESI />
          </Route>
          <Route exact path="/Contract">
            <Contract />
          </Route>
          <Route exact path="/PayrollServices">
            <PayrollServices />
          </Route>
          <Route exact path="/HR">
            <HR />
          </Route>
          <Route exact path="/Auditcompliances">
            <Auditcompliances />
          </Route>
          {/*-----------------------------------------Tax-returns---------------*/}

          <Route exact path="/Annual">
            <Annual />
          </Route>
          <Route exact path="/ROC">
            <ROC />
          </Route>
          <Route exact path="/GSTreturns">
            <GSTreturns />
          </Route>
          <Route exact path="/TDSfiling">
            <TDSfiling />
          </Route>
          <Route exact path="/TDSfilingreturns">
            <TDSfilingreturns />
          </Route>
          <Route exact path="/PFreturns">
            <PFreturns />
          </Route>
          <Route exact path="/ESIreturns">
            <ESIreturns />
          </Route>
          <Route exact path="/ITR1">
            <ITR1 />
          </Route>
          <Route exact path="/ITR2">
            <ITR2 />
          </Route>
          <Route exact path="/ITR3">
            <ITR3 />
          </Route>
          <Route exact path="/ITR4">
            <ITR4 />
          </Route>
          <Route exact path="/ITR5">
            <ITR5 />
          </Route>
          <Route exact path="/ITR6">
            <ITR6 />
          </Route>
          <Route exact path="/ITR7">
            <ITR7 />
          </Route>
          {/* --------------------------Legal and advisory---------------------------------------------------*/}
          <Route exact path="/Advisory">
            <Advisory />
          </Route>
          <Route exact path="/NCLT">
            <NCLT />
          </Route>
          <Route exact path="/RD">
            <RD />
          </Route>
          <Route exact path="/Liquidator">
            <Liquidator />
          </Route>
          <Route exact path="/Rocl">
            <Rocl />
          </Route>
          <Route exact path="/DGFT">
            <DGFT />
          </Route>
          <Route exact path="/Tribunals">
            <Tribunals />
          </Route>
          <Route exact path="/IPR">
            <IPR />
          </Route>
          <Route exact path="/Labourlaws">
            <Labourlaws />
          </Route>
          <Route exact path="/Factories">
            <Factories />
          </Route>
          <Route exact path="/EPA">
            <EPA />
          </Route>
          <Route exact path="/PCA">
            <PCA />
          </Route>
          <Route exact path="/Legalopenions">
            <Legalopenions />
          </Route>
          <Route exact path="/Drafting">
            <Drafting />
          </Route>
          {/*---    ---------------------------- SECURE BUSINESS--------------------------*/}
          <Route exact path="/Trademark">
            <Trademark />
          </Route>
          <Route exact path="/Trademarkobjection">
            <Trademarkobjection />
          </Route>
          <Route exact path="/Trademarkopposition">
            <Trademarkopposition />
          </Route>
          <Route exact path="/Trademarkrenewal">
            <Trademarkrenewal />
          </Route>
          {/* -------------------contact------------------- */}
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Redirect to="/"></Redirect>
        </Switch>
      </div>
    );
  }
}
export default App;
