import React from "react";
import Footer from "../Navigation/footer";
import OPCImg1 from "../../assets/images/OPC_1.png";
import OPCImg2 from "../../assets/images/OPC-2.png";
import FAQImg1 from "../../assets/images/FAQ-1.png";
class OPC extends React.Component {
  /*  constructor(){
        super();this.stat
    } */

  render() {
    return (
      <div>
        <section id="page content" className=" p-3  bg-light">
          <div className="sidebar-right background-grey ">
            <div className="container pt-4">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive "
                          src="https://images.unsplash.com/photo-1553484771-4e29a68540f4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDV8fGVudHJlcHJlbmV1cnxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=500&q=60"
                          alt="Lopamudra"
                        />
                      </div>

                      <div className="col-lg-7">
                        <h5 className="title">One person Company</h5>
                        <hr />
                        <p className=" text-justify">
                          Lopamudra in India has a dedicated team of company
                          secretarial, legal professionals and Chartered
                          Accountants who possess extensive experience in
                          various corporate laws and commercial / legal
                          documentation. Our team is highly business oriented in
                          approach and has experience across sectors and the
                          acumen to provide complete solutions for all corporate
                          secretarial matters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container  d-flex sidebar-right py-2">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50 ">
                <h1>What is One Person Company?</h1>
                <p className="text-justify">
                  The One Person Company (commonly known as OPC) is the type of
                  entity which is owned by a single person. It allows a sole
                  person to own and also manage the entire business operations.
                  ... Similar to Private Limited Company, OPC Registration and
                  its operations are governed by the Indian Companies Act, 2013
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={OPCImg2}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="contetnt1" className="bg-light">
          <div className=" container  d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid   "
                  src={OPCImg1}
                  alt="Lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50  ">
                <h1> Benefits of One Person Company?</h1>
                <p className="text-justify">
                  More narrow definitions have described entrepreneurship as the
                  process of designing, launching and running a new business,
                  which is often initially a small business, or as the "capacity
                  and willingness to develop, organize and manage a business
                  venture along with any of its risks to make a profit."[4] The
                  people who create these businesses are often referred to as
                  entrepreneurs.5 economic crisis, lack of market demand, or a
                  combination of all of these."[7] the process of designing,
                  launching and running a new business, which is often initially
                  a small business, or as the "capacity and willingness to
                  develop, organize and manage a business venture{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="documents" className="py-3">
          <div className="container  py-3">
            <div className="d-flex text-center container col-md-7">
              <h1 className=" mb-4 secu text-dark pb-2">
                Documents Required for One Person Company
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow p-3">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3  shadow p-3">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="cards" className="pb-3 py-2">
          <div className="container text-justify ">
            <h1 className="text-center py-3 secu container col-md-7">
              Compliance required by a One Person Company
            </h1>
            <h5 className="text-light text-center py-3">
              {" "}
              All you should know about necessary compliances
            </h5>
            <div className="row">
              <div className="col-md-4 pb-4">
                <div className="card  h-100  item1">
                  <div className="title text-center">
                    <h4 className="text-light"> GST Registration</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      A comparison of the popular types of business entity in
                      India, viz. Proprietorship vs Partnership vs LLP vs
                      Private Limited Company vs One Person Company.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 pb-4">
                <div className="card h-100  item2">
                  <div className="title text-center">
                    <h4 className="text-light">GST Return</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light">
                      Guide to partnership firm in India including the various
                      types and features. The law relating to partnership firm
                      in India is prescribed in the Indian Partnership Act of
                      1932. This Act lays down the rights and duties of the
                      partners between themselves and other legal relations
                      between partners and third persons, which are incidental
                      to the formation of a partnership.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 pb-4">
                <div className="card   item3 h-100 ">
                  <div className="title text-center ">
                    <h4 className="text-light">Accounting</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      The meaning of interstate and intrastate supply under GST
                      with illustrations. In GST, the terms interstate and
                      intrastate have tremendous significance in the
                      determination of IGST, CGST or SGST. Interstate supply
                      attracts IGST, while intrastate supply attracts CGST and
                      SGST.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-4 mb-4">
              <div className="col-md-4 pb-4">
                <div className="card  item4 h-100 ">
                  <div id="title" className="title text-center">
                    <h4 className="text-light"> Statutory Audit</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light ">
                      Procedure to create a GST invoice as per the GST Act or
                      download a sample GST invoice as per GST Invoice Rules.
                      Under GST, GSTIN of the supplier is mandatorily required
                      to be mentioned in the tax invoice along with a
                      consecutive serial number that is unique for each
                      financial year.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 pb-4">
                <div className="card  h-100 item5">
                  <div className="title text-center">
                    <h4 className="text-light"> MAT Audit</h4>
                  </div>
                  <div className="body p-3 mb-4">
                    <p className="text-light">
                      GST composition scheme helps small businesses with
                      turnover of less than Rs.150 lakhs by simplifying the GST
                      tax return filing procedure and allowing quarterly filing
                      of GST returns.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 pb-4">
                <div className="card  item6  h-100">
                  <div className="title text-center ">
                    <h4 className="text-light">Income Tax Audit</h4>
                  </div>
                  <div className="body p-3 ">
                    <p className="text-light">
                      File GST return online through Lopamudra with GST Expert
                      Support. You can prepare and file GSTR-1, GSTR-2, GSTR-3
                      and GSTR-4 return online lpcs. In addition to filing GST
                      returns, you can also issue GST invoices and record
                      purchases on LEDGERS to automatically file GST returns.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 pb-4">
                <div className="card  h-100  item1">
                  <div className="title text-center">
                    <h4 className="text-light"> ROC Annual filing</h4>
                  </div>
                  <div className="body p-3 text-center ">
                    <p className="text-light">
                      A comparison of the popular types of business entity in
                      India, viz. Proprietorship vs Partnership vs LLP vs
                      Private Limited Company vs One Person Company.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 pb-4">
                <div className="card h-100  item2">
                  <div className="title text-center">
                    <h4 className="text-light">IEC Registration</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light">
                      Guide to partnership firm in India including the various
                      types and features. The law relating to partnership firm
                      in India is prescribed in the Indian Partnership Act of
                      1932. This Act lays down the rights and duties of the
                      partners between themselves and other legal relations
                      between partners and third persons, which are incidental
                      to the formation of a partnership.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 pb-4">
                <div className="card   item3 h-100 ">
                  <div className="title text-center ">
                    <h4 className="text-light">TM Registration</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      The meaning of interstate and intrastate supply under GST
                      with illustrations. In GST, the terms interstate and
                      intrastate have tremendous significance in the
                      determination of IGST, CGST or SGST. Interstate supply
                      attracts IGST, while intrastate supply attracts CGST and
                      SGST.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="faqs">
            <div className="container py-4">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid">
                  <h1 className="pb-3 secu">FAQs on One person Company</h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par">
                        <h4>What is GSTIN?</h4>
                        <p className="text-light">
                          All businesses that successfully register under GST
                          are assigned a unique Goods and Services Tax
                          Identification Number also known as GSTIN GST
                          Identification Number.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Who should register for GST online?</h4>
                        <p className="text-light">
                          • Individuals registered under the Pre-GST law .e.,
                          Excise, VAT, Service Tax etc.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Is GST Registration Process online?</h4>
                        <p className="text-light">
                          Yes, the process for registration of GST is completely
                          online and paperless. You don’t have to move out of
                          your home or office. We do all the paperwork and
                          submit an application online.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          What is Aggregate Turnover for GST Registration
                          purposes?
                        </h4>
                        <p className="text-light">
                          The “Aggregate Turnover” means total turnover of a
                          person having the same PAN in respect of the
                          following:
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          I operate in more than one state. Do I have to take
                          multiple GST Registrations?
                        </h4>
                        <p className="text-light">
                          Yes, in that case, GSTIN is required to be obtained
                          for each state separately. We have special prices for
                          multi-state registrations. You may specifically
                          mention this to your compliance manager to avail
                          benefits.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          Whether GST Registration is required even if a person
                          is having aggregate turnover lesser than threshold
                          limit?
                        </h4>
                        <p className="text-light">
                          Yes, it is mandatory to obtain GSTIN in case of
                          certain categories of persons. The major categories
                          are given below:
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          I don’t have a Permanent Account Number . What should
                          I do?
                        </h4>
                        <p>
                          Before you apply for a GST Registration as a normal
                          taxpayer, it is mandatory to obtain a{" "}
                          <a
                            href="lopamudracs.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            PAN
                          </a>
                          .
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          I am a small trader with sales of Rs. 50 Lakh. Are
                          there any exemptions available to me?
                        </h4>
                        <p className="tetx-light">
                          Yes surely, Government grants some special exemptions
                          for small businesses like yours. You may opt for
                          composition scheme under which you will have to pay
                          GST @1% only. If you are a restaurant, the rate is 5%.
                          However, you can not claim GST Credit if you opt so.
                          You need to file only 1 return per quarter. This
                          scheme is not applicable if you are –<br />
                          • Service providers
                          <br />
                          • Inter-state sellers
                          <br />
                          • E-commerce sellers
                          <br />
                          • Supplier of non-taxable goods
                          <br />• Manufacturer of Notified Goods
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default OPC;
