import React from "react";
import FFImg1 from "../../assets/images/FF-1.png";
import Footer from "../Navigation/footer";

class GST extends React.Component {
  render() {
    return (
      <div>
        <section id="GST">
          <div className="jumbotron jumbotron-fluid">
            <div className="container">
              <h2 className="text-center pt-5">
                <span className="bgc text-light">&nbsp;GST </span>
                <span className="text-dark">Registration</span>
              </h2>
            </div>
          </div>
        </section>
        <section id="single" className="section single">
          <div className="container py-5">
            <div className="row ">
              <div className="col-lg-8 p-0 text-fluid ">
                <div className="row intro m-0">
                  <div className="col-12">
                    <span className="pre-title m-0">
                      Know what the law requires
                    </span>

                    <h2>
                      <span>GST Registration </span>
                    </h2>
                    <p>
                      Goods and Services Tax (GST) is an indirect tax that was
                      rolled out on July 1, 2017. It is levied on the supply of
                      goods and services across the country. It has brought
                      about a uniformity in the indirect tax structure by
                      replacing all the indirect taxes that existed in the
                      pre-GST regime such as VAT, service tax, Excise duty, etc.
                      Simply put, GST has incorporated the concept of "One
                      nation One Tax."
                    </p>
                    <h1>What are the components of GST?</h1>
                    <p>
                      GST will have 3 tax components, which includes a central
                      component (Central Goods and Services Tax or CGST) and a
                      state component (State Goods and Services Tax or SGST)
                      where centre and state will levy GST on all entities, i.e.
                      when a transaction happens within a state. Inter-state
                      transactions will attract the Integrated Goods and
                      Services Tax (IGST), to be levied by the centre, i.e. when
                      a transaction happens one state to another.
                    </p>
                  </div>
                </div>
              </div>

              <aside className="col-12 col-lg-4 pl-lg-8 pt-5   ">
                <div className="shadow p-3 mb-5">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2 " id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </aside>
            </div>
          </div>
        </section>

        <section className="bg-light py-5 Documents-section">
          <div className="container ">
            <h1 className="text-center ">
              Documents Required for GST Registrations
            </h1>
            <div className="container text-center">
              <div className="row">
                <div id="flag" className="col-md-4  py-3 pl-5">
                  <h4 className="subheadingtitle  ">
                    &nbsp;Pvt. Ltd. /Public Ltd./OPC{" "}
                  </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      Company PAN card
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      MOA, AOA &amp; Incorporation Certificate
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Company Address Proof
                    </p>
                  </ul>
                </div>
                <div className="col-md-4 py-3  pl-5 ">
                  <h4 className="subheadingtitle ">
                    LLP &amp; Partnership Firm{" "}
                  </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Firm PAN card
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      LLP Agreement/ Partnership deed
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Firm Address Proof
                    </p>
                  </ul>
                </div>
                <div className="col-md-4  py-3 pl-5  ">
                  <h4 className="subheadingtitle">Proprietorship Firm </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Proprietor's PAN card
                    </p>
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      Shop Act / Ghumasta License
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Address proof of Firm
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cardssec">
          <div className="container  py-5">
            <div className="text-center container col-md-6">
              <h4 className=" secu">Obtain GST Registration</h4>
              <h5 className="text-light">Points to make your decision easy</h5>
            </div>
            <div className="row mt-3">
              <div className=" col-lg-4   pb-4">
                <div className="card text-white h-100 item1">
                  <h5 className="card-title text-center">GSTIN</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      GSTIN is GST identification number or GST number. A GSTIN
                      is a 15-digit PAN-based unique identification number
                      allotted to every registered person under GST. As a
                      GST-registered dealer, you might want to do a GST Number
                      (GSTIN) verification before entering it in your GST
                      Returns.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    pb-4 ">
                <div className="card text-white  h-100 item2">
                  <h5 className="card-title text-center">GST Rates</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      The GST council has fitted over 1300 goods and 500
                      services under four tax slabs of 5%, 12%, 18% and 28%
                      under GST. This is aside the tax on gold that is kept at
                      3% and rough precious and semi-precious stones that are
                      placed at a special rate of 0.25% under GST. 7% goods and
                      services fall under this category.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4   pb-4 ">
                <div className="card text-white  h-100  item3">
                  <h5 className="card-title text-center">SGST, CGST & IGST</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      IGST, CGST, and SGST are categories of Goods and Service
                      Tax. IGST applies to interstate transactions and CGST and
                      SGST to intrastate transactions. IGST is collected
                      together and distributed to the Central and State
                      Governments. SGST and CGST are collected directly by the
                      Central and State Governments.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4  mt-3 ">
                <div className="card text-white  h-100  item4">
                  <h5 className="card-title text-center">
                    GST registration limit
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      In the GST Regime, businesses whose turnover exceeds Rs.
                      40 lakhs* (Rs 10 lakhs for NE and hill states) is required
                      to register as a normal taxable person. This process of
                      registration is called GST registration. For certain
                      businesses, registration under GST is mandatory.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    mt-3 ">
                <div className="card text-white  h-100 item5">
                  <h5 className="card-title text-center ">Exemptions</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Presently, businesses and organizations with a turnover of
                      up to Rs 20,00,000 are exempted from the GST registration.
                      The GST exemption limit for the limit for hilly and
                      norther estates is Rs 10,00,000.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    mt-3">
                <div className="card text-white  h-100 item6 ">
                  <h5 className="card-title text-center">Benefits</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      Persons who wish to register voluntarily have many
                      advantages. They can avail input tax credit. They can make
                      interstate transactions without any restrictions. They may
                      register on e-commerce websites and grow business. In all,
                      they will have a competitive advantage over other
                      un-registered businesses.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* --------------accordion for Faqs------------*/}
        <section className="py-3 faqs">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <img
                  src={FFImg1}
                  alt="Lopamudra"
                  className="embed-responsive"
                ></img>
              </div>
              <div className="wrap col-lg-7 shadow">
                <div className="container demo text-justify">
                  <h1 className="pb-3 text-light secu">
                    FAQs on GST Registration
                  </h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div
                    className="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div className="panel panel-default mt-3">
                      <div className="panel-heading" role="tab" id="headingOne">
                        <h4 className="panel-title text-light">
                          <a
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            className="seccccc "
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            What will be the effective date of registration?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseOne"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingOne"
                      >
                        <div className="panel-body ">
                          Where the application for registration has been
                          submitted within thirty days from the date on which
                          the person becomes liable to registration, the
                          effective date of registration shall be date of his
                          liability for registration. Where an application for
                          registration has been submitted by the applicant after
                          thirty days from the date of his becoming liable for
                          registration, the effective date of registration shall
                          be the date of grant of registration. In case of
                          suomoto registration, i.e. taking registration
                          voluntarily while being within the threshold exemption
                          limit for paying tax, the effective date of
                          registration shall be the date of the order of
                          registration.
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTwo">
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Is GST required below 20 lakhs?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseTwo"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div className="panel-body">
                          How to Calculate Aggregate Turnover for GST
                          Registration? A business whose aggregate turnover in a
                          financial year exceeds Rs 20 lakhs has to mandatorily
                          register under Goods and Services Tax. This limit is
                          set at Rs 10 lakhs for North Eastern and hilly states
                          flagged as special category states.
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingThree"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Who needs to register for GST in India?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseThree"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingThree"
                      >
                        <div className="panel-body">
                          In India, it is mandatory for businessmen whose
                          turnover exceeds ₹ 40 Lakh to register for GST.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingfour"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsefour"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            What is the purpose of GST?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsefour"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingfour"
                      >
                        <div className="panel-body">
                          This is to replace all the unidentified and indirect
                          taxes charged by government and it is implemented.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingfive"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsefive"
                            aria-expanded="false"
                            aria-controls="collapsefive"
                          >
                            Still, have confusion?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsefive"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingfive"
                      >
                        <div className="panel-body">
                          Don’t worry!! Our expert will help you to choose a
                          best suitable plan for you. Get in touch with our team
                          to get all your queries resolved. Write to us at
                          info@lopamudracs.com or call us @+91 9966534699.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default GST;
