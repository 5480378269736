import React from "react";
import FAQImg1 from "../../assets/images/FAQ-1.png";
import Footer from "../Navigation/footer";
import INTImg from "../../assets/images/INT.jpg";
import ITRImg5 from "../../assets/images/ITR-5.png";
import ITRImg55 from "../../assets/images/ITR-55.png";
class ITR5 extends React.Component {
  render() {
    return (
      <div>
        <section id="page content" className=" p-3  bg-light">
          <div className="sidebar-right background-grey ">
            <div className="container pt-4">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row align-items-center">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive"
                          src="https://images.unsplash.com/photo-1553484771-4e29a68540f4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDV8fGVudHJlcHJlbmV1cnxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=500&q=60"
                          alt="Lopamudra"
                        />
                      </div>

                      <div className="col-lg-7 ">
                        <h5 className="title">ITR-5 Return filing</h5>
                        <hr />
                        <p className=" text-justify">
                          Image result for annual returns The annual return is
                          the return that an investment provides over a period
                          of time, expressed as a time-weighted annual
                          percentage. Sources of returns can include dividends,
                          returns of capital and capital appreciation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 ">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container  d-flex sidebar-right ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50">
                <h1>What is ITR-5 Return filing?</h1>
                <p className="text-justify">
                  More narrow definitions have described entrepreneurship as the
                  process of designing, launching and running a new business,
                  which is often initially a small business, or as the "capacity
                  and willingness to develop, organize and manage a business
                  venture along with any of its risks to make a profit."[4] The
                  people who create these businesses are often referred to as
                  entrepreneurs.5 economic crisis, lack of market demand, or a
                  combination of all of these."[7] the process of designing,
                  launching and running a new business, which is often initially
                  a small business, or as the "capacity and willingness to
                  develop, organize and manage a business venture{" "}
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={ITRImg5}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="contetnt1" className="bg-light">
          <div className=" container py-4 d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid  "
                  src={ITRImg55}
                  alt="Lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50  ">
                <h1>Who can ITR-5 Return filing?</h1>
                <p className="text-justify">
                  More narrow definitions have described entrepreneurship as the
                  process of designing, launching and running a new business,
                  which is often initially a small business, or as the "capacity
                  and willingness to develop, organize and manage a business
                  venture along with any of its risks to make a profit."[4] The
                  people who create these businesses are often referred to as
                  entrepreneurs.5 economic crisis, lack of market demand, or a
                  combination of all of these."[7] the process of designing,
                  launching and running a new business, which is often initially
                  a small business, or as the "capacity and willingness to
                  develop, organize and manage a business venture{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5 bg-light ">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className=" text-justify pt-2 max_wid ">
                  <div className="vl">
                    <h1>Composition Scheme Filing</h1>
                    <p className="text-justify ">
                      All entities registered under the composition scheme are
                      required to file form GSTR-4A every quarter through the
                      GST portal or through a GST facilitation centre.{" "}
                    </p>
                    <p className="text-justify">
                      GST returns for those enrolled under the composition
                      scheme is due on the 18th of the month every quarter.
                      Hence, GST return filing for the composition scheme would
                      be due on April 18th, July 18th, October 18th and January
                      18th.
                    </p>
                    <p>
                      The returns filed must include details of the following:
                    </p>
                    <ul>
                      <li>
                        Inter-State and intra-State inward supplies received
                        from registered and unregistered persons
                      </li>
                      <li>Consolidated details of outward supplies</li>
                    </ul>
                    <p>
                      Even if an entity opted for the composition scheme
                      starting April of that year, it must continue filing
                      monthly GST returns until September of the same year.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 pt-2">
                <div className=" text-justify mt-5  ">
                  <h1>Penalty for late filing</h1>
                  <p className="text-justify">
                    Penalties and cancellation of the GST registration will
                    result if an entity fails to file GST returns on time.
                    In-case of non-compliance for more than six months
                    consecutively, the entity would be unable to obtain another
                    registration in addition to cancellation- until all the
                    penalties are paid back in full.
                  </p>
                  <p className="text-justify">
                    The penalties for entities having no turnover is different
                    when compared to entities having a certain turnover. NIL
                    returns must be filed in case of no turnover and failure to
                    do so will result in a penalty of Rs. 20 per day.
                  </p>
                  <p className="text-justify">
                    Established businesses will be levied with a penalty of Rs.
                    50 per day for the period of late-filing. A penalty of Rs.
                    50 per day will be applicable for late GSTR-3B return and
                    Rs.50 per for GSTR-1 return. In total, a penalty of more
                    than Rs.3000 per month would be incurred. In addition to the
                    above late filing fees, the entity would also have to pay
                    interest at the rate of 18% on GST payment remitted late.
                  </p>
                  <p className="text-justify m-b-25">
                    <strong>
                      *As per 40th GST council meeting, the penalty for late
                      filing has been waived off.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="background-grey">
          <div className="container py-5">
            <h3 className="text-center">
              <strong>Documents required for ITR-5 Return filing</strong>
            </h3>
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12  col-12 w-50 py-5">
                <ul className="text-secondary">
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Form-16 </strong>
                  <br />
                  <i
                    className="fa fa-check mr-3  pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Salary Slips</strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>
                    Interest certificates from Post Offices &amp; Banks
                  </strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Form 16A/16B/16C</strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Form 26AS</strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Tax-Savings Investment Proofs</strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Deductions under Section 80D to 80U</strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Home Loan statement from NBFC/Bank</strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Capital Gains</strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Aadhar Card</strong>
                  <br />
                </ul>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={INTImg}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="faqs">
            <div className="container py-4">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid">
                  <h1 className="pb-3 text-light secu">
                    FAQs on ITR-5 return filing
                  </h1>

                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par">
                        <h4>What is GSTIN?</h4>
                        <p>
                          All businesses that successfully register under GST
                          are assigned a unique Goods and Services Tax
                          Identification Number also known as GSTIN GST
                          Identification Number.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Who should register for GST online?</h4>
                        <p>
                          • Individuals registered under the Pre-GST law .e.,
                          Excise, VAT, Service Tax etc.
                          <br />
                          • Businesses with turnover above the threshold limit
                          of Rs. 40 Lakhs Rs. 10 Lakhs for North-Eastern States,
                          J&amp;K, Himachal Pradesh and Uttarakhand
                          <br />
                          • Casual taxable person / Non-Resident taxable person
                          <br />
                          • Every e-commerce aggregator
                          <br />• The person supplying online information and
                          database access or retrieval services from a place
                          outside India to a person in India, other than a
                          registered taxable person
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Is GST Registration Process online?</h4>
                        <p>
                          Yes, the process for registration of GST is completely
                          online and paperless. You don’t have to move out of
                          your home or office. We do all the paperwork and
                          submit an application online.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          What is Aggregate Turnover for GST Registration
                          purposes?
                        </h4>
                        <p>
                          The “Aggregate Turnover” means total turnover of a
                          person having the same PAN in respect of the
                          following:
                        </p>
                        <p>
                          i All taxable supplies including interstate supplies
                          <br />
                          Exempt supplies
                          <br />
                          Exports of goods and/or service
                        </p>
                        <p>
                          The above shall be computed on all India basis and
                          excludes taxes charged under the CGST Act, SGST Act
                          and the IGST Act.
                        </p>
                      </li>

                      <li className="col-lg-12 faq_par">
                        <h4>Still, have confusion?</h4>
                        <p>
                          Don’t worry!! Our expert will help you to choose a
                          best suitable plan for you. Get in touch with our team
                          to get all your queries resolved. Write to us at
                          info@lopamudracs.com or call us @+91 9966564399
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default ITR5;
