import React from "react";
import Footer from "../Navigation/footer";
import regImg1 from "../../assets/images/reg-1.jpeg";
import regImg2 from "../../assets/images/reg-2.jpg";
import regImg3 from "../../assets/images/reg-3.jpeg";
import FAQImg1 from "../../assets/images/FAQ-1.png";
class Partnership extends React.Component {
  /*  constructor(){
        super();this.stat
    } */

  render() {
    return (
      <div>
        <section id="page content" className=" p-3  bg-light">
          <div className="sidebar-right background-grey ">
            <div className="container pt-4">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row align-items-center">
                      <div className="col-lg-5 ">
                        <img
                          className="embed-responsive im "
                          src={regImg1}
                          alt="Lopamudra"
                        />
                      </div>

                      <div className="col-lg-7">
                        <h5 className="title">Partnership Firm Registration</h5>
                        <hr />
                        <p className=" text-justify">
                          Lopamudra in India has a dedicated team of company
                          secretarial, legal professionals and Chartered
                          Accountants who possess extensive experience in
                          various corporate laws and commercial / legal
                          documentation. Our team is highly business oriented in
                          approach and has experience across sectors and the
                          acumen to provide complete solutions for all corporate
                          secretarial matters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3  con">
                  <p className="widget-title  ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container py-0 d-flex sidebar-right ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50 ">
                <h1>Partnership Firm Registration :</h1>
                <p className="text-justify">
                  A Partnership firm is a business entity created by persons who
                  have agreed to share profits or loss of the business.
                  Partnerships are a very good choice of business entity for
                  small enterprises wherein two or more persons decides to
                  contribute to a business and share the profits or losses. In
                  India, Partnerships are widely prevalent because of its ease
                  of formation and minimal regulatory compliance. Also, the
                  concept of LLP was introduced only in 2010, whereas the
                  Partnership Act, 1932 has been in existence before the
                  independence of India. Hence, partnership firms are the most
                  prevalent type of business entity wherein a group of people
                  are involved.
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={regImg2}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="contetnt1" className="bg-light">
          <div className=" container py-4 d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid  p-3  "
                  src={regImg3}
                  alt="Lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50  ">
                <h1>Registration of Partnership Firm:</h1>
                <p className="text-justify">
                  A partnership firm can be registered under Section 58 of the
                  Indian Partnership Act at any time, even subsequent to the
                  formation. The registration of a partnership firm is done
                  through the Registrar of Firm in which the partnership firm is
                  situated. When the Registrar of Firms is satisfied that the
                  provisions of Section 58 are complied with, a record of entry
                  of the statement is made in the Register of Firms and
                  Certificate of Registration is issued
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="documents" className="py-4">
          <div className="container  ">
            <div className="d-flex text-center container col-md-7">
              <h1 className=" mb-4 secu text-dark pb-2">
                Documents Required for Partnership Firm Registration
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow p-3">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3 shadow p-3 ">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="cards" className="cardssec py-3">
          <div className="container  text-justify ">
            <h1 className="text-center pt-3 pb-2 secu container col-md-7">
              Compliance required for Partnership Firm
            </h1>
            <h5 className="text-light text-center py-3">
              All you Should know about partnership firm Compliance
            </h5>
            <div className="row mt-4">
              <div className="col-md-4 pb-4">
                <div className="card  h-100  item1">
                  <div className="title text-center">
                    <h4 className="text-light"> GST Registration</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      Partnership firms in India requires GST registration. GST
                      Registration Process is 100% online and there is no
                      requirement of submission of physical documents to GST
                      Department. GST registration must be obtained within 30
                      days of business incorporation, otherwise, the business
                      will be subject to heavy penalties..
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-4">
                <div className="card h-100  item2">
                  <div className="title text-center ">
                    <h4 className="text-light ">GST Return</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light">
                      Under the Goods & Service Tax regime which apparently
                      rolled out in 2017, partnership firms having GST
                      registration would be required to file GST returns. GST
                      Returns can be filed monthly, quarterly and annual GST
                      returns. As filing of GST returns is mandatory for all the
                      registered Taxpayers including Partnership firms.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card   item3 h-100 ">
                  <div className="title text-center ">
                    <h4 className="text-light">Accounting</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      The accounting for a partnership firm is essential
                      compliance. Every Partnership firm shall maintain proper
                      books of accounts which shall represent an accurate and
                      fair view of the state of affairs of the Firm. In essence,
                      a separate account tracks each partner’s investment,
                      distributions etc.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-4 mb-4">
              <div className="col-md-4 mb-4">
                <div className="card  item4 h-100 ">
                  <div id="title" className="title text-center">
                    <h4 className="text-light"> Tax audit</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light ">
                      The Income Tax audit would be needed for a partnership
                      firm if the total sales turnover is more than Rs.1 crore
                      during the financial year. In case of a professional firm,
                      the tax audit would be necessary if total gross receipts
                      exceed Rs.50 lakhs throughout the financial year under
                      assessment.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-4">
                <div className="card  h-100 item5">
                  <div className="title text-center">
                    <h4 className="text-light">IT Return</h4>
                  </div>
                  <div className="body p-3 mb-4">
                    <p className="text-light">
                      Income tax filing must be filed by all partnership firms.
                      The income tax return of a partnership firm that doesn’t
                      need an audit is due on the 31st of July. If the income
                      tax return of a partnership firm is to be audited
                      according to the Income Tax Act, then the return would be
                      unpaid on the 30th of September.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card  item6  h-100">
                  <div className="title text-center ">
                    <h4 className="text-light"> TDS Return</h4>
                  </div>
                  <div className="body p-3 ">
                    <p className="text-light">
                      Quarterly TDS returns must be filed by partnership firms
                      that have TAN and are required to deduct tax at source as
                      per TDS rules. A TDS Return is a quarterly statement which
                      has to be submitted to the IT Department of India. It
                      should contain all details of TDS deducted and deposited
                      by you for a particular quarter.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faqs ">
          <div>
            <div className="container py-4">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid  ">
                  <h1 className="pb-3  text-light secu ">
                    FAQs on Partnership Firm
                  </h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div className="myBox shadow p-3 mb-3 ">
                    <div className="wpb_text_column wpb_content_element  fq-wrap">
                      <div className="wpb_wrapper">
                        <ul>
                          <li className="col-lg-12 faq_par">
                            <h4>What is a Partnership Firm?</h4>
                            <p className="text-light">
                              A partnership firm is a business form in which two
                              or more individuals manage and operate a business
                              in accordance with the terms and objectives set in
                              a Partnership Deed. This may or may not be
                              registered.
                            </p>
                          </li>
                          <li className="col-lg-12 faq_par">
                            <h4>
                              Is there any limit on the number of Partners?
                            </h4>
                            <p className="text-light">
                              A partnership must have at least two partners. A
                              partnership firm in the banking business can have
                              up to 10 partners, while those engaged in any
                              other business can have 20 partners.
                            </p>
                          </li>
                          <li className="col-lg-12 faq_par text-light">
                            <h4>
                              Do I need to get Partnership firm registration
                              done in India?
                            </h4>
                            <p className="text-light">
                              No, registration of a partnership is not
                              necessary. However, for a partner to lodge a
                              complaint or sue another partner or the firm
                              itself, the partnership should be registered.
                              Moreover, for the partnership to bring any suit to
                              court, the firm should be registered. For small or
                              family businesses, it is fine if a firm is not
                              registered.
                            </p>
                          </li>
                          <li className="col-lg-12 faq_par">
                            <h4>
                              Is it mandatory to have a written Partnership
                              agreement (Partnership deed)?
                            </h4>
                            <p className="text-light">
                              No, it is not mandatory to have a written
                              Partnership agreement i.e. Partnership deed.
                              However, it is always the best course to have a
                              written document (partnership deed) instead of
                              oral agreements.
                            </p>
                          </li>
                          <li className="col-lg-12 faq_par">
                            <h4>
                              Can one deal with one of the partners of a
                              Partnership Firm?
                            </h4>
                            <p className="text-light">
                              Yes. The law presumes that each partner is an
                              agent of the other and dealing in good faith with
                              one partner binds the other partners as well.
                            </p>
                          </li>
                          <li className="col-lg-12 faq_par">
                            <h4>
                              Is the firm liable for the wrongful act of one
                              partner?
                            </h4>
                            <p className="text-light">
                              Yes. The firm and all the partners are liable for
                              the wrongful act or fraud which causes loss or
                              injury to any third parties.
                            </p>
                          </li>
                          <li className="col-lg-12 faq_par">
                            <h4>
                              What are the documents required for GST
                              registration for Partnership firm?
                            </h4>
                            <ol>
                              <li>Photos of all Partners</li>
                              <li>PAN Card of all Partners</li>
                              <li>Aadhar Card of all Partners</li>
                              <li>
                                <a
                                  href="lopamudracs.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  PAN Card
                                </a>{" "}
                                of the Partnership Firm
                              </li>
                              <li>
                                Proof of Constitution of Business (Partnership
                                deed)
                              </li>
                              <li>
                                Proof of Principle place of business (Anyone –
                                Electricity Bill/Rent or Lease agreement/Latest
                                Bank Statement – Not less than 2 months old)
                              </li>
                              <li>
                                Letter of Authority in favour of any Partner
                              </li>
                            </ol>
                          </li>
                          <li className="col-lg-12 faq_par">
                            <h4>What is Partnership at will?</h4>
                            <p className="text-light">
                              When the partnership deed does not contain any
                              provision for the duration of the partnership nor
                              conditions for the termination of the partnership,
                              it is a partnership at will.
                            </p>
                          </li>
                          <li className="col-lg-12 faq_par">
                            <h4>Which Cities do you provide your services?</h4>
                            <p className="text-light">
                              lopamudracs.com provides Partnership Registration
                              all across India. You can obtain Partnership
                              easily with us.
                            </p>
                          </li>
                          <li className="col-lg-12 faq_par">
                            <h4>Still, have confusion?</h4>
                            <p className="text-light">
                              Don’t worry!! Our expert will help you to choose
                              the best suitable plan for you. Get in touch with
                              our team to get all your queries resolved. Write
                              to us at info@lopamudracs.com or call us @+91
                              903219990 .
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Partnership;
