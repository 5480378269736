import React from "react";
import { Nav } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink, Link } from "react-router-dom";
import LOGOImg1 from "../../assets/images/LOGO-1.png";
class TopNav extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const show = this.props.menu ? "show" : "";

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-bg fixed-top custom__navbar shadow">
          <Link to="/home">
            {" "}
            <img
              className="logo-image embed-responsive"
              src={LOGOImg1}
              alt="lopamudra"
            />{" "}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            // data-toggle="collapse"
            // data-target="#navbarNavDropdown"
            // aria-controls="navbarNavDropdown"
            // aria-label="Toggle navigation"
            onClick={this.props.action}
          >
            <i className="fa fa-bars text-light bg-dark" aria-hidden="true"></i>
          </button>
          <div
            className={`collapse navbar-collapse custom__collapse ${show}`}
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav ml-auto  ">
              <li className="nav-item">
                <NavLink
                  className="nav-link menu-list "
                  activeClassName="active"
                  exact
                  to="/"
                  onClick={this.props.action}
                >
                  Home
                </NavLink>
              </li>
              {/* ----enterpreneur ---*/}
              <div className="nav-item dropdown">
                <NavLink
                  className="nav-link  dropdown-toggle"
                  data-toggle="dropdown"
                  className="nav-link menu-list "
                  activeClassName="active"
                  exact
                  to="#nogo"
                >
                  Enterpreneur{" "}
                </NavLink>
                <div className="dropdown-menu dropdown-large ">
                  <div className="row">
                    <div className="col-md text-left   ">
                      <li>
                        <NavLink
                          exact
                          to="/Proprietor"
                          onClick={this.props.action}
                        >
                          <span className="dropdown-item ">Proprietorship</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          to="/Partnership"
                          onClick={this.props.action}
                        >
                          <span className="dropdown-item ">Partnership</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/LLP" onClick={this.props.action}>
                          <span className="dropdown-item ">
                            Limited Liability Partnership
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/OPC" onClick={this.props.action}>
                          <span className="dropdown-item ">
                            One person Company
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/PVLC" onClick={this.props.action}>
                          <span className="dropdown-item ">
                            Private Limited Company
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/PBLC" onClick={this.props.action}>
                          <span className="dropdown-item ">
                            Public Limited Company
                          </span>
                        </NavLink>
                      </li>
                    </div>
                    <div className="col-md">
                      <li className="ENTI">OTHER ENTITIES</li>
                      <li>
                        <NavLink exact to="/Nidhi" onClick={this.props.action}>
                          <span className="dropdown-item ">Nidhi Company</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          to="/Producer"
                          onClick={this.props.action}
                        >
                          <span className="dropdown-item ">
                            Producer Company
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          to="/Section8"
                          onClick={this.props.action}
                        >
                          <span className="dropdown-item ">
                            Section 8 Company
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/USA" onClick={this.props.action}>
                          <span className="dropdown-item ">
                            USA Company Registration
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/Indian" onClick={this.props.action}>
                          <span className="dropdown-item ">
                            Indian Subsidiary
                          </span>
                        </NavLink>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              {/*-----------------------------------------registrations--------------------------------*/}
              <div className="nav-item dropdown">
                <NavLink
                  className="nav-link  dropdown-toggle"
                  data-toggle="dropdown"
                  className="nav-link menu-list "
                  activeClassName="active"
                  exact
                  to="#nogo"
                >
                  Registrations
                </NavLink>
                <div className="dropdown-menu dropdown-large  ">
                  <div className="row">
                    <div className="col-md text-left   ">
                      <li className="ENTI">REGISTRATIONS</li>
                      <li>
                        <NavLink exact to="/PF" onClick={this.props.action}>
                          <span className="dropdown-item ">
                            ESI & PF Registrations
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/RERA" onClick={this.props.action}>
                          <span className="dropdown-item ">
                            RERA Registrations
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/firms" onClick={this.props.action}>
                          <span className="dropdown-item " href="#">
                            Firms, Societies & Trust Registrations
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/GST" onClick={this.props.action}>
                          <span className="dropdown-item " href="#">
                            GST Registrations
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/PTR" onClick={this.props.action}>
                          <span className="dropdown-item ">
                            Professional Tax Registrations & Returns
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/MSME" onClick={this.props.action}>
                          <span className="dropdown-item text-left " href="#">
                            MSME Registration
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/SandE" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            S&E Act Registrations
                          </span>
                        </NavLink>
                      </li>
                    </div>
                    <div className="col-md">
                      <li>
                        <NavLink exact to="/CLLR" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            Contract Labour licence Registrations
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/IEC" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            IEC Registration
                          </span>
                        </NavLink>
                      </li>
                      <li className="ENTI">LICENSES</li>
                      <li>
                        <NavLink exact to="/DMHO" onClick={this.props.action}>
                          <span className="dropdown-item text-left  ">
                            DMHO License
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/Drug" onClick={this.props.action}>
                          <span className="dropdown-item text-left " href="#">
                            Drug License
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/FSSAI" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            Food Licence (FSSAI)
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/PCB" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            PCB Licenses
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/BWL" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            Biomedical Waste License
                          </span>
                        </NavLink>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              {/* -------------------------------------Compliances----------------------------------*/}
              <div className="nav-item dropdown">
                <NavLink
                  className="nav-link  dropdown-toggle"
                  data-toggle="dropdown"
                  className="nav-link menu-list "
                  activeClassName="active"
                  exact
                  to="#nogo"
                >
                  Compliances
                </NavLink>
                <ul className="dropdown-menu">
                  <li>
                    <NavLink exact to="/Secretarialservices">
                      <span
                        className="dropdown-item "
                        onClick={this.props.action}
                      >
                        SecretarialServices
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/ESI">
                      <span
                        className="dropdown-item "
                        onClick={this.props.action}
                      >
                        ESIC & EPF Compliances
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/Contract">
                      <span
                        className="dropdown-item "
                        onClick={this.props.action}
                      >
                        Contract Labour Compliances
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/PayrollServices"
                      onClick={this.props.action}
                    >
                      <span className="dropdown-item ">Payroll Services</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/Auditcompliances"
                      onClick={this.props.action}
                    >
                      <span className="dropdown-item ">Audit Compliances</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/HR">
                      <span
                        className="dropdown-item "
                        onClick={this.props.action}
                      >
                        HR Compliances
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* ---------------------- tax filings--------------- */}
              <div className="nav-item dropdown">
                <NavLink
                  className="nav-link  dropdown-toggle"
                  data-toggle="dropdown"
                  className="nav-link menu-list "
                  activeClassName="active" /*  */
                  exact
                  to="#nogo"
                >
                  Tax-filing
                </NavLink>
                <div className="dropdown-menu dropdown-large ">
                  <div className="row">
                    <div className="col-md text-left ">
                      <li>
                        <NavLink exact to="/Annual" onClick={this.props.action}>
                          <span className="dropdown-item ">Annual returns</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/ROC" onClick={this.props.action}>
                          <span className="dropdown-item ">ROC filings</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/GSTreturns">
                          <span className="dropdown-item ">G.S.T returns</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          to="/TDSfiling"
                          onClick={this.props.action}
                        >
                          <span className="dropdown-item ">TDS filings</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          to="TDSfilingreturns"
                          onClick={this.props.action}
                        >
                          <span className="dropdown-item ">
                            TDS filing Returns
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          to="/PFreturns"
                          onClick={this.props.action}
                        >
                          <span className="dropdown-item ">PF returns</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          to="/ESIreturns"
                          onClick={this.props.action}
                        >
                          <span className="dropdown-item text-left ">
                            Esi returns
                          </span>
                        </NavLink>
                      </li>
                    </div>
                    <div className="col-md" onClick={this.props.action}>
                      <li className="ENTI"> INCOMETAX RETURNS</li>

                      <li>
                        <NavLink exact to="/ITR1" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            ITR-1 Return filing
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/ITR2" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            ITR-2 Return filing
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/ITR3" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            ITR-3 Return filing
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/ITR4" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            ITR-4 Return filing
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/ITR5" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            ITR-5 Return filing
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/ITR6" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            ITR-6 Return filing
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/ITR7" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            ITR-7 Return filing
                          </span>
                        </NavLink>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              {/* ----- legal and advisory---------- 
              <li className="nav-item">
                <NavLink
                  className="nav-link menu-list "
                  activeClassName="active"
                  exact
                  to="/Legal and advisory  "
                >
                  Legal and advisory
                </NavLink>
              </li>*/}
              <div className="nav-item dropdown">
                <NavLink
                  className="nav-link  dropdown-toggle"
                  data-toggle="dropdown"
                  className="nav-link menu-list "
                  activeClassName="active" /*  */
                  exact
                  to="#nogo"
                >
                  Legal and advisory
                </NavLink>
                <div className="dropdown-menu dropdown-large laa">
                  <div className="row">
                    <div className="col-md text-left ">
                      <li>
                        <NavLink
                          exact
                          to="/Advisory"
                          onClick={this.props.action}
                        >
                          <span className="dropdown-item ">
                            Advisory Services
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/NCLT" onClick={this.props.action}>
                          <span className="dropdown-item ">NCLT</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/RD">
                          <span
                            className="dropdown-item "
                            onClick={this.props.action}
                          >
                            RD
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/Liquidator">
                          <span
                            className="dropdown-item "
                            onClick={this.props.action}
                          >
                            Liquidator
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/Rocl">
                          <span
                            className="dropdown-item "
                            onClick={this.props.action}
                          >
                            ROC
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/DGFT">
                          <span
                            className="dropdown-item "
                            onClick={this.props.action}
                          >
                            DGFT
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/Tribunals">
                          <span
                            className="dropdown-item "
                            onClick={this.props.action}
                          >
                            Tribunals
                          </span>
                        </NavLink>
                      </li>
                    </div>
                    <div className="col-md">
                      <li>
                        <NavLink exact to="/IPR" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            IPR (Trade mark, Copyrights, Patents)
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          to="/Labourlaws"
                          onClick={this.props.action}
                        >
                          <span className="dropdown-item text-left ">
                            Labour law’s
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          to="/Factories"
                          onClick={this.props.action}
                        >
                          <span className="dropdown-item text-left ">
                            Factories Act
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/EPA" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            Environmental Protection Act
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to="/PCA" onClick={this.props.action}>
                          <span className="dropdown-item text-left ">
                            Pollution Control Acts
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          to="/Legalopenions"
                          onClick={this.props.action}
                        >
                          <span className="dropdown-item text-left ">
                            Legal Opinions
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          exact
                          to="/Drafting"
                          onClick={this.props.action}
                        >
                          <span className="dropdown-item text-left ">
                            Drafting of various Deed & Agreement
                          </span>
                        </NavLink>
                      </li>
                    </div>
                  </div>
                </div>
              </div>

              {/*-----------Loans and Investments----------- */}
              <li className="nav-item">
                <NavLink
                  className="nav-link menu-list "
                  activeClassName="active"
                  exact
                  to="/Dsc"
                  onClick={this.props.action}
                >
                  Digital Signature
                </NavLink>
              </li>

              {/*-----------Secure bussiness---------- */}

              <div className="nav-item dropdown">
                <NavLink
                  className="nav-link  dropdown-toggle "
                  data-toggle="dropdown"
                  className="nav-link menu-list "
                  activeClassName="active"
                  exact
                  to="#nogo"
                >
                  Secure Business
                </NavLink>
                <ul className="dropdown-menu ">
                  <li>
                    <NavLink exact to="/Trademark" onClick={this.props.action}>
                      <span className="dropdown-item ">
                        Trademark Registration
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/Trademarkobjection"
                      onClick={this.props.action}
                    >
                      <span className="dropdown-item ">
                        Trademark Objection
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/Trademarkopposition"
                      onClick={this.props.action}
                    >
                      <span className="dropdown-item ">
                        Trademark Opposition
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/Trademarkrenewal"
                      onClick={this.props.action}
                    >
                      <span className="dropdown-item ">Trademark Renewal</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/*-------------------------- ---------Contact-------------------------- */}
              <li className="nav-item">
                <NavLink
                  className="nav-link menu-list "
                  exact
                  activeClassName="active"
                  to="/contact"
                  onClick={this.props.action}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
export default TopNav;
