import React from "react";
import Footer from "../Navigation/footer";
import CheckImg from "../../assets/images/Check.png";
import FAQImg1 from "../../assets/images/FAQ-1.png";
import AUDImg1 from "../../assets/images/AUD-1.jpg";
import AUDImg2 from "../../assets/images/AUD-2.jpg";
import Services from "../sliders/Services";

class Auditcompliances extends React.Component {
  render() {
    // function ValidationForm() {
    //   let username = document.forms["form-control"]["Name"];
    //   let email = document.forms["RegForm"]["Email"];
    //   let phoneNumber = document.forms["RegForm"]["Telephone"];
    //   if (username.value == "") {
    //     alert("Please enter your name.");
    //     username.focus();
    //     return false;
    //   }
    //   if (email.value == "") {
    //     alert("Please enter a valid e-mail address.");
    //     email.focus();
    //     return false;
    //   }
    //   if (email.value.indexOf("@", 0) < 0) {
    //     alert("Please enter a valid e-mail address.");
    //     email.focus();
    //     return false;
    //   }
    //   if (email.value.indexOf(".", 0) < 0) {
    //     alert("Please enter a valid e-mail address.");
    //     email.focus();
    //     return false;
    //   }
    //   if (phoneNumber.value == "") {
    //     alert("Please enter your telephone number.");
    //     phoneNumber.focus();
    //     return false;
    //   }
    // }
    return (
      <div>
        <section id="page content audit" className=" p-3  bg-light ">
          <div className="sidebar-right background-grey ">
            <div className="container pt-4 ">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row align-items-center">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive"
                          src="https://images.unsplash.com/photo-1542744173-05336fcc7ad4?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGF1ZGl0fGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                          alt="Lopamudra"
                        />
                      </div>

                      <div className="col-lg-7">
                        <h5 className="title">Audit compliances</h5>
                        <hr />
                        <p className=" text-justify">
                          Lopamudra in India has a dedicated team of company
                          secretarial, legal professionals and Chartered
                          Accountants who possess extensive experience in
                          various corporate laws and commercial / legal
                          documentation. Our team is highly business oriented in
                          approach and has experience across sectors and the
                          acumen to provide complete solutions for all corporate
                          secretarial matters
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 ">
                  <p className="widget-title ">CONTACT US</p>

                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        id="Name"
                        size="60"
                        name="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        id="E-mail"
                        size="60"
                        name="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                        id="Telephone"
                        size="60"
                        name="Telephone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container py-4 d-flex sidebar-right ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50 ">
                <h1>What Is a Compliance Audit?</h1>
                <p className="text-justify">
                  A compliance audit is an independent evaluation to ensure that
                  an organization is following external laws, rules, and
                  regulations or internal guidelines, such as corporate bylaws,
                  controls, and policies and procedures. Compliance audits may
                  also determine if an organization is conforming to an
                  agreement, such as when an entity accepts government or other
                  funding. Although most people are familiar with financial
                  audits, such as those for public companies through the
                  Sarbanes-Oxley Act (SOX) or individual or corporate tax audits
                  through the Internal Revenue Service (IRS), compliance audits
                  are not merely financial. Audits may also review IT and other
                  security issues, compliance with HR laws, quality management
                  systems, and other areas. Compliance is one leg in the tripod
                  of GRC, which stands for governance, risk, and compliance.
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={AUDImg1}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="contetnt1" className="bg-light">
          <div className=" container py-4 d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid  "
                  src={AUDImg2}
                  alt="Lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50  ">
                <h1>What Is the Purpose of a Compliance Audit ?</h1>
                <p className="text-justify">
                  A compliance audit gauges how well an organization adheres to
                  rules and regulations, standards, and even internal bylaws and
                  codes of conduct. Part of an audit may also review the
                  effectiveness of an organization’s internal controls.
                  Different departments may use multiple types of audits. For
                  example, accounting may use internal, compliance, and
                  operational audits. Audits may be required by different levels
                  of government.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="documents" className="py-3">
          <div className="container  py-3">
            <div className="d-flex container col-md-7">
              <h1 className=" mb-4 secu text-dark text-center pb-2">
                Documents Required for Audit Complinaces
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow p-3">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3  shadow p-3">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="cards">
          <div className="container  py-2">
            <div className="text-center">
              <h1 className="py-3 secu container col-md-7">
                Advantages of Audit compliances
              </h1>
            </div>
            <div className="row mt-4">
              <div className=" col-lg-4 ">
                <div className="card text-white  mb-3 item1">
                  <div className="card-header">Header</div>
                  <div className="card-body">
                    <h5 className="card-title">Primary card title</h5>
                    <p className="card-text">
                      Some quick example text totle and make up the bulk of the
                      card's content.This is a height action
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    ">
                <div className="card text-white item2 mb-3">
                  <div className="card-header">Header</div>
                  <div className="card-body">
                    <h5 className="card-title">Secondary card title</h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4   ">
                <div className="card text-white item3  mb-3">
                  <div className="card-header">Header</div>
                  <div className="card-body">
                    <h5 className="card-title">Success card title</h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card text-white item4  mb-3">
                  <div className="card-header">Header</div>
                  <div className="card-body">
                    <h5 className="card-title">Danger card title</h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 ">
                <div className="card text-white item5 mb-3">
                  <div className="card-header">Header</div>
                  <div className="card-body">
                    <h5 className="card-title">Warning card title</h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 ">
                <div className="card text-white item6 mb-3">
                  <div className="card-header">Header</div>
                  <div className="card-body">
                    <h5 className="card-title">Info card title</h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4">
                <div className="card item7  mb-3">
                  <div className="card-header">Header</div>
                  <div className="card-body">
                    <h5 className="card-title">Light card title</h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card text-white  item8  mb-3">
                  <div className="card-header">Header</div>
                  <div className="card-body">
                    <h5 className="card-title">Dark card title</h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4">
                <div className="card text-white item9  mb-3">
                  <div className="card-header">Header</div>
                  <div className="card-body">
                    <h5 className="card-title">Dark card title</h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="faqs">
            <div className="container py-3">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid ">
                  <h1 className="pb-3 text-light secu">
                    FAQs on Audit Complinaces
                  </h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par">
                        <h4> What is a sole proprietorship??</h4>
                        <p className="text-light">
                          A sole proprietorship qualifies as a company, but it
                          only has one owner and isn’t required to register with
                          state enemies. Beginning a sole proprietorship is
                          extremely simple and doesn’t require a formal filing
                          or paperwork process. All you have to do is simply go
                          into business.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Is GST mandatory for proprietorship??</h4>
                        <p className="text-light">
                          Sole Proprietorship Registration through GST
                          Registration. ... Every registered business has to
                          compulsorily collect the tax from the customers and
                          file the GST returns. If a sole proprietor has a
                          turnover of less than Rs. 20 Lakhs, it is not
                          mandatory for him to get registered and collect GST.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>How many owners are in a proprietorship?</h4>
                        <p className="text-light">
                          A proprietorship can legally have only one owner.
                          That's because the business serves as part of the
                          business owner's personal identity and vice versa.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>What is the advantage of proprietorship?</h4>
                        <p className="text-light">
                          One of the functional advantages of sole
                          proprietorships is that they are easier to set up than
                          other business entities. A person becomes a sole
                          proprietor simply by running a business. Another
                          functional advantage of a sole proprietorship is that
                          the owner maintains 100% control and ownership of the
                          business.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Still, have Confusion ?</h4>
                        <p className="text-light">
                          Don’t worry!! we are here to help you .Get in touch
                          with our team to get all your queries resolved. Write
                          to us at info@lopamudracs.com or 9966534699
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
export default Auditcompliances;
