import React from "react";
import Footer from "../Navigation/footer";
import LOGOImg1 from "../../assets/images/LOGO-1.png";
import soleImg2 from "../../assets/images/sole-2.jpg";
import soleImg3 from "../../assets/images/sole-3.png";
import FAQImg1 from "../../assets/images/FAQ-1.png";
class Proprietor extends React.Component {
  /*  constructor(){
        super();this.stat
    } */
  render() {
    return (
      <div>
        <section id="page content" className=" p-3  bg-light ">
          <div className="sidebar-right background-grey ">
            <div className="container pt-4  ">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row align-items-center">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive"
                          src={LOGOImg1}
                          alt="Lopamudra"
                        />
                      </div>

                      <div className="col-lg-7">
                        <h5 className="title">Proprietorship</h5>
                        <hr />
                        <p className=" text-justify">
                          Lopamudra in India has a dedicated team of company
                          secretarial, legal professionals and Chartered
                          Accountants who possess extensive experience in
                          various corporate laws and commercial / legal
                          documentation. Our team is highly business oriented in
                          approach and has experience across sectors and the
                          acumen to provide complete solutions for all corporate
                          secretarial matters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 ">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button
                        type="submit"
                        className="btn  mb-2"
                        id="sees"
                        href="#"
                      >
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container  d-flex sidebar-right text-justify ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50 ">
                <h1>What is a proprietorship?</h1>
                <p>
                  A sole proprietorship, also known as the sole trader,
                  individual entrepreneurship or proprietorship, is a type of
                  enterprise owned and run by one person and in which there is
                  no legal distinction between the owner and the business
                  entity. A sole trader does not necessarily work 'alone'—it is
                  possible for the sole trader to employ other people.
                </p>
                <p>
                  The sole trader receives all profits (subject to taxation
                  specific to the business) and has unlimited responsibility for
                  all losses and debts. Every asset of the business is owned by
                  the proprietor and all debts of the business are the
                  proprietor's. It is a "sole" proprietorship in contrast with
                  partnerships (which have at least two owners).
                </p>
                <p>
                  A sole proprietor may use a trade name or business name other
                  than their or its legal name. They may have to legally
                  trademark their business name if it differs from their own
                  legal name, the process varying depending upon country of
                  residence.
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={soleImg2}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="contetnt1" className="bg-light">
          <div className="container d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid p-3 "
                  src={soleImg3}
                  alt="Lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50 text-justify ">
                <h1>Who is Proprietor?</h1>

                <p>
                  The owner of a sole proprietorship business in India is called
                  a proprietor. It cannot be a corporate or legal entity. The
                  proprietor and the proprietorship are considered to be the
                  same entity legally.
                </p>

                <p>
                  The PAN and other documents of the proprietor are the basis
                  for obtaining all other business registrations and licenses.
                  In case of any issues of liability in the business, the
                  proprietor is held personally liable for it.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="documents" className="py-2">
          <div className="container  py-4">
            <div className="d-flex ">
              <h1 className="  secu text-dark text-center container col-md-7">
                Documents Required for Proprietorship
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3 shadow ">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="cards" className="cardssec py-3">
          <div className="container text-justify ">
            <div className="container  col-md-7">
              <h1 className="text-center py-4 secu ">
                Key Registrations of Sole Proprietor
              </h1>
              <p className="text-light text-center">
                Each proprietorship differs in terms of the functions, clientele
                and the mode of operations. Hence, a couple of the registrations
                mentioned below will be applicable.
              </p>
            </div>
            <div className="row pt-3">
              <div className="col-md-4 mb-4">
                <div className="card  h-100  item1">
                  <div className="title ">
                    <h4 className="text-light text-center">
                      {" "}
                      MSME registration
                    </h4>
                  </div>
                  <div className="body p-3 ">
                    <p className="text-light">
                      MSME or Udyog Aadhaar registration can be obtained in the
                      name of the business to establish that the sole
                      proprietorship is registered with the Ministry of Micro,
                      Small and Medium Enterprises. <br />
                      You can get yourself registered as Small and Medium
                      Enterprise (SME) under the MSME Act.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-4">
                <div className="card h-100 pb-3 item2">
                  <div className="title text-center">
                    <h4 className="text-light">TAN registration</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light">
                      The provisions of section 203A of the Income-tax Act
                      require all persons who deduct or collect tax at source to
                      apply for the allotment of a TAN.
                      <br />
                      TAN registration must be obtained for the proprietor from
                      the income tax department if the proprietor is making
                      salary payments wherein TDS deduction is required.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card   item3 h-100 ">
                  <div className="title text-center ">
                    <h4 className="text-light">GST registration</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      Sole Proprietorship Registration through GST Registration.
                      ... Every registered business has to compulsorily collect
                      the tax from the customers and file the GST returns.incase
                      If a sole proprietor has a turnover of less than Rs. 20
                      Lakhs, it is not mandatory for him to get registered and
                      collect GST.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-4 ">
              <div className="col-md-4 mb-4">
                <div className="card  item4 h-100 ">
                  <div id="title" className="title text-center">
                    <h4 className="text-light"> Import Export code</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light ">
                      The Importer -Exporter Code (IEC) is a key business
                      identification number which is mandatory for Exports or
                      Imports. No person shall make any import or export except
                      under an IEC Number granted by the DGFT. in case of a
                      proprietorship business undertaking export and/or import
                      of goods into India.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-4">
                <div className="card  h-100 item5">
                  <div className="title text-center">
                    <h4 className="text-light"> FSSAI registration</h4>
                  </div>
                  <div className="body p-3 mb-4">
                    <p className="text-light">
                      In case the proprietorship is involved in the selling of
                      food products or handling of food products,FSSAI
                      registration must be obtained from the Food safety
                      authority of india in the name of proprietor
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card  item6  h-100">
                  <div className="title text-center ">
                    <h4 className="text-light"> Current account</h4>
                  </div>
                  <div className="body p-3 ">
                    <p className="text-light">
                      A current account can be opened for a sole proprietorship
                      through Lopamudra Corporate Solutions from various banks
                      in India. We offers exclusive partnerships through which
                      zero-balance current accounts can be opened. It is
                      recommended that GST registration be obtained for the
                      same.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="faqs">
            <div className="container py-3">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid">
                  <h1 className="pb-3 text-light secu">
                    FAQs on Proprietorship
                  </h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par">
                        <h4> What is a sole proprietorship??</h4>
                        <p className="text-light">
                          A sole proprietorship qualifies as a company, but it
                          only has one owner and isn’t required to register with
                          state enemies. Beginning a sole proprietorship is
                          extremely simple and doesn’t require a formal filing
                          or paperwork process. All you have to do is simply go
                          into business.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Is GST mandatory for proprietorship??</h4>
                        <p className="text-light">
                          Sole Proprietorship Registration through GST
                          Registration. ... Every registered business has to
                          compulsorily collect the tax from the customers and
                          file the GST returns. If a sole proprietor has a
                          turnover of less than Rs. 20 Lakhs, it is not
                          mandatory for him to get registered and collect GST.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>How many owners are in a proprietorship?</h4>
                        <p className="text-light">
                          A proprietorship can legally have only one owner.
                          That's because the business serves as part of the
                          business owner's personal identity and vice versa.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>What is the advantage of proprietorship?</h4>
                        <p className="text-light">
                          One of the functional advantages of sole
                          proprietorships is that they are easier to set up than
                          other business entities. A person becomes a sole
                          proprietor simply by running a business. Another
                          functional advantage of a sole proprietorship is that
                          the owner maintains 100% control and ownership of the
                          business.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Still, have Confusion ?</h4>
                        <p className="text-light">
                          Don’t worry!! we are here to help you .Get in touch
                          with our team to get all your queries resolved. Write
                          to us at info@lopamudracs.com or 9966534699
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Proprietor;
