import React from "react";
import Sliders from "./sliders/Sliders";
import Adv from "./sliders/Adv";
import Results from "./sliders/results";
import Services from "./sliders/Services";
import Team from "./sliders/team";
import Latestnews from "./sliders/latestnews";
import Newsletter from "./sliders/newsletter";
import Vision from "./sliders/Vision";
import Getintouch from "./sliders/getintouch";
import Industries from "./sliders/Industries";
import Footer from "./Navigation/footer";
import Testimonial from "./sliders/Testimonial";
import Process from "./sliders/Process";
class Home extends React.Component {
  render() {
    return (
      <div>
        <Sliders />
        <Adv />
        <Results />
        <Services />
        <Team />
        <Process />
        <Newsletter />
        <Vision />
        <Industries />
        <Testimonial />
        <Latestnews />
        <Getintouch />
        <Footer />
      </div>
    );
  }
}
export default Home;
