import React from "react";
import Footer from "../Navigation/footer";
import TradeopImg from "../../assets/images/Tradeop.jpg";
import TradeopImg1 from "../../assets/images/Tradeop-1.png";
import FAQImg1 from "../../assets/images/FAQ-1.png";
class Trademarkopposition extends React.Component {
  render() {
    return (
      <div>
        <section id="page content" className=" p-3 mb-5 bg-light">
          <div className="sidebar-right background-grey ">
            <div className="container pt-4">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive"
                          src="https://images.unsplash.com/photo-1553484771-4e29a68540f4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDV8fGVudHJlcHJlbmV1cnxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=500&q=60"
                          alt="enterpreneur"
                        />
                      </div>

                      <div className="col-lg-7">
                        <h5 className="title">Trademark Opposition</h5>
                        <hr />
                        <p className=" text-justify">
                          Lopamudra in India has a dedicated team of company
                          secretarial, legal professionals and Chartered
                          Accountants who possess extensive experience in
                          various corporate laws and commercial / legal
                          documentation. Our team is highly business oriented in
                          approach and has experience across sectors and the
                          acumen to provide complete solutions for all corporate
                          secretarial matters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 mb-5">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container  d-flex sidebar-right ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50 ">
                <h1>Who can Oppose a Trademark Application :</h1>
                <p className="text-justify">
                  According to Section 21 of the Trademark Act, “any person” can
                  file a trademark notice, irrespective of commercial or
                  personal interest in the matter. The question of bona fides of
                  the opponent does not arise. Hence, trademark opposition
                  filing can be filed by a customer, member of the public or
                  competitor or any other person. Also, the person filing the
                  trademark opposition need not be a prior registered trade mark
                  owner.
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={TradeopImg1}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="contetnt1" className="bg-light">
          <div className=" container py-4 d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid  p-3  "
                  src={TradeopImg}
                  alt="Lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50  ">
                <h1>Reasons for Trademark Opposition :</h1>
                <p className="text-justify">
                  A trademark opposition can be filed under different sections
                  like absolute grounds , relative grounds, prohibited mark or
                  the opponent may also raise their own objection with regard to
                  the proprietorship of the trademark opposed. The Indian
                  Trademark Law does not provide any specific grounds of
                  opposition. Hence, the reason for trademark opposition could
                  be varied.
                </p>
                <p>
                  The following are some of the grounds for trademark opposition
                  in India:
                </p>

                <ol>
                  <p>
                    <li>
                      The trademark is similar or identical to an earlier or
                      existing registered trademark.
                    </li>
                    <li>The trademark is devoid of distinctive character.</li>
                    <li>The trademark is descriptive in nature.</li>
                    <li>
                      The trademark registration application is made with bad
                      faith.
                    </li>
                    <li>
                      The trademark is customary in the currently language and
                      or in the established practices of a business.
                    </li>
                    <li>
                      The trademark is likely to deceive the public or cause
                      confusion.
                    </li>
                    <li>
                      The trademark is contrary to the law or prevented by law.
                    </li>
                    <li>
                      The trademark is prohibited under the Emblem and Names
                      Act, 1950.
                    </li>
                    <li>
                      The trademark contains matters that are likely to hurt
                      religious feelings of any class or section of people.
                    </li>{" "}
                  </p>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section id="documents" className="py-3">
          <div className="container  py-2">
            <div className="d-flex container col-md-7 text-center">
              <h1 className="secu mb-4 text-dark pb-2">
                Documents Required for Trademark Opposition
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow p-3">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3 shadow p-3 ">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cardssec">
          <div className="container  py-5">
            <div className="text-center container col-md-7">
              <h4 className=" secu">Trademark Opposition process</h4>
              <p className="text-light">
                To initiate trademark opposition proceedings a notice of
                trademark opposition can be filed by any person within four
                months of date of advertisement or re-advertisement of the
                application in the Trademark Journal. The trademark opposition
                must be on Trademark Form 5 in the prescribed manner and filed
                with applicable fees. The trademark opposition notice should
                contain the following information:
              </p>
            </div>
            <div className="row mt-3">
              <div className=" col-lg-4   pb-4">
                <div className="card text-white h-100 item1">
                  <h5 className="card-title text-center">01</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Application against which trademark opposition is entered
                      including trademark registration application number, class
                      of good or services for which trademark registration
                      application was made and the name of applicant of the
                      trademark application.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    pb-4 ">
                <div className="card text-white  h-100 item2">
                  <h5 className="card-title text-center">02</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Details of the trademark opposition party including name
                      and address
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4   pb-4 ">
                <div className="card text-white  h-100  item3">
                  <h5 className="card-title text-center">03</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      The grounds for trademark opposition..
                    </p>
                    <p className="text-light text-justify">
                      {" "}
                      Based on the trademark opposition filed and the
                      counter-statement filed, the Registrar may call for the
                      hearing of the parties. The Registrar then has the option
                      to rule on the trademark registration application and
                      trademark opposition filing based on the evidence
                      presented.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="faqs">
            <div className="container py-5">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid ">
                  <h1 className="pb-3 secu text-light">
                    FAQs on Trademark Registration
                  </h1>
                  <h4 className="pb-4">Get answer to all your quiries</h4>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par text-light">
                        <h4>What is a trademark class ?</h4>
                        <p className="text-light">
                          To standardise the goods or services which the
                          trademark will represent, the trademark registry has a
                          list of 45 Classes under which all types of goods and
                          services are represented. The trademark application
                          must mention the type of goods or services the
                          trademark will represent under one or more of the
                          classes. Trademark registration is granted for that
                          specific class of goods or service.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>When can i use the TM mark?</h4>
                        <p className="text-light">
                          The TM mark can be used along with the trademark once
                          the trademark application is filed with the Controller
                          General of Patents Designs and Trademarks.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4> When can I use the ® mark?</h4>
                        <p className="text-light">
                          The ® mark can be used once the trademark application
                          is approved by the Controller General of Patents
                          Designs and Trademarks and trademark registration is
                          provided.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>How long is a registered trademark valid for?</h4>
                        <p className="text-light">
                          Registered trademarks are valid for 10 years from the
                          date of filing. The owner of the mark can file for
                          renewal to keep the trademark protection current prior
                          to the end of its validity.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>What if the trademark application gets refused?</h4>
                        <p className="text-light">
                          If the trademark examiner sends a hearing notice to
                          the applicant and if there is no response to the
                          hearing notice within 30 days of receipt, then the
                          application is automatically refused. In such a case,
                          a new application needs to be filed..
                        </p>
                      </li>

                      <li className="col-lg-12 faq_par">
                        <h4>What is the meaning of the status abonded?</h4>
                        <p className="text-light">
                          If the trademark examiner raises an objection to the
                          application and if there is no response to the
                          objection notice within 30 days of receipt, then the
                          application is automatically refused. In such a case,
                          a new application needs to be filed.
                        </p>
                      </li>

                      <li className="col-lg-12 faq_par">
                        <h4>Still, have confusion?</h4>
                        <p className="text-light">
                          Don’t worry!! Our expert will help you to choose a
                          best suitable plan for you. Get in touch with our team
                          to get all your queries resolved. Write to us at
                          info@lopamudracs.com or call us @+91 9966534699.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Trademarkopposition;
