import React from "react";
import { NavLink, Link } from "react-router-dom";
import Footer from "../Navigation/footer";
// import Carousel from "react-bootstrap/Carousel";
import Getintouch from "../sliders/getintouch";
import FACTImg1 from "../../assets/images/FACT-1.jpg";
class Factories extends React.Component {
  render() {
    return (
      <div>
        <section className="FACTORIES">
          <div className="container ">
            <div className="row">
              <div className="col-md-9">
                <div className="service-description-img">
                  <img
                    src={FACTImg1}
                    alt="lopamudra"
                    className="embed-responsive"
                  />
                </div>
                <div className="discription ">
                  <h3 className="title pt-3">Factories Act</h3>
                  <hr className="decor" />

                  <p>
                    Compliance with Labour and Industrial Laws has gathered
                    enormous momentum in the last few years. Especially
                    compliance with respect to employees employed through
                    Contractors and off-roll employees is getting more
                    challenging because the Law recognizes principal employer as
                    the one who is responsible in case the contractor fails to
                    comply. This area has assumed significant importance from a
                    risk management perspective considering the legal
                    implications and reputational risks attached with
                    non-compliance. Moreover, ‘Labour Laws’ is a very
                    specialized area, requiring additional managerial attention
                    over and above the routine internal audit function.
                  </p>
                  <h3 className="whatis">How we can help:</h3>
                  <ul>
                    <li>
                      <p>
                        Assuring proper compliances with various Labour Laws
                        including Contract Labour compliances
                      </p>{" "}
                    </li>
                    <li>
                      <p>
                        In-depth checking of bills submitted by Contractor
                        Agencies with respect to employees employed through
                        contractors / off-roll employees to evaluate adherence
                        with terms of contract, submission of necessary
                        evidences as proof of payment, billing as per agreed
                        rates to ensure there is no under / over recovery
                      </p>{" "}
                    </li>
                    <li>
                      <p>
                        Verification of payroll sheets submitted by the Vendors
                        to ensure correct calculation of salary payable and
                        statutory deductions in line with applicable Central and
                        State Government laws
                      </p>
                    </li>
                    <li>
                      <p>
                        Verification of challans / returns / records to ensure
                        proper compliance
                      </p>{" "}
                    </li>
                    <li>
                      <p>
                        Due diligence review for the appointment of Contractor
                        Agency to ensure that the Agency meets the desired
                        compliance requirements
                      </p>{" "}
                    </li>
                    <li>
                      Labour Law Compliances
                      <ul className="disc">
                        <li>
                          <p>PF, ESI compliances</p>
                        </li>
                        <li>
                          <p>Shops &amp; Establishment registrations</p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        Preparation of Employee hand book/ HR Policy in
                        compliance with respective state labour laws{" "}
                      </p>{" "}
                    </li>
                    <li>
                      <p>
                        Drafting/vetting of Employment Agreement, Non disclosure
                        agreement, Bond for trainees and junior employees
                      </p>
                    </li>
                    <li>
                      <p>
                        Drafting/vetting of Offer Letter to the prospective
                        employee{" "}
                      </p>
                    </li>
                    <li>
                      <p>
                        Drafting of Appointment Letter, Relieving Drafting of
                        Appointment Letter and No Due Certificate
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 mt-2 bg-light ">
                <div className=" mt-2 mb-5 ">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
                <div className="side-bar-list">
                  <p className="side-bar-title">Other Services</p>
                  <ul className="list-group  ">
                    <li className="list-group-item  bg-dark">
                      <Link to="/RD"> RD</Link>
                    </li>
                    <li className="list-group-item bg-dark">
                      <Link to="/Liquidator"> Liquidator</Link>
                    </li>
                    <li className="list-group-item bg-dark">
                      <Link to="/Rocl">ROC</Link>
                    </li>
                    <li className="list-group-item bg-dark">
                      <Link to="/DGFT"> DGFT</Link>
                    </li>
                    <li className="list-group-item bg-dark">
                      <Link to="/Tribunals">Tribunals</Link>
                    </li>
                    <li className="list-group-item bg-dark">
                      <Link to="/IPR">
                        IPR (Trade mark, Copyrights, Patents)
                      </Link>
                    </li>
                    <li className="list-group-item bg-dark">
                      <Link to="/Labourlaws">Labour law’s</Link>
                    </li>
                    <li className="list-group-item bg-dark">
                      <Link to="/NCLT"> NCLT</Link>
                    </li>
                    <li className="list-group-item bg-dark">
                      <Link to="/EPA">Environmental Protection Act</Link>
                    </li>
                    <li className="list-group-item bg-dark">
                      <Link to="/PCA">Pollution Control Acts</Link>
                    </li>
                    <li className="list-group-item bg-dark">
                      <Link to="/Legalopenions">Legal Opinions</Link>
                    </li>
                    <li className="list-group-item bg-dark">
                      <Link to="/Drafting">
                        Drafting of various Deed & Agreement
                      </Link>
                    </li>
                  </ul>
                </div>{" "}
              </div>{" "}
            </div>
          </div>
        </section>

        <section>
          <div className="container ">
            <div className="row shadow py-3 ">
              <div className=" col-md-9">
                <h1 className="nclt  ">What is Factories Act?</h1>
                <p className="text-left justify-text ">
                  The National Company Law Tribunal is a quasi-judicial body in
                  India that adjudicates issues relating to Indian companies.
                  ... The NCLT bench is chaired by a Judicial member who is
                  supposed to be a retired or a serving High Court Judge and a
                  Technical member who must be from the Indian Corporate Law
                  Service, ICLS Cadre.
                </p>
                <p className="text-left">
                  National Company Law Tribunal(NCLT) is established by the
                  Supreme Court to order to handle the laws regarding to the
                  companies. The NCLT operates as a quasi-judicial authority
                  which handles the structures, laws and settles disputes which
                  are related to corporate cases.
                </p>
                <p className="text-left">
                  The NCLT is formed on Article 245 in the constitution of
                  India. Ever since the 1900s, India has had several laws to
                  deal with industries and companies within the country. The
                  East India Company functioned as per the guidelines laid out
                  in the Royal Charter and the Indian Companies Act which was
                  established in 1913, revised in 1956 and then later amended
                  multiple times all dealt with the running of firms and
                  companies. Recently, in June 2016, a National Company Law
                  Tribunal (NCLT) and National Company Law Appellate Tribunal
                  (NCLAT) were established by the Supreme Court to handle laws
                  regarding companies.
                </p>
                <div className=" text-left justify-text">
                  <h1 className="text-left py-3 ">formation :</h1>
                  <ol>
                    <li>
                      <p>
                        The National Company Law Tribunal works as a
                        quasi-judicial authority that handles structures and
                        settles disputes and laws related to corporate cases.
                      </p>{" "}
                    </li>
                    <li>
                      <p>
                        Both the NCLT and NCLAT were formed based on Article 245
                        of India’s Constitution.
                      </p>
                    </li>
                    <li>
                      <p>
                        The Eradi Committee is credited with developing the
                        Tribunal, which functions as a court of law that handles
                        corporate cases.
                      </p>{" "}
                    </li>
                    <li>
                      <p>
                        The Tribunal is expected to fact check and hears out
                        discussions to conclude legal matters concerned with
                        corporations.
                      </p>{" "}
                    </li>
                    <li>
                      <p>
                        The authority to close cases related to companies and
                        factories was officially transferred from the CLB to the
                        NCLT, making it the Supreme Power when it comes to such
                        legal matters. The pending cases under the CLB were also
                        shifted to the NCLT as per the guidelines laid out in
                        Section 434 of the Companies Act.
                      </p>{" "}
                    </li>
                    <li>
                      <p>
                        The law decreed that the judicial powers of the High
                        Court, the Appellate Authority for Industrial and
                        Financial Reconstruction (AAIFR) and Board of Industrial
                        and Financial Reconstruction (BIFR) would all lie on the
                        Tribunal, making it an independent authority on such
                        matters.
                      </p>
                    </li>
                    <li>
                      <p>
                        The Tribunal, therefore, handles cases related to every
                        company listed in India, sparing financial institutions
                        such as banks and turned active on June 1st, 2016.
                      </p>{" "}
                    </li>
                    <li>
                      <p>
                        It currently has one principle bench and ten other
                        benches under it.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="py-5 mt-5">
          <div className="container ">
            <div className="row align-items-center ">
              <div className="col-lg-6 ">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-6690LIS_kgZbSD60v1Y-SliMQ3uyDRKlkQ&usqp=CAU"></img>
              </div>
              <div className="col-lg-6">
                <h5 className="title">What People Say</h5>
                <h2>
                  Client{" "}
                  <span className="featured">
                    <span className="bgd">&nbsp;Testimonial&nbsp;</span>
                  </span>
                </h2>

                <Carousel>
                  <Carousel.Item interval={1000}>
                    <img
                      className="d-block w-100"
                      src="https://img.glyphs.co/img?q=85&w=900&src=aHR0cHM6Ly9zMy5tZWRpYWxvb3QuY29tL2ltYWdlcy9oZHdlYi9oZHdlYl8xLmpwZw=="
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <h3>First slide label</h3>
                      <p>
                        Nulla vitae elit libero, a pharetra augue mollis
                        interdum.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item interval={500}>
                    <img
                      className="d-block w-100"
                      src="https://img.glyphs.co/img?q=85&w=900&src=aHR0cHM6Ly9zMy5tZWRpYWxvb3QuY29tL2ltYWdlcy9oZHdlYi9oZHdlYl82LmpwZw=="
                      alt="Third slide"
                    />
                    <Carousel.Caption>
                      <h3>Second slide label</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIfhc9Oq3ElBhE8vb8iOUE8TpPJwXSAZC_eA&usqp=CAU"
                      alt="Third slide"
                    />
                    <Carousel.Caption>
                      <h3>Third slide label</h3>
                      <p>
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </section> */}
        <Getintouch />
        <Footer />
      </div>
    );
  }
}
export default Factories;
