import React from "react";
import Footer from "../Navigation/footer";
import FFImg1 from "../../assets/images/FF-1.png";
class SandE extends React.Component {
  render() {
    return (
      <div>
        <section id="GST">
          <div className="jumbotron7 jumbotron-fluid">
            <div className="container">
              <h2 className=" pb-0 text-center  text-light ">
                <span className="bgc">&nbsp;S&E&nbsp;</span>
                <h2 className="text-dark">Registrations</h2>
              </h2>
              <p></p>
            </div>
          </div>
        </section>
        <section id="single" className="section single">
          <div className="container py-5">
            <div className="row">
              <div className="col-12 col-lg-8 p-0 text-fluid ">
                <div className="row intro m-0">
                  <div className="col-12">
                    <span className="pre-title m-0">
                      Know what the law requires
                    </span>
                    <h2>
                      <span> S&E Registrations </span>
                    </h2>
                    <p>
                      The Shops and Establishment Act is enacted to ensure
                      quality work atmosphere for employees. A venture need to
                      get the registration done when it employs it first
                      employee (with or without wages).
                    </p>
                    <h4>
                      Applicability of Shop & Establishment Registration :
                    </h4>
                    <p>
                      Every shop and establishment needs to register itself
                      compulsorily under this Act within 30 days of commencement
                      of work. As this is such a basic license, many other
                      licenses require this as proof of a commercial business.
                      For example, most banks will require you to furnish it if
                      you want to open a current account. To get this license,
                      you would need to provide the PAN card of the business
                      owner or the business itself, a copy of the rental
                      agreement or sale deed, and details of all the employees.
                      Premises governed by the Act are shops, commercial
                      establishments, residential hotels, clubs, restaurants,
                      eating houses, theaters and other places of public
                      amusement or entertainment etc. except those who falls
                      under Factories Act 1948.
                    </p>
                    <h4>Registration under Shop & Establishment Act:</h4>
                    <p>
                      The Shop and Establishment Act registration regulates
                      hours of work, payment of wages, leave, holidays, terms of
                      service and other conditions of work of persons employed
                      in shops, commercial establishments, establishments for
                      public entertainment or amusement and other establishments
                      and to provide for certain matters connected therewith.
                      This registration is compulsory for every business place
                      of work except those who falls under Factories Act 1948.
                      It is a state regulation and each state has separate shop
                      & establishment regulation.
                    </p>
                  </div>
                </div>
              </div>

              <aside className="col-12 col-lg-4 pl-lg-8 pt-5   ">
                <div className="shadow p-3 mb-5">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2 " id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </aside>
            </div>
          </div>
        </section>

        <section className="bg-light py-5 Documents-section">
          <div className="container ">
            <h1 className="text-center ">Documents Required for S & E</h1>
            <div className="container text-center">
              <div className="row">
                <div id="flag" className="col-md-4  py-3 pl-5">
                  <h4 className="subheadingtitle  ">
                    &nbsp;Pvt. Ltd. /Public Ltd./OPC{" "}
                  </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      Company PAN card
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      MOA, AOA &amp; Incorporation Certificate
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Company Address Proof
                    </p>
                  </ul>
                </div>
                <div className="col-md-4 py-3  pl-5 ">
                  <h4 className="subheadingtitle ">
                    LLP &amp; Partnership Firm{" "}
                  </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Firm PAN card
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      LLP Agreement/ Partnership deed
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Firm Address Proof
                    </p>
                  </ul>
                </div>
                <div className="col-md-4  py-3 pl-5  ">
                  <h4 className="subheadingtitle">Proprietorship Firm </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Proprietor's PAN card
                    </p>
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      Shop Act / Ghumasta License
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Address proof of Firm
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cardssec">
          <div className="container  py-5">
            <div className="text-center">
              <h4 className=" secu container col-md-6">
                Features of the Shop & Establishment Registration
              </h4>
              <p className="text-light"></p>
            </div>
            <div className="row mt-3">
              <div className=" col-lg-6   pb-4">
                <div className="card text-white h-100 item1">
                  <h5 className=" card-title text-center">
                    Legal Entity Proof
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      Each shop or business required a legal entity proof like
                      proprietorship firm or partnership firm etc. a legal
                      entity proof which gives the right for doing the business
                      in your area or state for the same.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-6    pb-4 ">
                <div className="card text-white  h-100 item2">
                  <h5 className=" card-title text-center">
                    Business Bank Account
                  </h5>{" "}
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Every banks is required a legal entity proof to open a
                      current bank account. Each shop & Establishment has to
                      open a separate bank account for the daily transactions as
                      per the RBI Compliance. So on the basis of the shop
                      certificate you can easily open a current business bank
                      account.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-6  ">
                <div className="card text-white  h-100  item3">
                  <h5 className=" card-title text-center">
                    Smooth inspections
                  </h5>{" "}
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      State government and local municipality conduct regular
                      inspection visits for checking the proper working of shops
                      and establishments along with license as well. If you have
                      the Shop Act license, then you can quickly get over the
                      inspections every time.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6  ">
                <div className="card text-white  h-100  item4">
                  <h5 className=" card-title text-center">
                    Government Benefits{" "}
                  </h5>{" "}
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Each state have a DIC Department which creates the policy
                      in the respective state for the all small business. On the
                      basis of Shop License Registration Certificate you can
                      easily avail the government benefits.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* --------------accordion for Faqs------------*/}
        <section className="py-3 faqs">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <img
                  src={FFImg1}
                  alt="Lopamudra"
                  className="embed-responsive"
                ></img>
              </div>
              <div className="wrap col-lg-7 shadow">
                <div className="container demo text-justify">
                  <h1 className="pb-3 text-light secu">
                    FAQs on S and E Registrations
                  </h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div
                    className="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div className="panel panel-default mt-3">
                      <div className="panel-heading" role="tab" id="headingOne">
                        <h4 className="panel-title text-light">
                          <a
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            className="seccccc "
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Who implements the provisions of this act? ?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseOne"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingOne"
                      >
                        <div className="panel-body ">
                          Enforcement of the provisions of this Act is the
                          responsibility of local authorities in their
                          respective jurisdiction and of State Govt. in
                          respective areas. Local authority or State Govt. may
                          appoint necessary number of inspectors for the purpose
                          of this Act.
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTwo">
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            When do I need to get Shops & Establishment
                            Registration for my venture?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseTwo"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div className="panel-body">
                          According to the Act, you will have to register for
                          S&E Registration within 30 days of commencement of
                          work. Here commencement of work can be interpreted as
                          the date you employ your first employee
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingThree"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Am I liable to do any filings once I get the Shops &
                            Establishment registration done?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseThree"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingThree"
                      >
                        <div className="panel-body">
                          There are no filings that you need to do after getting
                          the Registration. However, the company has to maintain
                          the registers and records of employee attendance,
                          leave, fines & penalties, advances and wages.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingfour"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsefour"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Whether Provident Fund Act 1952 is applicable to
                            Shops and Establishments
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsefour"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingfour"
                      >
                        <div className="panel-body">
                          Yes, the act is applicable to any establishment
                          employing 20 or more employees..
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingfive"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsefive"
                            aria-expanded="false"
                            aria-controls="collapsefive"
                          >
                            Still, have confusion ?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsefive"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingfive"
                      >
                        <div className="panel-body">
                          Don’t worry!! Our expert will help you to choose a
                          best suitable plan for you. Get in touch with our team
                          to get all your queries resolved. Write to us at
                          info@lopamudracs.com or call us @+91 9966534699.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default SandE;
