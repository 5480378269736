import React from "react";
import Carousel from "react-bootstrap/Carousel";
import bgImg from "../../assets/images/bg-1.jpg";
import bgImg2 from "../../assets/images/bg-2.jpg";
import bgImg3 from "../../assets/images/bg-3.jpg";
class Sliders extends React.Component {
  render() {
    return (
      <div>
        <Carousel>
          <Carousel.Item>
            <img className="d-block w-100" src={bgImg} alt="Lopamudra" />
            <Carousel.Caption>
              <div className="slide-content2 text-left row">
                <h2 className="slide3">Business Consulting</h2>
                <p className="parag">
                  We drive product and service innovation, improve financial
                  performance, accelerate market speed.
                </p>

                <a id="sees" href="#" className="smooth-anchor mt-4 btn ">
                  GET IN TOUCH
                </a>
                <a
                  id="see"
                  href="#"
                  className="smooth-anchor ml-sm-4 mt-4 btn outline-button"
                >
                  READ MORE
                </a>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={bgImg2} alt="Lopamudra" />

            <Carousel.Caption>
              <div className="col-12 center dd ">
                <h2 className=" text-light dddd">Financial Risk</h2>
                <p className="  mt-5 parag">
                  The right outcomes depend on continuous rigor in governance,
                  models, and processes across the finance function.
                </p>

                <a id="sees" href="#" className="smooth-anchor mt-4 btn ">
                  GET IN TOUCH
                </a>
                <a
                  id="see"
                  href="#"
                  className="smooth-anchor ml-sm-4 mt-4 btn outline-button"
                >
                  READ MORE
                </a>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={bgImg3} alt="Lopamudra" />

            <Carousel.Caption>
              <div className="slide-content row">
                <h2 className=" slide2 text-justify">
                  Audit&amp;&nbsp;&nbsp; Assurance
                </h2>
                <p className="parag">
                  Our focus is to map the technologies to solve the business
                  transformation, offering services.
                </p>

                <a
                  id="sees"
                  href="#"
                  className="smooth-anchor mt-4 ml-sm-4  btn "
                >
                  GET IN TOUCH
                </a>
                <a
                  id="see"
                  href="#"
                  className="smooth-anchor ml-sm-4 mt-4 btn outline-button"
                >
                  READ MORE
                </a>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}
export default Sliders;
