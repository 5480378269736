import React from "react";
import FFImg1 from "../../assets/images/FF-1.png";
import Footer from "../Navigation/footer";

class RERA extends React.Component {
  render() {
    return (
      <div>
        <section id="RERA">
          <div className="jumbotron3 jumbotron-fluid">
            <div className="container">
              <h2 className=" pb-0 text-center  text-light ">
                <span className="bgc">&nbsp;RERA&nbsp;</span>
              </h2>
            </div>
          </div>
        </section>
        <section id="single">
          <div className="container py-5">
            <div className="row ">
              <div className="col-12 col-lg-8 p-0 text-fluid ">
                <div className="row intro m-0">
                  <div className="col-12">
                    <span className="pre-title m-0">
                      Know what the law requires
                    </span>
                    <h2>
                      <span className="reg">RERA :</span>{" "}
                    </h2>
                    <p>
                      The Real Estate Regulation & Development Act (RERA) which
                      came into effect on May 1, 2017, provides India’s real
                      estate sector its very first regulator. It will bring
                      clarity & establish fair practices that would protect the
                      interests of home buyers.
                    </p>

                    <h1>The purpose of the act is:</h1>

                    <p>
                      {" "}
                      To establish regulation and promotion of Real Estate
                      sector
                    </p>

                    <p>
                      To ensure transparency &amp; efficiency in selling Real
                      Estate
                    </p>
                    <p>
                      * To protect the interest of consumers and establish an
                      adjudicating mechanism for speedy dispute redressal in
                      Real Estate sector
                    </p>
                    <p>
                      * To publish and maintain a website with records of
                      registered real estate projects and agents for public
                      viewing.
                    </p>
                    <p>
                      * To establish Appellate Tribunal to hear appeals from the
                      decisions, directions or orders of the Real Estate
                      Regulatory Authority
                    </p>
                  </div>
                </div>
                <div className="row text-justify ">
                  <div className="col-12 align-self-center pl-4 pr-4"></div>
                </div>
              </div>

              <aside className="col-12 col-lg-4 pl-lg-8 pt-5   ">
                <div className="shadow p-3 mb-5">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2 " id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </aside>
            </div>
          </div>
        </section>

        <section className="bg-light py-5 Documents-section">
          <div className="container ">
            <h1 className="text-center ">
              Documents Required for RERA Registrations
            </h1>
            <div className="container text-center">
              <div className="row">
                <div id="flag" className="col-md-4  py-3 pl-5">
                  <h4 className="subheadingtitle  ">
                    &nbsp;Pvt. Ltd. /Public Ltd./OPC{" "}
                  </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      Company PAN card
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      MOA, AOA &amp; Incorporation Certificate
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Company Address Proof
                    </p>
                  </ul>
                </div>
                <div className="col-md-4 py-3  pl-5 ">
                  <h4 className="subheadingtitle ">
                    LLP &amp; Partnership Firm{" "}
                  </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Firm PAN card
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      LLP Agreement/ Partnership deed
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Firm Address Proof
                    </p>
                  </ul>
                </div>
                <div className="col-md-4  py-3 pl-5  ">
                  <h4 className="subheadingtitle">Proprietorship Firm </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Proprietor's PAN card
                    </p>
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      Shop Act / Ghumasta License
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Address proof of Firm
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cardssec">
          <div className="container  py-5">
            <div className="text-center">
              <h4 className=" secu container col-md-6">
                Read more about RERA Registration
              </h4>
              <p className="text-light">
                Here are the top features of RERA that you should know about.
              </p>
            </div>
            <div className="row mt-3">
              <div className=" col-lg-4   pb-4">
                <div className="card text-white h-100 item1">
                  <h5 className="card-title text-center">
                    On-time possession of the property
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      The biggest loophole in the real estate sector has been
                      fake promises by promoters of completion and delivery of
                      projects, thereby causing huge financial worries and
                      mistrust among the buyers. When promoters register under
                      the RERA, they must provide the estimated date of
                      completion of the project, failing which there will be
                      huge penalties imposed on the promoter.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    pb-4 ">
                <div className="card text-white  h-100 item2">
                  <h5 className="card-title text-center">Security</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      A minimimum of 70% of investors' and buyrs' money would be
                      kept in a seperate escrow account. This money can only bw
                      spent on construction of the project and the cost borne
                      towards the land.
                    </p>
                    <p className="text-light text-justify">
                      {" "}
                      Developers can't ask more than 10% of the property's cost
                      as an advanced payment booking amount before the
                      registered sale aggreement is signed
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4   pb-4 ">
                <div className="card text-white  h-100  item3">
                  <h5 className="card-title text-center">Transparency</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Under RERA, builders are required to submit the original
                      approved plans for their ongoing projects and the
                      alternations that they made later to the regulator.
                    </p>
                    <p className="text-light text-justify ">
                      Developers can't make any chnages to the plan without the
                      written consent of the buyer.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4  mt-3 ">
                <div className="card text-white  h-100  item4">
                  <h5 className="card-title text-center">Fairness</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Under RERA.properties will be sold based on carpet area.
                      and not on super built-up area.
                    </p>
                    <p className="text-light text-justify">
                      In the event of project delay. buyers area entitled to
                      receive the entire money invested or they can stay
                      invested and receive monthly interest
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    mt-3 ">
                <div className="card text-white  h-100 item5">
                  <h5 className="card-title text-center ">Quality</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      If any issue is highlighed by the buyer to the regulator
                      within 5 years from the date of possession, then it has to
                      be fixed within 30 days by the builder.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    mt-3">
                <div className="card text-white  h-100 item6 ">
                  <h5 className="card-title text-center">Authorisation</h5>
                  <div className="card-body csaa">
                    <div className="card-body csaa">
                      <p className="text-light ">
                        Without registration with the regulator.a developer
                        would not be able to
                        invite,advertise,sell,offer,building or any other
                        investment in the project.Post registration, all the
                        advertisement soliciting investment will have to bear
                        the unique project-wise RERA registration number.
                      </p>
                      <p className="text-light "></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* --------------accordion for Faqs------------*/}
        <section className="py-3 faqs">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <img
                  src={FFImg1}
                  alt="Lopamudra"
                  className="embed-responsive"
                ></img>
              </div>
              <div className="wrap col-lg-7 shadow">
                <div className="container demo text-justify">
                  <h1 className="pb-3 text-light secu">FAQs on RERA</h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div
                    className="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div className="panel panel-default mt-3">
                      <div className="panel-heading" role="tab" id="headingOne">
                        <h4 className="panel-title text-light">
                          <a
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            className="seccccc "
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Which projects comes under the purview of RERA ?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseOne"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingOne"
                      >
                        <div className="panel-body ">
                          all the real estate projects for which Building
                          permissions were approved on or after 01-01-2017 by
                          the Competent Authorities viz., UDAs / DTCP /
                          Municipal Corporations / Municipalities / Nagar
                          Panchayats / TSIIC as the case may be comes under the
                          purview of RERA Act & Rules except the projects where:
                          <ul>
                            <li>
                              Area of land proposed to be developed does not
                              exceed ve hundred square meters
                            </li>
                            <li>
                              Number of apartments proposed to be developed does
                              not exceed eight inclusive of all phases
                            </li>
                            <li>
                              Renovation or repair or re-development which does
                              not involve marketing, advertising selling or new
                              allotment of any apartment, plot or building, as
                              the case may be, under the real estate project
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTwo">
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Can a promoter or a real estate agent also le
                            complaint against a buyer?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseTwo"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div className="panel-body">
                          Yes. An aggrieved person having any interest in the
                          registered real estate project can le complaint.
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingThree"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            On what grounds can the home buyer le a complaint?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseThree"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingThree"
                      >
                        <div className="panel-body">
                          An aggrieved person may le a complaint with TSRERA for
                          any violation or contravention of the provisions of
                          the Act or the Rules or Regulations made there under.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingfour"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsefour"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Is there any time limit prescribed for disposal of
                            complaints?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsefour"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingfour"
                      >
                        <div className="panel-body">
                          Section 29 of the Act provides that complaints should
                          be disposed off as expeditiously as possible but not
                          later than sixty days from the date of ling the same.
                          However, where it cannot be disposed of during the
                          said period, the Real Estate Regulatory Authority is
                          required to record its reasons for the same.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingfive"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsefive"
                            aria-expanded="false"
                            aria-controls="collapsefive"
                          >
                            Still, have Confusion ?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsefive"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingfive"
                      >
                        <div className="panel-body">
                          Don’t worry!! we are here to help you .Get in touch
                          with our team to get all your queries resolved. Write
                          to us at info@lopamudracs.com or 9966534699.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default RERA;
