import React from "react";
import Getintouch from "./sliders/getintouch";
import Footer from "./Navigation/footer";

class Contact extends React.Component {
  render() {
    return (
      <div>
        <section id="contactus">
          <div className="contactss py-5 text-center">
            <h2>Contact us</h2>
          </div>
        </section>
        <Getintouch />
        <section id="about" className=" py-5">
          <div className="container py-5">
            <div className="row">
              <div className=" col-md-4 ">
                <div className="col-12 p-0">
                  <span className="pre-title m-0 ">Talk to an expert</span>
                  <h2>
                    <span className="bgd">&nbsp;Our&nbsp; </span>
                    <br />
                    Business
                    <br />
                    Units
                  </h2>
                  <p>
                    Curabitur convallis, diam a egestas iaculis, neque lorem
                    interdum felis, in viverra lacus tortor in leo.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4 pr-md-5 align-self-top text pl-5">
                <div className="row items ">
                  <div className="contacts">
                    <h4 id="A" className="pt-2  branch">
                      <strong>Branch</strong>
                    </h4>

                    <ul className="navbar-nav">
                      <li className="nav-item pb-3">
                        <a href="#" className="nav-link add">
                          <i
                            id="icon"
                            className="fa fa-phone  mr-2 "
                            aria-hidden="true"
                          ></i>
                          +91-7702397397
                        </a>
                      </li>
                      <li className="nav-item ">
                        <a href="#" className="nav-link add">
                          <i
                            id="icon"
                            className="fa fa-envelope mr-2 "
                            aria-hidden="true"
                          ></i>
                          info@lopamudracs.com
                        </a>
                      </li>
                      <br />
                      <li className="nav-item">
                        <a
                          href="https://goo.gl/maps/SdYGXp7pAf1TCYAD9"
                          className="nav-link add"
                        >
                          <i
                            id="icon"
                            className="fa fa-map-marker mr-2  "
                            aria-hidden="true"
                          ></i>
                          7-2-1813/5/A Opposite SBI{" "}
                          <span className="addres">
                            {" "}
                            bank,Street no.1,Czech
                          </span>
                          Colony{" "}
                          <span className="addres">,Sanath Nagar,HYD-18</span>
                        </a>
                      </li>
                      <li className="nav-item pt-3">
                        <a
                          id="see"
                          href="https://goo.gl/maps/SdYGXp7pAf1TCYAD9"
                          className="mt-2 btn text-dark "
                          target="_blank"
                        >
                          VIEW MAP
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 pr-md-5 align-self-top pl-5">
                <div className="row items">
                  <div className="contacts">
                    <h4 id="A" className="pt-2 branch">
                      <strong>Headquarters</strong>
                    </h4>

                    <ul className="navbar-nav">
                      <li className="nav-item pb-3">
                        <a href="#" className="nav-link add">
                          <i
                            id="icon"
                            className="fa fa-phone  mr-2  pb-3"
                            aria-hidden="true"
                          ></i>
                          +91-9966534699
                        </a>
                      </li>
                      <li className="nav-item pb-3">
                        <a href="#" className="nav-link add">
                          <i
                            id="icon"
                            className="fa fa-envelope mr-2  pb-3"
                            aria-hidden="true"
                          ></i>
                          info@lopamudracs.com
                        </a>
                      </li>

                      <li className="nav-item pb-3">
                        <a href="#" className="nav-link add">
                          <i
                            id="icon"
                            className="fa fa-map-marker mr-3  pb-3"
                            aria-hidden="true"
                          ></i>
                          Vibrant Residency,
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Madhapur,
                          Hyderabad-500081
                        </a>
                      </li>
                      <li className="nav-item pt-4">
                        <a
                          id="see"
                          href=""
                          className="mt-2 btn text-dark "
                          target="_blank"
                        >
                          VIEW MAP
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Contact;
