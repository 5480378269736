import React from "react";
import Footer from "../Navigation/footer";
import DSCImg2 from "../../assets/images/DSC-2.jpg";
import DSCImg1 from "../../assets/images/DSC-1.jpg";
import FAQImg1 from "../../assets/images/FAQ-1.png";
class DSC extends React.Component {
  /*  constructor(){
        super();this.stat
    } */
  render() {
    return (
      <div>
        <section id="page content" className=" p-3  bg-light">
          <div className="sidebar-right background-grey ">
            <div className="container py-3 ">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive"
                          src="https://images.unsplash.com/photo-1553484771-4e29a68540f4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDV8fGVudHJlcHJlbmV1cnxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=500&q=60"
                          alt="Lopamudra"
                        />
                      </div>

                      <div className="col-lg-7">
                        <h5 className="title">Class 3 Digital Signature</h5>
                        <hr />
                        <p className=" text-justify">
                          Proprietorship registration with gst registration and
                          msme registration along with business bank account
                          opening and LEDGERS accounting software for providing
                          estimates, invoices, tracking purchases, filing GST
                          returns and generating GST eWay bill. Inclusive of
                          government fee and taxesProprietorship registration
                          with GST registration, MSME registration along with
                          business bank account opening, online payment gateway
                          and LEDGERS accounting software for providing
                          estimates,
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 mb-2">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container  d-flex sidebar-right py-2">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50 ">
                <h1> Digital signature Certificate :</h1>
                <p className="text-justify">
                  A Digital Signature establishes the identity of the sender or
                  signee electronically while filling documents through the
                  internet. The Ministry of Corporate Affair’s (MCA) mandates
                  that the Directors sign some of the application documents
                  using their Digital Signature. Hence, a Digital Signature is
                  required for all Directors of a proposed Company.
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={DSCImg2}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="contetnt1" className="bg-light py-3">
          <div className="container d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid p-3 "
                  src={DSCImg1}
                  alt="Lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50  ">
                <h1>
                  Who requires Digital Signature Certificate under MCA21 :
                </h1>

                <p>
                  Under MCA21 all the authorized signatories of company and
                  professionals who sign the manual documents and returns filed
                  with ROC is required to obtain a Digital Signature Certificate
                  (DSC). Therefore following personnel have to procure Digital
                  Signature Certificate:
                </p>
                <div className="text-warning">
                  <ul>
                    <li>
                      {" "}
                      <strong>Directors</strong>
                    </li>
                    <br />
                    <li>
                      <strong>CA’s/Auditors</strong>
                    </li>
                    <br />
                    <li>
                      <strong>
                        Company Secretary – Whether in practice or in job.
                      </strong>
                    </li>
                    <br />
                    <li>
                      <strong>
                        {" "}
                        Bank Officials – for Registration and Satisfaction of
                        Charges
                      </strong>
                    </li>
                    <br />
                    <li>
                      <strong>
                        Other Authorized Signatories.Credentials required with
                        Application Form
                      </strong>
                    </li>
                    <br />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="documents" className="py-2">
          <div className="container  py-3">
            <div className="d-flex text-center container col-md-7 ">
              <h1 className=" secu text-dark pb-2">
                Documents Required for Digital signature
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow p-3">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3  ">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="cards">
          <div className="container text-justify pb-3 py-3">
            <h1 className="text-center py-3 secu">
              All about Digital signature Certificate
            </h1>
            <h5 className="text-light text-center">
              Points to make your decision easy
            </h5>
            <div className="row mt-4">
              <div className="col-md-6 mb-4">
                <div className="card  h-100  item1">
                  <div className="title text-center">
                    <h4 className="text-light"> Class III DSC</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      Class III Digital Signatures provide the highest level of
                      security and are used for E-Auctions and E-Tendering. All
                      government entities usually require Class III Digital
                      Signatures for the tendering process. This certificate
                      will be issued to individuals as well as organizations.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-4">
                <div className="card h-100  item2">
                  <div className="title text-center">
                    <h4 className="text-light">
                      <strong>Validity</strong>
                    </h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light">
                      Digital Signatures usually come with a validity of 1 or 2
                      years. Digital Signatures must be renewed before expiry.
                      DSC is issued in a pen drive like a token. A subscriber
                      should be well aware of its certificate validity to avoid
                      probable business loss due to expired certificate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="faqs">
            <div className="container py-3">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid">
                  <h1 className="pb-3 text-light secu">
                    FAQs on Digital Signature
                  </h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par">
                        <h4> Why should I need to have a digital signature?</h4>
                        <p className="text-light">
                          A digital signature (DSC) is used to sign the e-forms,
                          which is in PDF format, on behalf of you by us for
                          making application to register your company. A DSC is
                          mandate for the Directors of the company.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>What is Digital signature?</h4>
                        <p className="text-light">
                          Digital signature is an electronic signature that use
                          to sign and documents and apply and application
                          online. It”s provide identity how sign or apply
                          application. It's save cost and time, same as hand
                          written signature.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          How long will take to complete digital signature?
                        </h4>
                        <p className="text-light">
                          DSC issue in same day in 2 Hours.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Can Class 3 DSC be used for taxes?</h4>
                        <p className="text-light">
                          Class 3 DSC IS used for filing electronic documents
                          and electronic submissions of tenders and auctions,
                          e-bidding, e-auctioning, Patents Filing , logos Filing
                          and for Registrar of corporations E-filing, VAT return
                          e-filing, TDS return E-filing, Income tax E-filing,
                          sign a word or PDF and more.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Still, have Confusion ?</h4>
                        <p className="text-light">
                          Don’t worry!! we are here to help you .Get in touch
                          with our team to get all your queries resolved. Write
                          to us at info@lopamudracs.com or 9966534699
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default DSC;
