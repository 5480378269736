import React from "react";
class Getintouch extends React.Component {
  render() {
    return (
      <div>
        <section id="contact" className="section-7 form contact py-5 ">
          <div className="container py-5 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-8 pr-md-5 align-self-center text ">
                <div className="row intro">
                  <div className="col-12 p-0">
                    <span className="pre-title m-0">Send a message</span>
                    <h2>
                      Get in{" "}
                      <span className="featured">
                        <span className="bgd">&nbsp;Touch&nbsp;</span>
                      </span>
                    </h2>
                    <h6 className="pb-4">
                      We will respond to your message as soon as possible.
                    </h6>
                  </div>
                </div>
                <div className="in">
                  <div className=" col-md-12 p-0 ">
                    <form id="LPCS-simple-form" className="LPCS-simple-form">
                      <input type="hidden" name="section" value="LPCS_form" />

                      <div className="row ">
                        <div className="col-12 col-md-6 m-0 p-2 input-group">
                          <input
                            id="touch"
                            type="text"
                            name="name"
                            className="form-control field-name"
                            placeholder="Name"
                          />
                        </div>
                        <div className="col-12 col-md-6 m-0 p-2 input-group">
                          <input
                            id="touch"
                            type="email"
                            name="email"
                            className="form-control field-email"
                            placeholder="Email"
                          />
                        </div>
                        <div className="col-12 col-md-6 m-0 p-2 input-group">
                          <input
                            id="touch"
                            type="text"
                            name="phone"
                            className="form-control field-phone"
                            placeholder="Phone"
                          />
                        </div>
                        <div className="col-12 col-md-6 m-0 p-2 input-group">
                          <i className="icon-arrow-down mr-3"></i>
                          <select
                            id="touch"
                            name="info"
                            className="form-control field-info"
                          >
                            <option value="01" /*selected */ disabled>
                              More Info
                            </option>
                            <option>Audit & Assurance</option>
                            <option>Secretarial Services</option>
                            <option>Compliances</option>
                            <option>Legal and Advisory</option>
                            <option>Digital Signature</option>
                            <option>Tax-filing</option>
                            <option>Other</option>
                          </select>
                        </div>
                        <div className="col-12 m-0 p-2 input-group">
                          <textarea
                            id="getin"
                            name="message"
                            className="form-control field-message"
                            placeholder="Message"
                          ></textarea>
                        </div>
                        <div className="col-12 col-12 m-0 p-2 input-group">
                          <span className="form-alert"></span>
                        </div>
                        <div className="col-12 input-group m-0 p-2">
                          <a id="sees" className="btn ">
                            SEND
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="contacts">
                  <h4 id="A">Lopamudra Corporate Solutions.</h4>
                  <p>
                    <strong>YOUR COMPLIANCES ARE OUR RESPONSIBILITY.</strong>
                  </p>
                  <p className="title">professional services</p>
                  <ul className="navbar-nav">
                    <li className="nav-item mb-3">
                      <h5 id="address">
                        {" "}
                        <a href="#" className="nav-link add">
                          <i
                            id="icon"
                            className="fa fa-phone mr-3 "
                            aria-hidden="true"
                          ></i>
                          +91 7702397397
                        </a>{" "}
                      </h5>
                    </li>
                    <li className="nav-item mb-3">
                      <h5 id="address">
                        <a href="#" className="nav-link add">
                          <i
                            id="icon"
                            className="fa fa-envelope mr-3"
                            aria-hidden="true"
                          ></i>
                          lopamudra.cps@gmail.com
                        </a>
                      </h5>
                    </li>
                    <li className="nav-item mb-2">
                      <h5 id="address">
                        <a href="#" className="nav-link add">
                          <i
                            id="icon"
                            className="fa fa-map-marker mr-3"
                            aria-hidden="true"
                          ></i>
                          7-2-1813/5/A Opposite SBI
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bank,Street
                          no.1,Czech Colony
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;,Sanath
                          Nagar,HYD-18
                        </a>
                      </h5>
                    </li>
                    <li className="nav-item pt-3">
                      <a
                        id="see"
                        href="https://goo.gl/maps/N9g3f66Hz8Z4U65W7"
                        className="mt-2 btn text-dark "
                        target="_blank"
                      >
                        VIEW MAP
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Getintouch;
