import React from "react";
import Footer from "../Navigation/footer";
import AnnualImg from "../../assets/images/Annual.jpg";
import AnnualImg1 from "../../assets/images/Annual-1.jpg";
import INTImg from "../../assets/images/INT.jpg";
import FAQImg1 from "../../assets/images/FAQ-1.png";
class Annual extends React.Component {
  render() {
    return (
      <div>
        <section id="page content" className=" bg-light  p-3 ">
          <div className="sidebar-right background-grey ">
            <div className="container  pt-4  ">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row align-items-center">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive"
                          src="https://images.unsplash.com/photo-1553484771-4e29a68540f4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDV8fGVudHJlcHJlbmV1cnxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=500&q=60"
                          alt="lopamudra"
                        />
                      </div>

                      <div className="col-lg-7 ">
                        <h5 className="title">Annual returns </h5>
                        <hr />
                        <p className=" text-justify">
                          Lopamudra in India has a dedicated team of company
                          secretarial, legal professionals and Chartered
                          Accountants who possess extensive experience in
                          various corporate laws and commercial / legal
                          documentation. Our team is highly business oriented in
                          approach and has experience across sectors and the
                          acumen to provide complete solutions for all corporate
                          secretarial matters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 ">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container  d-flex sidebar-right ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50  ">
                <h1> Annual returns </h1>
                <p className="text-justify">
                  An annual return is a document that contains details of a
                  company's share capital, indebtedness, directors,
                  shareholders, changes in dictatorships, corporate governance
                  disclosures etc. ...The regulations of the Companies Act, 2013
                  specifies that every company must prepare and file annual
                  return with the registrar each financial year before the 29th
                  of November. In this article, we look at the information filed
                  by company in its annual return in detail
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={AnnualImg}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="contetnt1" className="bg-light">
          <div className=" container py-4 d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid  "
                  src={AnnualImg1}
                  alt="lopamudra "
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50   ">
                <h1>Authorized Signatories</h1>
                <p className="text-justify">
                  An annual return should have the authorized digital signature
                  of a director and the company secretary. In the absence of a
                  company secretary, a company secretary in practice can fulfill
                  this responsibility. If the particular firm is a one-person
                  company or small company, the annual return can be signed by a
                  director only.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-2 bg-light shadow pb-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className=" text-justify  max_wid ">
                  <div className="vl">
                    <h1>Annual Return Forms</h1>
                    <ol>
                      <li>
                        <p className="text-justify ">
                          Annual returns must be filed in Form MGT-7 within 60
                          days from the date of Annual General Meeting (AGM).
                        </p>
                      </li>
                      <li>
                        {" "}
                        <p className="text-justify">
                          Annual Returns of listed companies and companies
                          having a paid-up share capital of Rs 10 crores or more
                          must be certified by a company secretary in practice
                          in Form MGT 8.
                        </p>
                      </li>
                      <li>
                        <p>
                          Every foreign company must file their annual returns
                          in Form FC-4 within a period of 60 days from the last
                          day of its financial year.
                        </p>
                      </li>

                      <li>
                        <p>
                          All the companies must prepare the extract of Annual
                          Return in Form MGT-9.
                        </p>
                      </li>
                    </ol>
                    <h1>Details of Registration</h1>
                    <ul>
                      <li>
                        <p>Registration number of the company</p>{" "}
                      </li>
                      <li>
                        <p>
                          Foreign company registration number/ Global Location
                          Number (GLN)
                        </p>{" "}
                      </li>
                      <li>
                        <p>Corporate Identity Number (CIN)</p>{" "}
                      </li>
                      <li>
                        <p>Category of the company</p>{" "}
                      </li>
                      <li>
                        <p>Sub-category of the company</p>{" "}
                      </li>
                      <li>
                        <p>
                          Names of stock exchanges where the shares are listed
                        </p>{" "}
                      </li>
                      <li>
                        <p>
                          Whether or not the AGM was held in the particular
                          year. If it was held, the date or due date of AGM. If
                          it wasn’t held, the reason for not organizing the
                          same.
                        </p>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 ">
                <div className=" text-justify ">
                  <h1>Contents of Annual Return</h1>
                  <p>
                    A company’s annual return must contain the following
                    details:
                  </p>
                  <ol>
                    <li>
                      <p>Details of registration</p>
                    </li>
                    <li>
                      <p>Particulars of the company’s registered office</p>
                    </li>
                    <li>
                      <p>
                        Principal business activities pursued by the company
                      </p>
                    </li>
                    <li>
                      <p>
                        Particulars of Holding, Subsidiary and Associate
                        Companies
                      </p>
                    </li>
                    <li>
                      <p>
                        Particulars of the shares, debentures and other
                        securities of the company
                      </p>
                    </li>
                    <li>
                      <p>
                        Particulars of turnover and net worth of the company
                      </p>
                    </li>
                    <li>
                      <p>Details of shareholding pattern.</p>
                    </li>
                    <li>
                      <p>Indebtedness</p>
                    </li>
                    <li>
                      <p>
                        Details of members, debenture holders and other
                        securities holder
                      </p>
                    </li>
                    <li>
                      <p>
                        Details of shares/Debenture transfers of the particular
                        financial year
                      </p>
                    </li>
                    <li>
                      <p>Particulars of promoters</p>
                    </li>
                    <li>
                      <p>Particulars of directors</p>
                    </li>
                    <li>
                      <p>Particulars Key Managerial Personnel</p>
                    </li>
                    <li>
                      <p>
                        Details of meetings of members/class of
                        members/Board/Committees of the Board of Directors
                      </p>
                    </li>
                    <li>
                      <p>Remuneration of directors</p>
                    </li>
                    <li>
                      <p>Remuneration of Key Managerial Personnel</p>
                    </li>
                    <li>
                      <p>
                        Details on penalties/punishment/compounding of offences
                        on company, directors and other officers in default
                      </p>
                    </li>
                    <li>
                      <p>
                        Details of matters pertaining to certification of
                        compliances and disclosure
                      </p>
                    </li>
                    <li>
                      <p>
                        Details in respect of shares held by or on behalf of the
                        Foreign Institutional Investor (FII)
                      </p>
                    </li>
                    <li>
                      <p>Details of other pertinent disclosures</p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="background-grey">
          <div className="container py-5">
            <h3 className="text-center">
              <strong>Documents required for Annual Return filing</strong>
            </h3>
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12  col-12 w-50 py-5">
                <ul className="text-secondary">
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Form-16 </strong>
                  <br />
                  <i
                    className="fa fa-check mr-3  pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Salary Slips</strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>
                    Interest certificates from Post Offices &amp; Banks
                  </strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Form 16A/16B/16C</strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Form 26AS</strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Tax-Savings Investment Proofs</strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Deductions under Section 80D to 80U</strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Home Loan statement from NBFC/Bank</strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Capital Gains</strong>
                  <br />
                  <i
                    className="fa fa-check mr-3 pb-3 text-primary"
                    aria-hidden="true"
                  ></i>
                  <strong>Aadhar Card</strong>
                  <br />
                </ul>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={INTImg}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="faqs">
            <div className="container py-4">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid">
                  <h1 className="pb-3 text-light secu">
                    FAQs on Anual return filing
                  </h1>

                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par">
                        <h4>What is GSTIN?</h4>
                        <p>
                          All businesses that successfully register under GST
                          are assigned a unique Goods and Services Tax
                          Identification Number also known as GSTIN GST
                          Identification Number.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Who should register for GST online?</h4>
                        <p>
                          • Individuals registered under the Pre-GST law .e.,
                          Excise, VAT, Service Tax etc.
                          <br />
                          • Businesses with turnover above the threshold limit
                          of Rs. 40 Lakhs Rs. 10 Lakhs for North-Eastern States,
                          J&amp;K, Himachal Pradesh and Uttarakhand
                          <br />
                          • Casual taxable person / Non-Resident taxable person
                          <br />
                          • Every e-commerce aggregator
                          <br />• The person supplying online information and
                          database access or retrieval services from a place
                          outside India to a person in India, other than a
                          registered taxable person
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Is GST Registration Process online?</h4>
                        <p>
                          Yes, the process for registration of GST is completely
                          online and paperless. You don’t have to move out of
                          your home or office. We do all the paperwork and
                          submit an application online.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          What is Aggregate Turnover for GST Registration
                          purposes?
                        </h4>
                        <p>
                          The “Aggregate Turnover” means total turnover of a
                          person having the same PAN in respect of the
                          following:
                        </p>
                        <p>
                          i All taxable supplies including interstate supplies
                          <br />
                          Exempt supplies
                          <br />
                          Exports of goods and/or service
                        </p>
                        <p>
                          The above shall be computed on all India basis and
                          excludes taxes charged under the CGST Act, SGST Act
                          and the IGST Act.
                        </p>
                      </li>

                      <li className="col-lg-12 faq_par">
                        <h4>Still, have confusion?</h4>
                        <p>
                          Don’t worry!! Our expert will help you to choose a
                          best suitable plan for you. Get in touch with our team
                          to get all your queries resolved. Write to us at
                          info@lopamudracs.com or call us @+91 9966564399
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
export default Annual;
