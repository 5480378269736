import React from "react";
import Footer from "../Navigation/footer";
import comImg2 from "../../assets/images/com-2.jfif";
import comImg1 from "../../assets/images/com-1.jpg";
import comImg4 from "../../assets/images/com-4.jpeg";
import FAQImg1 from "../../assets/images/FAQ-1.png";

class PayrollServices extends React.Component {
  render() {
    return (
      <div>
        <section id="page content" className=" p-3  bg-light">
          <div className="sidebar-right background-grey pt-3">
            <div className="container  ">
              <div className="row ">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row align-items-center">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive "
                          src={comImg2}
                          alt="Payroll"
                        />
                      </div>

                      <div className="col-lg-7">
                        <span className="title ">Payroll Services</span>

                        <hr />
                        <p className=" text-justify">
                          All you need to know Our specialized Payroll service
                          includes managing expense and re-imbursement
                          management along with Payroll process, Disbursement of
                          Payroll and employee re-imbursements, Issuance of Pay
                          slips, Depositing Payroll related Taxes and Employee
                          Contributions to Pension or other funds, Filing of
                          relevant Forms and Returns and Compliances under
                          various Labour Law / Statutes, Salary structuring in
                          Tax efficient ways with clear workings to show
                          employee Take Home salary.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 ">
                  <p className="widget-title text-light ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container py-4 d-flex sidebar-right ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50 ">
                <h1 className="y">What is Payroll?</h1>
                <p className="text-justify">
                  Payroll is the business process of paying employees. Running
                  payroll consists of calculating employee earnings and
                  factoring out federal and state payroll taxes. The term
                  payroll can also refer to:{" "}
                </p>
                <ul>
                  <li>A business’s financial records of employees.</li>{" "}
                  <li> The distribution of employee paychecks. </li>
                  <li> Annual records of employee wages.</li>{" "}
                </ul>{" "}
                <p>
                  {" "}
                  Payroll can be a business’s greatest overhead expense. And the
                  payroll process is complicated. But understanding each
                  component of payroll may help you better understand your
                  business finances. And it can help ensure you remain compliant
                  with federal and state tax and labor laws.
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={comImg1}
                  alt="payroll 2"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="documents" className="py-3">
          <div className="container  py-3">
            <div className="d-flex container col-md-7">
              <h1 className=" mb-3 secu text-dark text-center pb-2">
                Documents Required for payroll Services
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow p-3">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3  shadow p-3">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="cards" className="cardssec">
          <div className="container  py-3">
            <div className="text-center">
              <h1 className="pb-2 text-light secu container col-md-4">
                Payroll Process
              </h1>
            </div>
            <div className="row pb-4">
              <div className=" col-lg-4  pb-3 ">
                <div className="card text-white item1 h-100">
                  {/*  <div className="card-header"><h4>01</h4></div> */}

                  <h5 className="card-title">Simplify Payroll Operations</h5>
                  <p className="card-text text-light ">
                    Helping you remain agile and a step ahead of your
                    competition
                  </p>
                </div>
              </div>
              <div className=" col-lg-4 pb-3">
                <div className="card text-white item2 h-100">
                  <h5 className="card-title">Ensuring Smooth Transitions</h5>
                  <p className="card-text text-light">
                    Seamless migration from customer processes to our operations
                    processes
                  </p>
                </div>
              </div>
              <div className=" col-lg-4 pb-3">
                <div className="card text-white item3 h-100">
                  <h5 className="card-title">Convenience & Flexibility</h5>
                  <p className="card-text text-light">
                    24*7 secure ‘myPayroll’ access for admin as well as
                    employees. Solutions to manage multi location payroll across
                    India as well as overseas
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4  pb-3 ">
                <div className="card text-white item4 h-100">
                  <h5 className="card-title"> Error-Free Payroll</h5>
                  <p className="card-text text-light">
                    Invested in a highly customizable & powerful back-end
                    payroll engine, to make sure that significant part of
                    payroll processing is automated
                  </p>
                </div>
              </div>
              <div className=" col-lg-4 pb-3">
                <div className="card text-white item5 h-100">
                  <h5 className="card-title">Minimize Risk</h5>
                  <p className="card-text text-light">
                    With Statutory Compliance worries taken care of, your
                    business no longer needs to worry about the risks involved
                    in not meeting compliance norms
                  </p>
                </div>
              </div>
              <div className=" col-lg-4 pb-3">
                <div className="card text-white item6  h-100">
                  <h5 className="card-title">Focus Core Competencies</h5>
                  <p className="card-text text-light">
                    With hassle-free payroll solutions, your management and HR
                    teams can focus on their core competencies and organization
                    growth
                  </p>
                </div>
              </div>
              {/* --------------------------------------
              <div className=" col-lg-4  item7 ">
                <div className="card bg-light mb-3">
                  <div className="card-header">Header</div>
                  <div className="card-body">
                    <h5 className="card-title">Light card title</h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4   item8 ">
                <div className="card text-white bg-dark mb-3">
                  <div className="card-header">Header</div>
                  <div className="card-body">
                    <h5 className="card-title">Dark card title</h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4   item9 ">
                <div className="card text-white bg-light mb-3">
                  <div className="card-header">Header</div>
                  <div className="card-body">
                    <h5 className="card-title">Dark card title</h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>---*/}
            </div>
          </div>
        </section>

        <section>
          <div className="faqs">
            <div className="container py-3">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid">
                  <h1 className="pb-3 text-light secu">
                    FAQs on Payroll Services
                  </h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par">
                        <h4>Why should I consider pay?</h4>
                        <p>
                          Some of the benefits from payroll outsourcing are:
                          1.Access to the experience of experts 2.No need to
                          hire a full-time employee 3.You no longer need staff
                          cover when one of your payroll employees is on sick
                          leave, holidays or in case of other absences
                          4.Confidentiality and security of your payroll data.
                          5.You reduce the time spent on routine and repetitive
                          tasks. Through outsourcing, your Payroll becomes easy
                          and you can enjoy the peace of mind from knowing that
                          the salaries are always paid correctly and in time.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          I have only a few employees. Am I too small to
                          outsource payroll?
                        </h4>
                        <p>
                          At Lopamudra corporate solutions, we deliver payroll
                          services for any size of company, from one to several
                          thousands of employees. Regardless of size, we can
                          create a payroll service especially for you, at a
                          competitive price. You pay only for what you need and
                          have the flexibility to adapt as your business grows.
                        </p>{" "}
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>What is cloud based payroll system?</h4>
                        <p>
                          We at Lopamudra use a variety of web-based software to
                          process client payroll. It is a model in which client
                          does not need to invest in costly hardware or
                          software. It is a simple process where system is
                          available on our server & you will pay on monthly /
                          quarterly or annual basis. It will suitable for small
                          & medium organization that do not want to spend huge
                          money on software & do not want to manage server.
                          What’s more, it is accessible through internet all
                          over the globe.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>What is desktop based payroll system?</h4>
                        <p>
                          This system is idle for a micro organization, where
                          limited employees are working. Data resides in our own
                          systems. You have to give us data in a simple
                          template. Software will automatically calculate the
                          payroll & generate the reports. Advantages are: Very
                          cheap, Easy to use, IT infrastructure not required at
                          your end.
                        </p>
                      </li>

                      <li className="col-lg-12 faq_par">
                        <h4>Still, have confusion?</h4>
                        <p>
                          Don’t worry!! Our expert will help you to choose a
                          best suitable plan for you. Get in touch with our team
                          to get all your queries resolved. Write to us at
                          info@Lopamudracs.com or call us @+91 770 239 7397 .
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default PayrollServices;
