import React from "react";
import { Link } from "react-router-dom";
class Services extends React.Component {
  render() {
    return (
      <div>
        <section id="services" className="py-5 services">
          <div className="container pt-5 py-5">
            <div className="row intro pb-5">
              <div className="col-12 col-md-9 align-self-center text-center text-md-left">
                <span className="pre-title m-auto ml-md-0">
                  Our business areas
                </span>
                <h2 id="excellence" className="text-light">
                  Excellence in{" "}
                  <span className="mblev">
                    <span className="bgc"> &nbsp;Services&nbsp;</span>
                  </span>
                </h2>
                <p className="text-content">
                  We are leaders in providing consultancy services with a set of
                  cutting-edge technologies and a team of experienced and
                  renowned professionals. These are some options that you can
                  hire.
                </p>
              </div>
              <div className="col-12 col-md-3 align-self-end ">
                <a id="see" href="#footer" className="btn  float-right">
                  SEE ALL
                </a>
              </div>
            </div>
            <div className="row">
              <div className=" col-md-4 mb-3">
                <div className="card text-white  mb-3">
                  <div className="card-symbol">
                    {" "}
                    <i className="fa fa-sitemap" aria-hidden="true"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Audit &amp; Assurance</h5>
                    <p className="text-content">
                      The audit is the process of evaluating the accounting
                      entries present in the financial statement of the company.
                    </p>
                    <Link className="card-user" to="/Auditcompliances">
                      <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className=" col-md-4 mb-3">
                <div className="card text-white  mb-3">
                  <div className="card-symbol">
                    <i className="fa fa-briefcase" aria-hidden="true"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Secretarial Services</h5>
                    <p className="text-content">
                      Filing and transcription services are among the most
                      common requirements for small businesses.
                    </p>
                    <Link className="card-user" to="/Secretarialservices">
                      <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className=" col-md-4 mb-3">
                <div className="card text-white mb-3">
                  <div className="card-symbol">
                    <i className="fa fa-bar-chart" aria-hidden="true"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Compliances</h5>
                    <p className="text-content">
                      The company said that it had always acted in compliance
                      with environmental laws.
                    </p>
                    <Link className="card-user" to="/HR">
                      <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className=" col-md-4 mb-3">
                <div className="card text-white  mb-3">
                  <div className="card-symbol">
                    <i className="fa fa-plane" aria-hidden="true"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">business investments</h5>
                    <p className="text-content">
                      Business investment refers to the commitment of funds to a
                      business either in an active capacity
                    </p>
                    <Link className="card-user" to="/Contract">
                      <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card text-white  mb-3">
                  <div className="card-symbol">
                    {" "}
                    <i className="fa fa-gavel" aria-hidden="true"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Legal and Advisory</h5>
                    <p className="text-content">
                      Legal counsel is an organization that offers advisory
                      services that deal with various matters.
                    </p>
                    <Link className="card-user" to="/Advisory">
                      <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card text-white mb-3">
                  <div className="card-symbol">
                    <i className="fa fa-square-o" aria-hidden="true"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Digital Signatures</h5>
                    <p className="text-content">
                      It is a mathematical technique used to validate the
                      authenticity and integrity of a message, digital document.
                    </p>
                    <Link className="card-user" to="/DSC">
                      <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Services;
