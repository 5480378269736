import React from "react";
import LLPImg1 from "../../assets/images/LLP_1.png";
import LLPImg2 from "../../assets/images/LLP-2.png";
import CheckImg from "../../assets/images/Check.png";
import FAQImg1 from "../../assets/images/FAQ-1.png";
import Footer from "../Navigation/footer";

class LLP extends React.Component {
  /*  constructor(){
        super();this.stat
    } */
  render() {
    return (
      <div>
        <section id="page content" className=" p-3  bg-light ">
          <div className="sidebar-right background-grey ">
            <div className="container pt-4 pb-4 ">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row align-items-center">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive"
                          src="https://images.unsplash.com/photo-1553484771-4e29a68540f4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDV8fGVudHJlcHJlbmV1cnxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=500&q=60"
                          alt="Lopamudra"
                        />
                      </div>

                      <div className="col-lg-7">
                        <h5 className="title">LLP Registration</h5>
                        <hr />
                        <p className=" text-justify">
                          Lopamudra in India has a dedicated team of company
                          secretarial, legal professionals and Chartered
                          Accountants who possess extensive experience in
                          various corporate laws and commercial / legal
                          documentation. Our team is highly business oriented in
                          approach and has experience across sectors and the
                          acumen to provide complete solutions for all corporate
                          secretarial matters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 ">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container py-2 d-flex sidebar-right ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50 ">
                <h1>What is Limited Liability Partnership?</h1>
                <p className="text-justify">
                  LLP full form: LLP stands for Limited Liability partnership.
                  LLP is a mix of a traditional partnership, and a company as
                  some of its features are similar to a traditional partnership
                  and some matches with a company.
                  <br />
                  Limited Liability Partnership (LLP) was introduced in India by
                  way of the Limited Liability Partnership Act, 2008. A limited
                  liability partnership (LLP) is a partnership in which some or
                  all partners (depending on the jurisdiction) have limited
                  liabilities. It therefore can exhibit elements of partnerships
                  and corporations. In an LLP, each partner is not responsible
                  or liable for another partner's misconduct or negligence.
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={LLPImg2}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="contetnt1" className="bg-light">
          <div className=" container py-4 d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid  p-3 mb-5 "
                  src={LLPImg1}
                  alt="Lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50  ">
                <h1>Benefits of Limited Liability Partnership?</h1>
                <ol>
                  <p className="text-justify">
                    <li>
                      It is more flexible to organize the internal structure of
                      LLP. Comparatively, it is complex to organize the internal
                      structure of a company.
                    </li>
                    <li>
                      There is no maximum limit for the number of partners in
                      LLP. In the private limited company, shareholders are
                      limited to the extent of 200 shareholders.
                    </li>
                    <li>
                      Raising and utilization of funds depends on the partners
                      will. Funds can be bought and utilized only as per the
                      norms listed under the Companies Act, 2013.
                    </li>
                    <li>
                      LLP is exempt from Dividend Distribution Tax (DDT). In
                      contrast, a company has to pay DDT on dividend
                      distribution.
                    </li>
                    <li>
                      Professionals like Chartered accountant , Cost Accountant
                      (CMA), Advocates , engineers, and doctors may prefer to
                      register as LLPs.
                    </li>
                    <li>
                      No requirement of compulsory audit: All the companies,
                      whether private or public, irrespective of their share
                      capital, are required to get their accounts audited. But
                      in case of LLP, there is no such mandatory requirement.
                    </li>{" "}
                  </p>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section id="documents" className="py-3">
          <div className="container  py-2">
            <div className="d-flex container col-md-7 text-center">
              <h1 className="secu mb-4 text-dark pb-2">
                Documents Required for Limited Liability Partnership
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow p-3">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3  shadow p-3">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="cards" className="cardssec py-2">
          <div className="container text-justify ">
            <h1 className="text-center py-3  secu container col-md-6">
              Compliance required for LLP
            </h1>
            <h5 className="text-light text-center py-3">
              All you should know about necessary compliance
            </h5>
            <div className="row mt-4">
              <div className=" col-md-4 pb-4">
                <div className="card  h-100  item1">
                  <div className="title text-center">
                    <h4 className="text-light"> GST Registration</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      Every LLP is required to get their GST registration. GST
                      Registration Process is 100% online and there is no
                      requirement of submission of physical documents to GST
                      Department. GST registration must be obtained within 30
                      days of business incorporation, otherwise, the LLP will
                      not be able to issue proper GST related invoices.
                    </p>
                  </div>
                </div>
              </div>

              <div className=" col-md-4 pb-4">
                <div className="card h-100  item2">
                  <div className="title text-center">
                    <h4 className="text-light">GST Return</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light">
                      Once a Limited Liability Partnership gets registered under
                      GST It becomes necessary to file GST returns for LLPs. As
                      filing of GST returns is mandatory for all the registered
                      Taxpayers including LLPs. GST returns can be filed
                      monthly, quarterly or yearly depending upon the types of
                      GST returns form you are filing.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-md-4 pb-4">
                <div className="card   item3 h-100 ">
                  <div className="title text-center ">
                    <h4 className="text-light">Accounting</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      Every LLP shall maintain and keep the accounting records
                      which sufficient to show and explain the transaction of an
                      LLP and which discloses with reasonable accuracy the
                      financial position of the LLP. The books of account are
                      required to be kept and maintained at the registered
                      office of the LLP for the period of 8 years.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-4 mb-4">
              <div className=" col-md-4 pb-4">
                <div className="card  item4 h-100 ">
                  <div id="title" className="title text-center">
                    <h4 className="text-light"> ROC annual filing</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light ">
                      LLPs are required to file ROC Form 8 before 30th October
                      every year. Form 8 contains details of the Statement of
                      Accounts and solvency. Also, LLPs are required to file ROC
                      Form 11 before 30th May every year. Form 11 contains
                      details of all the Designated Partners like whether there
                      are any changes in the management of the LLP.
                    </p>
                  </div>
                </div>
              </div>

              <div className=" col-md-4 pb-4">
                <div className="card  h-100 item5">
                  <div className="title text-center">
                    <h4 className="text-light"> Audit under IT ACT</h4>
                  </div>
                  <div className="body p-3 mb-4">
                    <p className="text-light">
                      Every Limited Liability Partnership whose turnover exceeds
                      INR 2 Cr. In case of a business or INR 50 Lakh in case of
                      a profession, is required to get its books of accounts tax
                      audited under section 44AB of the Income-tax Act. Such tax
                      audit under section 44AB will have to be completed and
                      filed by 30th September.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-md-4 pb-4">
                <div className="card  item6  h-100">
                  <div className="title text-center ">
                    <h4 className="text-light"> IT Returns</h4>
                  </div>
                  <div className="body p-3 ">
                    <p className="text-light">
                      Every Limited Liability Partnership has to file the Income
                      Tax Returns every year, irrespective of its transactions.
                      It must be filed by LLP on or before 31st July (if not
                      covered under audit) or 30th September (if covered under
                      audit). Income tax returns for Limited Liability
                      Partnership have to be filed under ITR 5.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className=" col-md-4 pb-4">
                <div className="card  h-100  item1">
                  <div className="title text-center">
                    <h4 className="text-light"> MAT Audit for LLPs</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      It is mandatory for the LLPs to get their accounts audited
                      under MAT i.e. Minimum Alternate Tax. A report in form 29b
                      is to be issued by a Practicing Chartered Accountant which
                      certifies that MAT has been computed according to Income
                      Tax Act, 1961. The book profit of a company is the
                      accounting profit arrived at after making the specified
                      additions and deductions.
                    </p>
                  </div>
                </div>
              </div>

              <div className=" col-md-4 pb-4">
                <div className="card h-100  item2">
                  <div className="title text-center">
                    <h4 className="text-light">TM Registration</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light">
                      For an LLPs, Trademarks are necessary not mandatory
                      though. The protection of the LLP’s name is limited to the
                      extent that another LLP will not be registered with the
                      same or a closely-resembling name. If you hold a unique
                      brand name it is always advised to get a trademark
                      registered as it is necessary to get protection under
                      different classes of Trademark.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-md-4 pb-4">
                <div className="card   item3 h-100 ">
                  <div className="title text-center ">
                    <h4 className="text-light">Form 3 CEB for LLP</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      For LLPs which have entered into any international
                      transactions with associated enterprises or have
                      undertaken specified Domestic Transactions, need to file
                      Form 3CEB. This form should be certified by a practicing
                      Chartered Accountant. Limited Liability Partnerships which
                      are required to file this Form can do their tax filing by
                      the 30th of November.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="faqs">
            <div className="container py-4">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid">
                  <h1 className="pb-3 secu">
                    FAQs on Limited Liability Partnership
                  </h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par">
                        <h4>Is audit compulsory for LLP?</h4>
                        <p className="text-light">
                          The accounts of every LLP shall be audited in
                          accordance with Rule 24 of LLP, Rules 2009. Such
                          rules, inter-alia, provides that any LLP, whose
                          turnover does not exceed, in any financial year, forty
                          lakh rupees, or whose contribution does not exceed
                          twenty five lakh rupees, is not required to get its
                          accounts audited.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Is LLP agreement mandatory for all LLPs??</h4>
                        <p className="text-light">
                          Yes, a LLP agreement is a mandatory document and
                          should be adopted within 30 days of its incorporation
                          and where the partners have not executed it, the first
                          schedule of the LLP Act will be applicable.work and
                          submit an application online.
                        </p>
                      </li>

                      <li className="col-lg-12 faq_par">
                        <h4>When should we file Form 4 of LLP??</h4>
                        <p className="text-light">
                          In such a case details of all the three changes can be
                          filed through the same Form 4 only if the Form is
                          filed on or before 1st May, as all the events fall
                          within 30 days. However, if the LLP files the Form 4
                          on 10th of May, then details in a separate Form would
                          be required to be filed in respect of Partner A."
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Still, have confusion?</h4>
                        <p className="text-light">
                          Don’t worry!! Our expert will help you to choose a
                          best suitable plan for you. Get in touch with our team
                          to get all your queries resolved. Write to us at
                          info@lopamudracs.com or call us @+91 9966534699.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default LLP;
