import React from "react";
import Getintouch from "../sliders/getintouch";
import Footer from "../Navigation/footer";
import ADVImg from "../../assets/images/ADV.jpg";
import ADVSImg from "../../assets/images/ADVS.png";

class Advisory extends React.Component {
  render() {
    return (
      <div>
        <section>
          <div className="container ">
            <div className="row">
              <div className="col-md-6 ">
                <h1 className="text-center advsry  text-justify">Advisory</h1>
                <p>
                  Our value-added offerings of various Advisory services
                  including Accounting, Financial, Transaction ,Corporate,
                  Advisory, Training and Management Consulting enable our
                  clients to run their business ingeniously and benifit from
                  astute insights generated.
                </p>
              </div>

              <div className="col-md-6">
                <img
                  src={ADVImg}
                  alt="lopamudra"
                  className="embed-responsive "
                ></img>
              </div>
            </div>{" "}
          </div>
        </section>
        <section className="section bg-gray py-5 ">
          <div className="container bg-light py-5">
            <div className="row justify-content-center pb-5 ">
              <div className="col-md-6 text-center">
                <i className=""></i>
                <h4 className="text-uppercase title py-3">Best Services</h4>
                <p className="sub-title">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit sed do
                  eiusmod tempor incididunt ut labore et dolore.
                </p>
              </div>
            </div>

            <div className="row m-5">
              <div className="col-md-4 ">
                <div className="">
                  <div className="services-icon">
                    {/*
<img src="https://www.flaticon.com/svg/static/icons/svg/1738/1738691.svg" className="advs pl-5" alt="Lopamudra"></img>*/}
                  </div>
                  <p className="services-main-title">Accounting advisory</p>
                  <p className="">
                    <i className="fa fa-star-o" aria-hidden="true"></i>IFRS
                    Accounting <br />
                    <i className="fa fa-star-o" aria-hidden="true"></i>System
                    study & design <br />
                    <i className="fa fa-star-o" aria-hidden="true"></i>
                    Supervising the Implementation of Accounting System
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="">
                  <div className="services-icon">
                    {/* <img src="https://www.flaticon.com/svg/static/icons/svg/1354/1354924.svg"className="advs" alt="Lopamudra"></img>*/}
                  </div>
                  <p className="services-main-title">Financial Advisory</p>
                  <p className="">
                    <i className="fa fa-star-o" aria-hidden="true"></i>Financial
                    Analysis
                    <br />
                    <i className="fa fa-star-o" aria-hidden="true"></i>IPO
                    Consultatiion
                  </p>
                </div>
              </div>

              <div className="col-md-4 ">
                <div className="">
                  <div className="">
                    {/*<i className="fa fa-file-text-o" aria-hidden="true"></i>*/}
                  </div>
                  <p className="services-main-title"> Transaction Advisory </p>
                  <p className="">
                    <i className="fa fa-star-o" aria-hidden="true"></i>We help
                    our clients to successfully complete transactions, by
                    helping them identify right potential
                    targets/investors,conducting due diligence,mapping out
                    transaction roadmap and executing M&A process
                  </p>
                </div>
              </div>
            </div>
            <div className="row m-5">
              <div className="col-md-4">
                <div className="">
                  <div className="services-icon">
                    <i className=""></i>
                  </div>
                  <p className="services-main-title ">Corporate Advisory</p>
                  <p className="">
                    <i className="fa fa-star-o" aria-hidden="true"></i>{" "}
                    Corporate Laws
                    <br />
                    <i className="fa fa-star-o" aria-hidden="true"></i>{" "}
                    Negotiable Instruments <br />
                    <i className="fa fa-star-o" aria-hidden="true"></i>
                    Commercial Laws <br />
                    <i className="fa fa-star-o" aria-hidden="true"></i> Banking
                    Laws
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="">
                  <div className="services-icon">
                    <i className=""></i>
                  </div>
                  <p className="services-main-title ">Training</p>
                  <p className="">
                    <i className="fa fa-star-o" aria-hidden="true"></i>{" "}
                    Explaining the concepts & Accounting Standards <br />
                    <i className="fa fa-star-o" aria-hidden="true"></i>Coaching
                    in successful implementation of accounting system <br />
                    <i className="fa fa-star-o" aria-hidden="true"></i>{" "}
                    Preparation of Training Module
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="">
                  <div className="services-icon">
                    <i className=""></i>
                  </div>
                  <p className="services-main-title">Management Consulting</p>
                  <p>
                    <i className="fa fa-star-o" aria-hidden="true"></i>Corporate
                    planing <br />
                    <i className="fa fa-star-o" aria-hidden="true"></i>{" "}
                    Bussiness Transformation <br />
                    <i className="fa fa-star-o" aria-hidden="true"></i>
                    Competition Analysis <br />
                    <i className="fa fa-star-o" aria-hidden="true"></i> Market
                    Study <br />
                    <i className="fa fa-star-o" aria-hidden="true"></i>
                    Feasibility study <br />
                    <i className="fa fa-star-o" aria-hidden="true"></i>Cost
                    Optimisation
                    <br />
                    <i className="fa fa-star-o" aria-hidden="true"></i>{" "}
                    Performance improvement
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container col-lg-8">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  src={ADVSImg}
                  alt="Lopamudra"
                  className="embed-responsive"
                ></img>
              </div>
              <div className=" col-lg-5 ">
                <p className="services-main-title  style-7">Other Services</p>
                <p className="pt-4">
                  <i
                    className="fa fa-arrow-circle-right"
                    aria-hidden="true"
                  ></i>
                  Central Excise
                  <br />
                  <i
                    className="fa fa-arrow-circle-right"
                    aria-hidden="true"
                  ></i>
                  Custom's
                  <br />
                  <i
                    className="fa fa-arrow-circle-right"
                    aria-hidden="true"
                  ></i>
                  Central Sales tax <br />
                  <i
                    className="fa fa-arrow-circle-right"
                    aria-hidden="true"
                  ></i>
                  Forigen Trade Policy (DGFT/JDGFT) <br />
                  <i
                    className="fa fa-arrow-circle-right"
                    aria-hidden="true"
                  ></i>
                  Promotional/Rewards/Incentive Schemes <br />
                  <i
                    className="fa fa-arrow-circle-right"
                    aria-hidden="true"
                  ></i>
                  Discounting Letter of Credit (Domestic and
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;International) Custom's
                  clearances
                  <br />
                  <i
                    className="fa fa-arrow-circle-right"
                    aria-hidden="true"
                  ></i>
                  Export Promotion
                  <br />
                  <i
                    className="fa fa-arrow-circle-right"
                    aria-hidden="true"
                  ></i>
                  IEZ/SEZ/EOU/STP/EHTP/BTP AND related &nbsp;&nbsp;&nbsp;&nbsp;
                  area
                  <br />
                </p>
              </div>
            </div>
          </div>
        </section>
        <Getintouch />
        <Footer />
      </div>
    );
  }
}
export default Advisory;
