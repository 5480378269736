import React from "react";
import FFImg1 from "../../assets/images/FF-1.png";
import Footer from "../Navigation/footer";

class firms extends React.Component {
  render() {
    return (
      <div>
        <section id="GST">
          <div className="jumbotron4 jumbotron-fluid">
            <div className="container">
              <h2 className="text-center pt-5">
                <span className="bgc text-light">
                  &nbsp;Firm,Socities & Trust&nbsp;{" "}
                </span>
                <span className="text-light"> Registrations</span>
              </h2>
              <p></p>
            </div>
          </div>
        </section>
        <section id="single" className="section single">
          <div className="container py-5">
            <div className="row ">
              <div className="col-12 col-lg-8 p-0 text-fluid ">
                <div className="row intro m-0 align-item-center">
                  <div className="col-12">
                    <span className="pre-title m-0">
                      Know what the law requires
                    </span>
                  </div>
                </div>
                <br />
                <div className="row text-justify">
                  <div className="col-12 align-self-center pl-4 pr-4">
                    <h2>Company Registration India :</h2>
                    <p>
                      Private Limited Company is the most popular type of
                      business entity in India. Over 20 lakhs companies have
                      been registered in India as of October 2020 and 12 lakhs
                      companies are classified as active. All companies
                      registered in India are governed by the MCA (Ministry of
                      Corporate Affairs) under the Companies Act, 2013. Each
                      year Lopamudra helps register thousands of companies
                      across India. The company registration process can be
                      completed online on Lopamudra in less than 10 days with
                      assistance from experienced incorporation experts.
                    </p>
                    <h2>Society Registration :</h2>
                    <p>
                      A society is an association of several individuals
                      combined using a mutual accord to deliberate, govern and
                      act cooperatively for some communal purpose. Societies are
                      usually registered for the advancement of charitable
                      activities like sports, music, culture, religion, art,
                      education, etc.
                    </p>
                    <p>
                      Society Registration, under, The Society Registration Act,
                      in India, lays down certain procedures for the sake of
                      society registration &amp; operation. This act was
                      implemented with the purpose of augmenting the legal
                      stipulations of society registration for the advancement
                      of literature, fine arts, science or distribution of
                      awareness for bountiful purposes. The society registration
                      act, 1860 has been accepted by several state governments
                      without or with further amendments.
                    </p>
                    <h2>Trust :</h2>
                    <p>
                      As per the Indian Trust Act 1882, a Trust is an
                      arrangement where the owner (trustor) transfers the
                      property to someone else (trustee) for the benefit of a
                      third person (beneficiary).
                    </p>
                    <p>
                      Such a property is transferred by the trustor to the
                      trustee along with a proclamation that the trustee should
                      hold the property for the beneficiaries of the Trust.
                    </p>
                  </div>
                </div>
              </div>

              <aside className="col-12 col-lg-4 pl-lg-8 pt-5   ">
                <div className="shadow p-3 mb-5">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2 " id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </aside>
            </div>
          </div>
        </section>

        <section className="bg-light py-5 Documents-section">
          <div className="container ">
            <h1 className="text-center ">Documents Required for F , S , T</h1>
            <div className="container text-center">
              <div className="row">
                <div id="flag" className="col-md-4  py-3 pl-5">
                  <h4 className="subheadingtitle  ">
                    &nbsp;Pvt. Ltd. /Public Ltd./OPC{" "}
                  </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      Company PAN card
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      MOA, AOA &amp; Incorporation Certificate
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Company Address Proof
                    </p>
                  </ul>
                </div>
                <div className="col-md-4 py-3  pl-5 ">
                  <h4 className="subheadingtitle ">
                    LLP &amp; Partnership Firm{" "}
                  </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Firm PAN card
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      LLP Agreement/ Partnership deed
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Firm Address Proof
                    </p>
                  </ul>
                </div>
                <div className="col-md-4  py-3 pl-5  ">
                  <h4 className="subheadingtitle">Proprietorship Firm </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Proprietor's PAN card
                    </p>
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      Shop Act / Ghumasta License
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Address proof of Firm
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cardssec">
          <div className="container  py-5">
            <div className="text-center">
              <h4 className=" secu container col-md-6">
                Read more about Firm,Socities & Trust registration
              </h4>
              <p className="text-light">
                The process of trademark begins with a trademark search. We can
                help you conduct a trademark search using the tool below to find
                marks across classes. The government trademark search tool can
                also be used government.
              </p>
            </div>
            <div className="row mt-3">
              <div className=" col-lg-4   pb-4">
                <div className="card text-white h-100 item1">
                  <h5 className="card-title text-center">Owning Assets</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      A company can acquire, own, and transfer any type of
                      tangible or intangible asset in India. A shareholder is
                      not eligible to claim the company’s property, as they are
                      not owners of the company. A shareholder merely has an
                      interest in the company arising under the articles of
                      association of the company, measuring a sum for liability.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    pb-4 ">
                <div className="card text-white  h-100 item2">
                  <h5 className="card-title text-center">Equity Raising</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      A company is the only type of legal entity which can help
                      the promoters raise equity funding from Angel Investors,
                      Private Equity Firms and the Stock Exchange. A private
                      limited company would suffice for raising equity funds
                      from Angel Investors and Private Equity Investors. In case
                      of listing or allotment of shares to more than 200
                      shareholders, a Limited Company would be required.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4   pb-4 ">
                <div className="card text-white  h-100  item3">
                  <h5 className="card-title text-center">
                    Selection of a Name
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      When selecting a name for society registration, it is
                      vital to understand that according to Society Act, 1860,
                      an identical or similar name of a currently registered
                      society will not be allowed. Moreover, the proposed name
                      shall not suggest for any patronage of state government or
                      government of India or fascinate the provisions of Emblem
                      &amp; Names Act,1950
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4  mt-3 ">
                <div className="card text-white  h-100  item4">
                  <h5 className="card-title text-center">
                    Memorandum of Association
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      The Memorandum of Association of the society along with
                      Rules &amp; Regulations of society must be signed by every
                      establishing member, witness by Gazetted Officer, Notary
                      Public, Chartered Accountant, Oath Commissioner, Advocate,
                      Magistrate first class or Chartered Accountant with their
                      official stamping and complete address.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    mt-3 ">
                <div className="card text-white  h-100 item5">
                  <h5 className="card-title text-center ">Public Trust</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      It is a trust whose beneficiaries include the public at
                      large. Further, a Public Trust can be further subdivided
                      into Public Charitable Trust and Public Religious Trust.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    mt-3">
                <div className="card text-white  h-100 item6 ">
                  <h5 className="card-title text-center">Private Trust</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      A private Trust is the one whose beneficiaries include
                      families or individuals. Further, a Private Trust can be
                      subdivided into: Private Trusts whose beneficiaries and
                      their requisite shares both can be determined The Private
                      Trusts whose both or either the beneficiaries and their
                      requisite shares cannot be determined.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* --------------accordion for Faqs------------*/}
        <section className="py-3 faqs">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <img
                  src={FFImg1}
                  alt="Lopamudra"
                  className="embed-responsive"
                ></img>
              </div>
              <div className="wrap col-lg-7 shadow">
                <div className="container demo text-justify">
                  <h1 className="pb-3 text-light secu">
                    FAQs on FIRM Registration
                  </h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div
                    className="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div className="panel panel-default mt-3">
                      <div className="panel-heading" role="tab" id="headingOne">
                        <h4 className="panel-title text-light">
                          <a
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            className="seccccc "
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            How many are required to start a proprietorship?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseOne"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingOne"
                      >
                        <div className="panel-body ">
                          Only one person is required to start a proprietorship
                          as it can have only one promoter.
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTwo">
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            How can I transfer my proprietorship ?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseTwo"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div className="panel-body">
                          A business operated under a proprietor cannot be
                          transferred to another person unlike a limited
                          liability partnership or a private limited company.
                          Only the assets in the proprietorship can be
                          transferred to another person through sale. Intangible
                          assets like government approvals, registrations, etc.,
                          cannot be transferred to another person.
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingThree"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            What is meant by a society or an association?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseThree"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingThree"
                      >
                        <div className="panel-body">
                          A Society / Association is an organized group of
                          people with the sole aim and objectives of
                          non-profitable service. Registration of Societies is
                          optional. Registration of such an association will
                          have legal recognition. It will be possible to run
                          society legally with discipline. Society can sue and
                          be sued.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingfour"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsefour"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Who will register a society/Association and where it
                            can be registered?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsefour"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingfour"
                      >
                        <div className="panel-body">
                          Registrar of Societies i.e., District Registrar of a
                          District appointed under Andhra Pradesh Societies
                          Registration Act, 2001 as applicable to the state of
                          Telangana is the authority to register the societies.
                          Society can be registered in the office of the
                          Registrar in which District the society is intended to
                          be established. Application may have to be made to the
                          Registrar through MeeSeva online.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingfive"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsefive"
                            aria-expanded="false"
                            aria-controls="collapsefive"
                          >
                            Is there any Trust Registration Form?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsefive"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingfive"
                      >
                        <div className="panel-body">
                          The registration of Trust or Trust Deed Registration
                          is a one and same thing and each state have prescribed
                          a distinct profess for trust registration. As such,
                          there is no uniform trust registration form to
                          register a trust. The basic documents to register a
                          trust is the trust deed and KYC documents of its
                          author, trustee and witness of the trust deed.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingsix">
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsesix"
                            aria-expanded="false"
                            aria-controls="collapsesix"
                          >
                            Can a private trust deed be amended?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsesix"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingsix"
                      >
                        <div className="panel-body">
                          A trust deed is a conventional report laying out the
                          gatherings associated with a trust and the guidelines
                          for conveying trust property to recipients. Just a
                          revocable trust can be revised once made. Changes can
                          be made distinctly during the trust grantor&#39;s
                          lifetime.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default firms;
