import React from "react";
import Footer from "../Navigation/footer";
import FAQImg1 from "../../assets/images/FAQ-1.png";
import TradeRRImg from "../../assets/images/TradeRR.jpg";
import TradeMImg from "../../assets/images/TradeM.png";
class Trademarkrenewal extends React.Component {
  render() {
    return (
      <div>
        <section id="page content" className=" p-3 mb-5 bg-light">
          <div className="sidebar-right background-grey ">
            <div className="container pt-4 ">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive"
                          src="https://images.unsplash.com/photo-1553484771-4e29a68540f4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDV8fGVudHJlcHJlbmV1cnxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=500&q=60"
                          alt="enterpreneur"
                        />
                      </div>

                      <div className="col-lg-7">
                        <h5 className="title">Trademark Renewal</h5>
                        <hr />
                        <p className=" text-justify">
                          Lopamudra in India has a dedicated team of company
                          secretarial, legal professionals and Chartered
                          Accountants who possess extensive experience in
                          various corporate laws and commercial / legal
                          documentation. Our team is highly business oriented in
                          approach and has experience across sectors and the
                          acumen to provide complete solutions for all corporate
                          secretarial matters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 mb-5">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container d-flex sidebar-right ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50 ">
                <h1>When the Trademark should be renewed?</h1>
                <p className="text-justify">
                  Trademark renewal has to be filed before expiry of the mark.
                  The trademark renewal application form must be prepared and
                  filed before a deadline to enjoy the seamless protection of
                  the trademark without any chances of litigation. Once renewed,
                  it has lifetime validity.
                </p>
                <p className="text-justify">
                  It is important for you to ensure that you apply for renewal
                  of trademarks beforehand i.e. before the expiry of 10 years.
                  There’s a practice of Trademark Ministry to send a notice to
                  the registered office regarding the expiry. However, you would
                  not wish to do so. If you have not filed before renewal due
                  date, a registrar may advertise its intention to remove the
                  trademark in a journal. In case you have not applied for
                  renewal before expiry, you may do so later on by paying fine.
                  This process is called restoration. However, it may be subject
                  to litigation.
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={TradeRRImg}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="contetnt1" className="bg-light">
          <div className=" container py-3 d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid  p-3 mb-5 "
                  src={TradeMImg}
                  alt="Lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50  ">
                <h1>Duration to renew a Trademark:</h1>

                <ul>
                  <p className="text-justify">
                    <li>
                      The renewal can be filed{" "}
                      <strong>
                        within one year prior to the date of expiry
                      </strong>{" "}
                      as per rules 57 and 58 in Trade Mark rules 2017.
                    </li>
                    <li>
                      The renewal can be filed{" "}
                      <strong>
                        within six months prior to the date of expiry
                      </strong>{" "}
                      as per rules 63 and 64 in Trade Mark rules 2002.
                    </li>
                    <li>
                      The renewal can also be filed{" "}
                      <strong>
                        within six months after the date of expiry
                      </strong>
                      .
                    </li>{" "}
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="documents" className="py-3">
          <div className="container  py-2">
            <div className="d-flex container col-md-7 text-center">
              <h1 className="secu mb-4 text-dark pb-2">
                Documents Required for Trademark Renewal
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow p-3">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3 shadow p-3 ">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cardssec">
          <div className="container  py-5">
            <div className="text-center container col-md-7">
              <h4 className=" secu">Advantages of Trademark renewal</h4>

              <h4 className="text-light pt-3 pb-4">
                <strong>Points to make your decision easy</strong>
              </h4>
            </div>
            <div className="row mt-3">
              <div className=" col-lg-4   pb-4">
                <div className="card text-white h-100 item1">
                  <h5 className="card-title text-center">Unique Identity</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      All are there in the name and brand. Your clients will
                      know you only by your brand. They will identify your
                      products and services with the brand attached to it.
                      Renewing your trademark ensures this. It automatically
                      gives your product or service a unique identity, different
                      from others.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    pb-4 ">
                <div className="card text-white  h-100 item2">
                  <h5 className="card-title text-center">Legal Protection</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Renewing a Trademark not only protects your brand but also
                      provides you with the tools to prevent someone using
                      similar signs. Renewal of a Trademark helps you to
                      maintain your own brand and stops others from using it. It
                      is a valid legal protection that you obtain by renewing.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4   pb-4 ">
                <div className="card text-white  h-100  item3">
                  <h5 className="card-title text-center">Creation of Asset</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      A well known brand or logo is an intellectual asset for
                      the owner. You can very well capitalize on the same if the
                      same is registered and renewed from time to time. There
                      are numerous instances whereby valid brands can easily
                      sell the franchise or lend the name for huge fees or
                      royalties.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="faqs">
            <div className="container py-5">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid ">
                  <h1 className="pb-3 secu text-light">
                    FAQs on Trademark Renewal
                  </h1>
                  <h4 className="pb-4">Get answer to all your quiries</h4>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par text-light">
                        <h4>. What is Trademark Renewal ?</h4>
                        <p className="text-light">
                          Trademark, once registered is valid for 10 years and
                          need to be renewed after every 10 years by paying fees
                          to Trademark Department. Renewal of trademark helps to
                          continue the brand usage for indefinite period.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          What is the Timeline & Fees for Trademark Renewal?
                        </h4>
                        <p className="text-light">
                          he Trademark renewal can be done anytime within 6
                          months of expiration of 10 years of trademark
                          registration.
                          <br /> Any further delay above 6 months is risky and
                          will attract extra fees and documentation. If the
                          renewal process is not completed within 12 months of
                          expiration of trademark, the trademark will be
                          abandoned i.e. removed from the trademark register.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          What is difference between Trademark Registration and
                          Trademark Renewal?
                        </h4>
                        <p className="text-light">
                          The trademark registration is different in
                          documentation, procedure, time required and fees from
                          renewal. Trademark renewal within 6 months of
                          completing ten years is simple and easy to handle.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Trademarkrenewal;
