import React from "react";
import Footer from "../Navigation/footer";
import FAQImg1 from "../../assets/images/FAQ-1.png";
import TRADEImg1 from "../../assets/images/TRADE-1.jpg";
import TRADEImg2 from "../../assets/images/TRADE-2.jpg";
class Trademark extends React.Component {
  render() {
    return (
      <div>
        <section id="page content" className=" p-3 mb-5 bg-light">
          <div className="sidebar-right background-grey ">
            <div className="container pt-4 pb-4 ">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive"
                          src="https://lawcirca.com/wp-content/uploads/2020/08/trademark-egistration.jpg"
                          alt="Lopamudra"
                        />
                      </div>

                      <div className="col-lg-7">
                        <h5 className="title">Trademark Registration</h5>
                        <hr />
                        <p className=" text-justify">
                          Lopamudra in India has a dedicated team of company
                          secretarial, legal professionals and Chartered
                          Accountants who possess extensive experience in
                          various corporate laws and commercial / legal
                          documentation. Our team is highly business oriented in
                          approach and has experience across sectors and the
                          acumen to provide complete solutions for all corporate
                          secretarial matters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 mb-5">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container  d-flex sidebar-right ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50 ">
                <h1>What is Trademark ?</h1>
                <p className="text-justify">
                  According to the USPTO, “a trademark is a word, phrase,
                  symbol, and/or design that identifies and distinguishes the
                  source of the goods of one party from those of others. A
                  service mark is a word, phrase, symbol, and/or design that
                  identifies and distinguishes the source of a service rather
                  than goods. Examples include brand names, slogans, and logos.
                  (The term “trademark” is often used in a general sense to
                  refer to both trademarks and service marks.)” Similar to
                  copyright, a person does not need not register a trademark or
                  service mark to receive protection rights, but there are
                  certain legal benefits to registering the mark with the USPTO.
                  There is rarely an overlap between trademark and copyright law
                  but it can happen — for instance, when a graphic illustration
                  is used as a logo the design may be protected both under
                  copyright and trademark.
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={TRADEImg1}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="contetnt1" className="bg-light">
          <div className=" container py-4 d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid  "
                  src={TRADEImg2}
                  alt="Lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50  ">
                <h1>Why Should i Register a Trademark ?</h1>
                <p className="text-justify">
                  If you don't register your trade mark, then someone else can.
                  This immediately puts your business and any product or service
                  development you are undertaking at risk. Securing a registered
                  trademark protects your brand, and provides you with the tools
                  to prevent someone using similar signs and riding off the back
                  of your business. If you do not protect your trademark by
                  registering it, then you may find you are legally prevented
                  from expanding your business. With your brand registered, you
                  are within your rights to take actions against parties who
                  have attempted to register conflicting trademarks, or are
                  operating with conflicting brands and damaging your business.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section></section>
        <section id="documents" className="py-3 ">
          <div className="container  py-2">
            <div className="d-flex container col-md-7">
              <h1 className="secu mb-4 text-dark text-center pb-2">
                Documents Required for Trademark Registartion
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow p-3">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3  shadow p-3">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cardssec">
          <div className="container  py-5">
            <div className="text-center container col-md-7">
              <h4 className=" secu">Trademark Registration process</h4>
              <p className="text-light">
                As your product or service becomes successful, the trademark
                itself starts to develop an intrinsic value. Business investors
                will assess whether you have taken the appropriate legal
                trademark protection to secure your brand.
              </p>
            </div>
            <div className="row mt-3">
              <div className=" col-lg-4   pb-4">
                <div className="card  h-100 item1">
                  <h5 className="card-title text-center">
                    Trademark application allotment
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      Once the trademark registration application is filed with
                      the trademark registrar, a trademark application allotment
                      number is provided within one or two working days. The
                      application can then be tracked online through the online
                      trademark search facility. On obtaining the allotment
                      number, the owner of the trademark can affix the TM symbol
                      next to the logo.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    pb-4 ">
                <div className="card text-white  h-100 item2">
                  <h5 className="card-title text-center">
                    Vienna codification{" "}
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      The Vienna codification established by the Vienna
                      agreement (1973) is an international classification of the
                      figurative elements of marks. Once the application is
                      filed, the trademark registrar will apply the Vienna
                      classification to the trademark based on the figurative
                      elements of marks. While this work is in progress, the
                      trademark application status usually reflects as "sent for
                      Vienna codification"
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4   pb-4 ">
                <div className="card text-white  h-100  item3">
                  <h5 className="card-title text-center">
                    Trademark Examination
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Once the Vienna codification is complete, the trademark
                      registration application will be allotted to an officer in
                      the trademark registrar office. The trademark officer
                      would then review the trademark application for
                      correctness and issue a trademark examination report. The
                      officer will either accept the application and allow for
                      journal publication or object the same.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4  mt-3 ">
                <div className="card text-white  h-100  item4">
                  <h5 className="card-title text-center">Objection</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      If the trademark registration application is objected by
                      the Trademark Officer, the trademark applicant has the
                      right to appear before the officer and address the
                      objections. If the officer is satisfied with the
                      justifications of the trademark applicant, the trademark
                      would be allowed for trademark journal publication. In
                      case the officer is not satisfied with the justifications,
                      the trademark applicant has the right to appeal the
                      decision of the Trademark officer before the Intellectual
                      Property Appellate Board.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    mt-3 ">
                <div className="card text-white  h-100 item5">
                  <h5 className="card-title text-center">
                    Journal Publication
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      If the trademark registration application is opposed by a
                      third-party, a hearing will be called for by the trademark
                      hearing officer. Both the applicant and the opposing party
                      have the chance to appear at the hearing and provide
                      justifications for registration or rejection of the
                      trademark application. Based on the hearings and the
                      evidence presented, the hearing officer will determine if
                      the application should be accepted or rejected. The
                      decision of the hearing officer can also be challenged by
                      escalating it to the Intellectual Property Appellate Board
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    mt-3">
                <div className="card text-white  h-100 item6 ">
                  <h5 className="card-title text-center">
                    Trademark Registration
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      If there are no objections or oppositions to the
                      application, the trademark manuscript and trademark
                      registration certificate will be prepared. Once the
                      trademark registration certificate is issued, the mark is
                      considered to be a registered trademark of the owner,
                      granting the owner exclusive use of the mark. The symbol
                      can now be placed next to the logo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="faqs">
            <div className="container py-5">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid ">
                  <h1 className="pb-3 secu text-light">
                    FAQs on Trademark Registration
                  </h1>
                  <h4 className="pb-4">Get answer to all your quiries</h4>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par text-light">
                        <h4>How long is a trademark registration valid?</h4>
                        <p className="text-light">
                          A trademark registration will be valid for 10 years
                          from the date of application. Before or at the end of
                          10 years, the trademark owner can easily renew their
                          trademark by filing for renewal.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>What is the ™ symbol?</h4>
                        <p className="text-light">
                          The ™ symbol can be put next to a business name or
                          brand or logo once a trademark application has been
                          filed with the Government. Putting a ™ symbol next to
                          a brand indicates to competitors that you have filed a
                          trademark application claiming ownership over the
                          trademark.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4> When can I use the ® mark?</h4>
                        <p className="text-light">
                          The ® mark can be used once the trademark application
                          is approved by the Controller General of Patents
                          Designs and Trademarks and trademark registration is
                          provided.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          How long does the trademark registration process take?
                        </h4>
                        <p className="text-light">
                          After filing the trademark application, registration
                          of the mark can happen between 6 - 12 months, subject
                          to Government processing timelines. If a trademark
                          application is objected or opposed, the registration
                          process can take longer.
                        </p>
                      </li>

                      <li className="col-lg-12 faq_par">
                        <h4>Still, have confusion?</h4>
                        <p className="text-light">
                          Don’t worry!! Our expert will help you to choose a
                          best suitable plan for you. Get in touch with our team
                          to get all your queries resolved. Write to us at
                          info@lopamudracs.com or call us @+91 9966534699.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Trademark;
