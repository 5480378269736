import React from "react";
import Footer from "../Navigation/footer";
import Services from "../sliders/Services";
import FAQImg1 from "../../assets/images/FAQ-1.png";
import SECImg from "../../assets/images/SEC.jpg";
import SECImg1 from "../../assets/images/SEC_1.jpg";
class SecretarialServices extends React.Component {
  render() {
    return (
      <div>
        <section id="page content" className=" p-3  bg-light">
          <div className="sidebar-right background-grey ">
            <div className="container pt-4  ">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row align-items-center">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive"
                          src="https://images.unsplash.com/photo-1553484771-4e29a68540f4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDV8fGVudHJlcHJlbmV1cnxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=500&q=60"
                          alt="lopamudra"
                        />
                      </div>

                      <div className="col-lg-7">
                        <h5 className="title">Secretarial Services</h5>
                        <hr />
                        <p className=" text-justify">
                          Lopamudra in India has a dedicated team of company
                          secretarial, legal professionals and Chartered
                          Accountants who possess extensive experience in
                          various corporate laws and commercial / legal
                          documentation. Our team is highly business oriented in
                          approach and has experience across sectors and the
                          acumen to provide complete solutions for all corporate
                          secretarial matters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 ">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container py-4 d-flex sidebar-right ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50 ">
                <h1>What is Secretarial Service?</h1>
                <p className="text-justify">
                  Secretarial services have been given a new perspective in the
                  corporate world. It just doesn’t comprise of filing and
                  corresponding but also the compliance of statutory and legal
                  issues which form the foundation of any company. They perform
                  the function of bridging the gap between the administrative
                  and geographical inadequacies in a formation of the company as
                  every state or a country have their own drafted plethora of
                  rules and regulations. Corporate secretarial service stands as
                  a supporting structure to the company’s compliance needs
                  regardless of nature of the company as the company secretary
                  is adept in handling all types of company.
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={SECImg}
                  alt="lopamudra"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="contetnt1" className="bg-light">
          <div className=" container py-4 d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid  p-3 mb-5 "
                  src={SECImg1}
                  alt="lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50  ">
                <h1>Under Companies Act, 1956 and 2013:</h1>

                <ul>
                  <p className="text-justify">
                    <li>
                      Registration of the New Company, Section 25 Company,
                      Limited Liability Partnerships (LLP), Change of Name,
                      Change of Objects, Change of status from Public Ltd to
                      Private Ltd and vice a versa
                    </li>
                    <li>
                      Conversion of a Partnership Firm/ Limited Liability
                      Partnerships (LLP) into a Company
                    </li>
                    <li>
                      Registration of Foreign Company (Branch Office / Liaison
                      Office / Project Office)
                    </li>
                    <li>
                      Assisting in making petitions to be filed with Company Law
                      Board, Regional Director, Registrar of Companies
                    </li>
                    <li>
                      Assistance in shifting of registered office from State to
                      another
                    </li>
                    <li>
                      Assisting in preparation of Minutes, Notices, Agenda &amp;
                      Resolutions for Board Meeting, Annual / Extra-Ordinary
                      General Meeting &amp; Committee Meetings
                    </li>
                    <li>
                      Assisting in preparation of various registers like
                      Register of Member, Register of Transfer, Register of
                      Charges, Register of Director, Register of related party
                      transaction, etc.
                    </li>
                    <li>
                      Liasioning with the office of Registrar of Companies,
                      Regional Director and Company Law Board for obtaining
                      various regulatory approvals
                    </li>
                    <li>
                      Arranging Secretarial Compliance Certificate for Public,
                      Private &amp; Listed Companies
                    </li>
                    <li>
                      Assistance in procedural compliance required toward
                      mergers, demergers, acquisition, amalgamations, take over,
                      slump sale etc.
                    </li>
                    <li>
                      Assisting in winding up/closure of companies in India
                    </li>
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="documents" className="py-3">
          <div className="container  py-3">
            <div className="d-flex container col-md-7 ">
              <h1 className=" mb-4 secu text-dark text-center pb-2">
                Documents Required for Secretarial Services
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow p-3">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3  shadow p-3">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="cards" className="cardssec py-3">
          <div className="container text-justify ">
            <h1 className="text-center py-3 secu container col-md-7 ">
              Know more about Secretarial lservices
            </h1>
            <p className="text-light text-center">
              Each proprietorship differs in terms of the functions, clientele
              and the mode of operations. Hence, a couple of the registrations
              mentioned below will be applicable.
            </p>
            <div className="row pt-3">
              <div className="col-md-4 mb-4">
                <div className="card  h-100  item1">
                  <div className="title text-center">
                    <h4 className="text-light"> MSME registration</h4>
                  </div>
                  <div className="body p-3 ">
                    <p className="text-light">
                      MSME or Udyog Aadhaar registration can be obtained in the
                      name of the business to establish that the sole
                      proprietorship is registered with the Ministry of Micro,
                      Small and Medium Enterprises. <br />
                      You can get yourself registered as Small and Medium
                      Enterprise (SME) under the MSME Act.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-4">
                <div className="card h-100 pb-3 item2">
                  <div className="title text-center">
                    <h4 className="text-light">TAN registration</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light">
                      The provisions of section 203A of the Income-tax Act
                      require all persons who deduct or collect tax at source to
                      apply for the allotment of a TAN.
                      <br />
                      TAN registration must be obtained for the proprietor from
                      the income tax department if the proprietor is making
                      salary payments wherein TDS deduction is required.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card   item3 h-100 ">
                  <div className="title text-center ">
                    <h4 className="text-light">GST registration</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      Sole Proprietorship Registration through GST Registration.
                      ... Every registered business has to compulsorily collect
                      the tax from the customers and file the GST returns.incase
                      If a sole proprietor has a turnover of less than Rs. 20
                      Lakhs, it is not mandatory for him to get registered and
                      collect GST.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-4 ">
              <div className="col-md-4 mb-4">
                <div className="card  item4 h-100 ">
                  <div id="title" className="title text-center">
                    <h4 className="text-light"> Import Export code</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light ">
                      The Importer -Exporter Code (IEC) is a key business
                      identification number which is mandatory for Exports or
                      Imports. No person shall make any import or export except
                      under an IEC Number granted by the DGFT. in case of a
                      proprietorship business undertaking export and/or import
                      of goods into India.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-4">
                <div className="card  h-100 item5">
                  <div className="title text-center">
                    <h4 className="text-light"> FSSAI registration</h4>
                  </div>
                  <div className="body p-3 mb-4">
                    <p className="text-light">
                      In case the proprietorship is involved in the selling of
                      food products or handling of food products,FSSAI
                      registration must be obtained from the Food safety
                      authority of india in the name of proprietor
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card  item6  h-100">
                  <div className="title text-center ">
                    <h4 className="text-light"> Current account</h4>
                  </div>
                  <div className="body p-3 ">
                    <p className="text-light">
                      A current account can be opened for a sole proprietorship
                      through Lopamudra Corporate Solutions from various banks
                      in India. We offers exclusive partnerships through which
                      zero-balance current accounts can be opened. It is
                      recommended that GST registration be obtained for the
                      same.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="faqs">
            <div className="container py-4">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid">
                  <h1 className="pb-3 secu">FAQs on Secretarial Services</h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par">
                        <h4>What is GSTIN?</h4>
                        <p>
                          All businesses that successfully register under GST
                          are assigned a unique Goods and Services Tax
                          Identification Number also known as GSTIN GST
                          Identification Number.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Who should register for GST online?</h4>
                        <p>
                          • Individuals registered under the Pre-GST law .e.,
                          Excise, VAT, Service Tax etc.
                          <br />
                          • Businesses with turnover above the threshold limit
                          of Rs. 40 Lakhs Rs. 10 Lakhs for North-Eastern States,
                          J&amp;K, Himachal Pradesh and Uttarakhand
                          <br />
                          • Casual taxable person / Non-Resident taxable person
                          <br />
                          • Agents of a supplier &amp; Input service distributor
                          <br />
                          • Those paying tax under the reverse charge mechanism
                          <br />
                          • A person who supplies via e-commerce aggregator
                          <br />
                          • Every e-commerce aggregator
                          <br />• The person supplying online information and
                          database access or retrieval services from a place
                          outside India to a person in India, other than a
                          registered taxable person
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Is GST Registration Process online?</h4>
                        <p>
                          Yes, the process for registration of GST is completely
                          online and paperless. You don’t have to move out of
                          your home or office. We do all the paperwork and
                          submit an application online.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          What is Aggregate Turnover for GST Registration
                          purposes?
                        </h4>
                        <p>
                          The “Aggregate Turnover” means total turnover of a
                          person having the same PAN in respect of the
                          following:
                        </p>
                        <p>
                          i All taxable supplies including interstate supplies
                          <br />
                          Exempt supplies
                          <br />
                          Exports of goods and/or service
                        </p>
                        <p>
                          The above shall be computed on all India basis and
                          excludes taxes charged under the CGST Act, SGST Act
                          and the IGST Act.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          I operate in more than one state. Do I have to take
                          multiple GST Registrations?
                        </h4>
                        <p>
                          Yes, in that case, GSTIN is required to be obtained
                          for each state separately. We have special prices for
                          multi-state registrations. You may specifically
                          mention this to your compliance manager to avail
                          benefits.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          Whether GST Registration is required even if a person
                          is having aggregate turnover lesser than threshold
                          limit?
                        </h4>
                        <p>
                          Yes, it is mandatory to obtain GSTIN in case of
                          certain categories of persons. The major categories
                          are given below:
                        </p>
                        <p>
                          {" "}
                          Persons making any Inter-State taxable supply of Goods
                          / Services
                          <br />
                          Persons who are required to pay tax under Reverse
                          Charge Mechanism or Persons who are required to deduct
                          tax under GST <br />
                          Persons who supply goods and/or services on behalf of
                          other registered taxable persons whether as an agent
                          or otherwise
                          <br />
                          Input service distributor
                          <br />
                          Casual taxable persons or Non-resident taxable persons
                        </p>
                      </li>

                      <li className="col-lg-12 faq_par">
                        <h4>How long does it take to get GSTIN?</h4>
                        <p>
                          Upon successful submission of application, you will
                          obtain GSTIN usually within 4-6 working days.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Is there any validity of GST Certificate?</h4>
                        <p>
                          Once GST certificate is granted, the registration is
                          valid until it is surrendered or canceled or
                          suspended. Only GST certificate issued to a
                          non-resident taxable person and a casual taxable
                          person has a validity period.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          Is it possible for Partners to get GST registration
                          for Partnership firm and Proprietorship firm that
                          comes under GST?
                        </h4>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Still, have confusion?</h4>
                        <p>
                          Don’t worry!! Our expert will help you to choose a
                          best suitable plan for you. Get in touch with our team
                          to get all your queries resolved. Write to us at
                          info@ebizfiling.com or call us @+91 9643 203 209.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default SecretarialServices;
