import React from "react";
import FFImg1 from "../../assets/images/FF-1.png";
import Footer from "../Navigation/footer";

class PF extends React.Component {
  render() {
    return (
      <div>
        <section id="GST">
          <div className="jumbotron2 jumbotron-fluid">
            <div className="container">
              <h2 className=" pb-5">
                <span className="bgc text-light">&nbsp;ESI & PF&nbsp; </span>
              </h2>
            </div>
          </div>
        </section>
        <section id="single" className="section single">
          <div className="container py-5">
            <div className="row">
              <div className="col-12 col-lg-8 p-0 text-fluid ">
                <div className="row intro m-0">
                  <div className="col-12">
                    <span className="pre-title m-0">
                      Know what the law requires
                    </span>
                    <div>
                      <h2>
                        <span className="reg">ESIC :</span>
                      </h2>
                      <p>
                        ESI stands for Employee State Insurance managed by the
                        Employee State Insurance Corporation which is an
                        autonomous body created by the law under the Ministry of
                        Labour and Employment, Government of India.
                      </p>
                      <p>
                        This scheme is started for Indian workers. The workers
                        are provided with a huge variety of medical, monetary
                        and other benefits from the employer. Any Company having
                        more than 10 employees (in some states it is 20
                        employees) who have a maximum salary of Rs. 15000/- has
                        to mandatorily register itself with the ESIC.
                      </p>
                      <h2>
                        <span className="reg">EPFO :</span>
                      </h2>
                      <p>
                        Employees Provident Fund (EPF) is a scheme controlled by
                        the Employees’ Provident Funds and Miscellaneous
                        Provisions Act, 1952. It is regulated under the umbrella
                        of Employees’ Provident Fund Organisation (EPFO). PF
                        registration is applicable for all establishment which
                        employs 20 or more persons, subject to certain
                        circumstances and exemptions even if they engage less
                        than 20 employees. Under EPF scheme, an employee has to
                        pay a certain contribution towards the scheme and an
                        equal contribution is paid by the employer. The employee
                        gets a total amount including self and employer’s
                        contribution with interest, on retirement or
                        resignation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <aside className="col-12 col-lg-4 pl-lg-8 pt-5   ">
                <div className="shadow p-3 mb-5">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2 " id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </aside>
            </div>
          </div>
        </section>
        <section className="bg-light py-5 Documents-section">
          <div className="container ">
            <h1 className="text-center ">
              Documents Required for ESI & PF Registrations
            </h1>
            <div className="container text-center">
              <div className="row">
                <div id="flag" className="col-md-4  py-3 pl-5">
                  <h4 className="subheadingtitle  ">
                    &nbsp;Pvt. Ltd. /Public Ltd./OPC{" "}
                  </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      Company PAN card
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      MOA, AOA &amp; Incorporation Certificate
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Company Address Proof
                    </p>
                  </ul>
                </div>
                <div className="col-md-4 py-3  pl-5 ">
                  <h4 className="subheadingtitle ">
                    LLP &amp; Partnership Firm{" "}
                  </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Firm PAN card
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      LLP Agreement/ Partnership deed
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Firm Address Proof
                    </p>
                  </ul>
                </div>
                <div className="col-md-4  py-3 pl-5  ">
                  <h4 className="subheadingtitle">Proprietorship Firm </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Proprietor's PAN card
                    </p>
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      Shop Act / Ghumasta License
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Address proof of Firm
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cardssec">
          <div className="container  py-5">
            <div className="text-center">
              <h4 className=" secu container col-md-6">
                Benifits of ESIC Registration
              </h4>
              <p className="text-light">
                The following are some of the major benefits for employees
                working in a ESI Registered entity.
              </p>
            </div>
            <div className="row mt-3">
              <div className=" col-lg-4   pb-4">
                <div className="card text-white h-100 item1">
                  <h5 className="card-title text-center">Medical Benefit</h5>{" "}
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      Full medical care is provided to all persons registered
                      under ESI and their family members – from the day the
                      person enters insurable employment. There is no ceiling on
                      expenditure on the treatment of an Insured Person or his
                      family member. Medical care is also provided to retired
                      and permanently disabled insured persons and their spouses
                      on payment of a token annual premium of Rs.120/-..
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    pb-4 ">
                <div className="card text-white  h-100 item2">
                  <h5 className="card-title text-center">Sickness Benefit</h5>{" "}
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Sickness benefit in the form of cash compensation at the
                      rate of 70% of wages is payable to insured workers during
                      the periods of certified sickness for a maximum of 91 days
                      in a year. In order to qualify for sickness benefit, the
                      insured worker is required to contribute for 78 days in a
                      contribution period of 6 months.Workers suffering from
                      malignant and long-term diseases can claim extended
                      sickness benefit for upto two years at an enhanced rate of
                      80% of wages.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4   pb-4 ">
                <div className="card text-white  h-100  item3">
                  <h5 className="card-title text-center">Maternity Benefit</h5>{" "}
                  <div className="card-body csaa">
                    <p className="text-light text-jleft">
                      Maternity benefit for confinement/pregnancy is provided
                      for three months, which is extendable by further one month
                      on medical advice at the rate of full wage subject to
                      contribution for 70 days in the preceding year.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4  mt-3 ">
                <div className="card text-white  h-100  item4">
                  <h5 className="card-title text-center">
                    Disablement Benefit
                  </h5>{" "}
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      From the day of entering insurable employment and
                      irrespective of having paid any contribution, 90% of wage
                      is payable so long as temporary disability continues.
                      Permanent disablement benefit is payable at the rate of
                      90% of wage in the form of monthly payment, in case of
                      permanent disablement based on the extent of loss of
                      earning capacity as certified by a Medical Board.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    mt-3 ">
                <div className="card text-white  h-100 item5">
                  <h5 className="card-title text-center text-center ">
                    Dependant Benefit
                  </h5>{" "}
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Dependant benefit is paid at the rate of 90% of wage in
                      the form of monthly payment to the dependants of a
                      deceased insured person, in cases death occurs due to
                      employment injury or occupational hazards.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    mt-3">
                <div className="card text-white  h-100 item6 ">
                  <h5 className="card-title text-center">
                    Unemployment Allowance
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      Under the Rajiv Gandhi Shramik Kalyan Yojana, unemployment
                      allowance is payable to an insured Person who become
                      unemployed after being insured three or more years, due to
                      closure of factory/establishment, retrenchment or
                      permanent invalidity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* --------------accordion for Faqs------------*/}
        <section className="py-3 faqs">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <img
                  className="embed-responsive"
                  src={FFImg1}
                  alt="Lopamudra"
                ></img>
              </div>
              <div className=" col-lg-7 shadow">
                <div className="container demo text-justify">
                  <h1 className="pb-3 text-light secu">
                    FAQs on PF and ESI Registration
                  </h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div
                    className="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div className="panel panel-default mt-3">
                      <div className="panel-heading" role="tab" id="headingOne">
                        <h4 className="panel-title text-light">
                          <a
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            className="seccccc "
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            What is the statutory rate of contribution to EPF
                            account?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseOne"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingOne"
                      >
                        <div className="panel-body ">
                          The statutory rate of contribution is 12% of basic
                          salary plus dearness allowance of Rs.15,000 per month.
                          In case an employee wishes to contribute on a higher
                          basic salary, the same is possible after taking a
                          permission from the provident fund authorities.
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTwo">
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Are there any restrictions on the withdrawal of EPF?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseTwo"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div className="panel-body">
                          An employee resigning from service (except in the case
                          of a retirement) can withdraw PF only after the expiry
                          of two months.
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingThree"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            How does the EPFO credit interest on the EPF
                            accounts?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseThree"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingThree"
                      >
                        <div className="panel-body">
                          The EPFO credits interest on the monthly running
                          balances in the EPF accounts. The rate of interest is
                          notified each year. For FY 2019-20, the rate notified
                          is 8.5%.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingfour"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsefour"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            What is ESI Scheme?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsefour"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingfour"
                      >
                        <div className="panel-body">
                          Employees’ State Insurance Scheme of India is a
                          multi-dimensional Social Security Scheme tailored to
                          provide Socio-economic protection to the 'employees'
                          in the organized sector against the events of
                          sickness, maternity, disablement and death due to
                          employment injury and to provide medical care to the
                          insured employees and their families.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingfive"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsefive"
                            aria-expanded="false"
                            aria-controls="collapsefive"
                          >
                            How does the ESI scheme help the employees?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsefive"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingfive"
                      >
                        <div className="panel-body">
                          The scheme provides full medical care to the employee
                          registered under the ESI Act, 1948 during the period
                          of his incapacity, restoration of his health and
                          working capacity. It provides financial assistance to
                          compensate the loss of his/ her wages during the
                          period of his abstention from work due to sickness,
                          maternity and employment injury. The scheme provides
                          medical care to his/her family members also.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingsix">
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsesix"
                            aria-expanded="false"
                            aria-controls="collapsesix"
                          >
                            Who administers the ESI Scheme?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsesix"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingsix"
                      >
                        <div className="panel-body">
                          The ESI Scheme is administered by a statutory
                          corporate body called the Employees' State Insurance
                          Corporation (ESIC), which has members representing
                          Employers, Employees, the Central Government, State
                          Government, Medical Profession and the Hon’ble Members
                          of Parliament. Director General is the Chief Executive
                          Officer of the Corporation and is also an ex-officio
                          member of the Corporation.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>{" "}
        <Footer />
      </div>
    );
  }
}
export default PF;
