import React from "react";
import TESTImg1 from "../../assets/images/TEST-1.jpeg";
import TESTImg2 from "../../assets/images/TEST-2.jpeg";
class Testimonial extends React.Component {
  render() {
    return (
      <div>
        <section className="testimonial text-center py-5">
          <div className="container text-center">
            <div className=" white-heading text-center">
              <h5 className="pre-title text-light">What People Say</h5>
              <h2>
                Client <span className="bgc">&nbsp;Testimonial&nbsp;</span>
              </h2>
            </div>
            <div
              id="testimonial4"
              className="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x"
              data-ride="carousel"
              data-pause="hover"
              data-interval="5000"
              data-duration="2000"
            >
              <div className="carousel-inner" role="listbox">
                <div className="carousel-item active">
                  <div className="testimonial4_slide">
                    <img
                      src={TESTImg1}
                      alt="Lopamudra"
                      className="img-circle img-responsive"
                    />
                    <p>
                      I just wanted to share a quick note and let you know that
                      you guys do a really good job.Great experiance ever. You
                      guys are the best! Keep up the great work!
                    </p>
                    <h4>" Dr.karthik "</h4>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="testimonial4_slide">
                    <img
                      src="https://i.ibb.co/8x9xK4H/team.jpg"
                      alt="Lopamudra"
                      className="img-circle img-responsive"
                    />
                    <p>
                      Lopamudra has a solid technology team and ontime
                      delivery.. Very happy to be associated with them
                    </p>
                    <h4>"MallaReddy.G"</h4>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="testimonial4_slide">
                    <img
                      src={TESTImg2}
                      alt="Lopamudra"
                      className="img-circle img-responsive"
                    />
                    <p>
                      Great work!!! The team was solid, efficient and
                      knowledgeable. They did an amazing job on my very
                      challenging Project. I will be using them again. Thank you
                      for doing such a great job!
                    </p>
                    <h4>"Amar Nadh"</h4>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="testimonial4_slide">
                    <img
                      src="https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzJ8fHByb2ZpbGUlMjBwaWN0dXJlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                      alt="Lopamudra"
                      className="img-circle img-responsive"
                    />
                    <p>
                      Lopamudra provides a high standard of service. They are
                      quick to address questions and concerns and produce
                      quality work. We look forward to working with them in the
                      future.s
                    </p>
                    <h4>"Induja Seshadri"</h4>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="testimonial4_slide">
                    <img
                      src="https://images.unsplash.com/photo-1474176857210-7287d38d27c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fHByb2Zlc3Npb25hbCUyMHByb2ZpbGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                      alt="Lopamudra"
                      className="img-circle img-responsive"
                    />
                    <p>
                      It has been an easy ride being associated with Lopamudra.
                      They provide wide range of solutions for all spheres of
                      our business. and the Lopamudra team is readily available
                      24*7 for support.
                    </p>
                    <h4>"Sai Charan"</h4>
                  </div>
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#testimonial4"
                data-slide="prev"
              >
                <span className="carousel-control-prev-icon"></span>
              </a>
              <a
                className="carousel-control-next"
                href="#testimonial4"
                data-slide="next"
              >
                <span className="carousel-control-next-icon"></span>
              </a>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Testimonial;
