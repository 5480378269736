import React from "react";
import { NavLink, Link } from "react-router-dom";
import bgImg7 from "../../assets/images/bg-7.jpg";
import LOGOImg1 from "../../assets/images/LOGO-1.png";
class Footer extends React.Component {
  render() {
    return (
      <div>
        <section
          id="footer"
          className="footer text-light py-5 fixed-bg "
          style={{
            backgroundImage: "url(" + bgImg7 + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            alt: "lopamudra",
          }}
        >
          <footer className="page-footer font-small stylish-color-dark pt-4 ">
            <div className="container text-center text-md-left">
              <div className="row">
                <div className="col-lg-3 mx-auto text-left ">
                  <img
                    className="logo-image embed-responsive"
                    src={LOGOImg1}
                    alt="lopamudra"
                  />
                  <p className="text-light">
                    <i className="fa fa-home mr-3" aria-hidden="true"></i>{" "}
                    Lopamudra Corporate solutions
                  </p>
                  <p className="text-light">
                    <i className="fa fa-envelope-o mr-3" aria-hidden="true"></i>{" "}
                    info@lopamudracs.com
                  </p>
                  <p className="text-light">
                    <i className="fa fa-mobile mr-3" aria-hidden="true"></i>{" "}
                    +91-7702397397{" "}
                  </p>
                  <p className="text-light">
                    <i className="fa fa-phone mr-2" aria-hidden="true"></i>{" "}
                    +91-9966534699
                  </p>
                  <button id="sees" type="submit" className="btn  mb-4">
                    GET IN TOUCH
                  </button>
                </div>

                <hr className="clearfix w-100 d-md-none" />
                <div className="col-lg-9">
                  <div className="row ">
                    <div className="col-12 col-lg-4 mx-auto item  ">
                      <div className="card pb-3 text-left pl-4">
                        <h5 className="font-weight-bold text-uppercase mt-3 mb-4 ">
                          About
                        </h5>

                        <ul className="list-unstyled">
                          <li>
                            <i
                              className="fa fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <a href="#results">results</a>
                          </li>
                          <li>
                            <i
                              className="fa fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <a href="#services">Services</a>
                          </li>
                          <li>
                            <i
                              className="fa fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <a href="#team">Our Team</a>
                          </li>
                          <li>
                            <i
                              className="fa fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <a href="#vision">Vision</a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <hr className="clearfix w-100 d-md-none" />

                    <div className="col-lg-4 mx-auto item">
                      <div className="card pb-3 text-left pl-4">
                        <h5 className="font-weight-bold text-uppercase mt-3 mb-4 ">
                          Services
                        </h5>

                        <ul className="list-unstyled ">
                          <li>
                            <i
                              className="fa fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <Link to="/DSC">Digital Signature</Link>
                          </li>
                          <li>
                            <i
                              className="fa fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <Link to="/Secretarialservices">
                              Secretarial services
                            </Link>
                          </li>
                          <li>
                            <i
                              className="fa fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <Link to="/ESI">ESIC and EPF Compliances</Link>
                          </li>
                          <li>
                            <i
                              className="fa fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <Link to="/PayrollServices">Payroll services</Link>
                          </li>
                          <li>
                            <i
                              className="fa fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <Link to="/HR">HR Compliances</Link>
                          </li>
                          <li>
                            <i
                              className="fa fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <Link to="/Auditcompliances">
                              Audit Compliances
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <hr className="clearfix w-100 d-md-none" />

                    <div className="col-lg-4 item">
                      <div className="card pb-3 text-left pl-4">
                        <h5 className="font-weight-bold text-uppercase mt-3 mb-4">
                          USEFULL LINKS
                        </h5>

                        <ul className="list-unstyled">
                          <li>
                            <i
                              className="fa fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <a
                              href="https://www.incometaxindiaefiling.gov.in/home"
                              target="_blank"
                            >
                              PAN Search
                            </a>
                          </li>
                          <li>
                            <i
                              className="fa fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <a
                              href="https://www.incometaxindiaefiling.gov.in/home"
                              target="_blank"
                            >
                              TAN Search
                            </a>
                          </li>
                          <li>
                            <i
                              className="fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <a
                              href="https://www1.oanda.com/currency/converter/"
                              style={{
                                textDecoration: "none",
                              }}
                              target="_blank"
                            >
                              Currency Converter
                            </a>
                          </li>
                          <li>
                            <i
                              className="fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <a
                              href="https://www.incometaxindia.gov.in/Pages/default.aspx"
                              target="_blank"
                            >
                              Online Payment of Taxes
                            </a>
                          </li>

                          <li>
                            <i
                              className="fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <a href="https://www.tin-nsdl.com/" target="_blank">
                              Challan Status Enquiry
                            </a>
                          </li>
                          <li>
                            <i
                              className="fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <a
                              href="https://www.incometaxindiaefiling.gov.in/home"
                              target="_blank"
                            >
                              e-filing
                            </a>
                          </li>
                          <li>
                            <i
                              className="fa fa-angle-right mr-2"
                              aria-hidden="true"
                            ></i>
                            <a
                              href="http://www.mca.gov.in/mcafoportal/login.do"
                              target="_blank"
                            >
                              MCA21(ROC)
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <ul className="list-unstyled list-inline text-center py-2">
              <li className="list-inline-item">
                <h5 className="mb-1">Register </h5>
              </li>
              <li className="list-inline-item">
                <a href="#!" className="btn  btn-rounded " id="sees">
                  Sign up!
                </a>
              </li>
            </ul>

            <hr />

            <ul className="list-unstyled list-inline text-center social">
              <li className="list-inline-item">
                <a
                  id="icon"
                  href="https://www.facebook.com/Lopamudracs"
                  className="fa fa-facebook text-light bg-primary"
                  target="_blank"
                ></a>
              </li>

              {/* <li className="list-inline-item">
                <a
                  id="icon"
                  href="#"
                  className="fa fa-google text-light bg-danger"
                  target="_blank"
                ></a>
              </li> */}
              <li className="list-inline-item">
                <a
                  id="icon"
                  href="https://www.linkedin.com/company/lopamudra-corporate-solutions/?viewAsMember=true"
                  className="fa fa-linkedin text-light bg-primary "
                  target="_blank"
                ></a>
              </li>
              <li className="list-inline-item">
                <a
                  id="icon"
                  href="https://www.instagram.com/lopamudra.cps/"
                  className="fa fa-instagram text-light bg-danger"
                  target="_blank"
                ></a>
              </li>
              <li className="list-inline-item">
                <a
                  id="icon"
                  href="https://twitter.com/Lopamudracorpo3"
                  className="fa fa-twitter  text-light bg-primary"
                  target="_blank"
                ></a>
              </li>
            </ul>

            <div className="footer-copyright text-center py-3 text-light">
              Copyright © 2020 :
              <a href="https://lopamudracs.com/" className="text-danger">
                {" "}
                Lopamudra Corporate solutions
              </a>
            </div>
          </footer>
        </section>
      </div>
    );
  }
}
export default Footer;
