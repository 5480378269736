import React from "react";
class Vision extends React.Component {
  render() {
    return (
      <div>
        <section id="vision" className="py-5">
          <div className="container">
            <div
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="300"
              data-aos-duration="300"
            >
              <div className="row">
                <div className="col-md-12">
                  <h2 className="text-center ">
                    Our Vision &amp;{" "}
                    <span className="bgd text-dark">&nbsp;Mission&nbsp; </span>
                  </h2>
                  <p className="para text-center text-light">
                    We believe in “customer obsession”. A continuous focus and
                    the endless waves of energy towards the customer needs are
                    making us endorse. We want to empower ourselves through the
                    empowerment of our customers.
                  </p>
                  <p className="para text-center text-light">
                    “Reinventing Corporate Compliance & Governance” ,“Making
                    compliance easy”. Yes it is!,“Leading enables of incredible
                    compliance”
                  </p>
                  <p className="para text-center text-light">
                    “We are on a mission to make your compliance as easy as
                    humanly possible to give hassle free experience with our
                    impeccable services and tireless dedication”
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Vision;
