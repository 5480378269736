import React from "react";
class Process extends React.Component {
  render() {
    // var a = ['red','blue']
    // var b = ['red','blue','green','yellow']
    // console.log(a,b)
    // console.log(a.length-1)
    return (
      <div>
        <section className="process py-3 bg-light">
          <div className="container col-md-8">
            <div className="text-center py-4 ">
              <h2>
                How We<span className="bgd"> Work&nbsp;</span>
              </h2>
              <p className="text-center">
                We focus on both local and global markets as we offer
                Consultation and Audit Services in the labour legislative part
                of compliance- an integral component of corporate governance.
              </p>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="1500"
                >
                  <div className="main-timeline">
                    <div className="timeline">
                      <a href="#" className="timeline-content">
                        <div className="timeline-icon">
                          <i className="fa fa-globe"></i>
                        </div>
                        <h3 className="title">
                          UNDERSTAND THE REQUIREMENT FROM SCRATCH
                        </h3>
                        <p className="description"></p>
                      </a>
                    </div>

                    <div className="timeline">
                      <a href="#" className="timeline-content">
                        <div className="timeline-icon">
                          <i className="fa fa-pencil"></i>
                        </div>
                        <h3 className="title">
                          wORK ON THE REQUIREMENT AND MAKE A FRAME WORK
                        </h3>
                        <p className="description"></p>
                      </a>
                    </div>
                    <div className="timeline">
                      <a href="#" className="timeline-content">
                        <div className="timeline-icon">
                          <i className="fa fa-spinner"></i>
                        </div>
                        <h3 className="title">
                          UPDATING THE CLIENT ABOUT EACH AND EVERY SINGLE STEP{" "}
                        </h3>
                        <p className="description"></p>
                      </a>
                    </div>
                    <div className="timeline">
                      <a href="#" className="timeline-content">
                        <div className="timeline-icon">
                          <i className="fa fa-file-text-o"></i>
                        </div>
                        <h3 className="title">
                          DELIVERING THE RESULTS WITH THE TIMELINE
                        </h3>
                        <p className="description"></p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Process;
