import React from "react";
import Footer from "../Navigation/footer";
import FFImg1 from "../../assets/images/FF-1.png";
class DMHO extends React.Component {
  render() {
    return (
      <div>
        <section id="GST">
          <div className="jumbotron10 jumbotron-fluid">
            <div className="container">
              <h2 className=" py-5 text-center">
                <span className="bgc text-light">&nbsp;DMHO&nbsp;</span>
                License
              </h2>
            </div>
          </div>
        </section>
        <section id="single" className="section single">
          <div className="container py-5">
            <div className="row">
              <div className="col-12 col-lg-8 p-0 text-fluid ">
                <div className="row intro m-0">
                  <div className="col-12">
                    <span className="pre-title m-0">
                      Know what the law requires
                    </span>

                    <h2>
                      <span> DMHO License </span>
                    </h2>
                    <p>
                      To establish any business in India, getting the required
                      mandatory licences and permissions is considered the
                      toughest work. And these are more important and stringent
                      in the health sector as it deals with the life and death
                      of patients. These days, most of the hospitals employ
                      Hospital Consultants or Hospital Administrators to get
                      this work done. But in the Indian system which is famous
                      for its Red Tape Bureaucracy, it is important for the
                      hospital management to know all the applicable acts and
                      rules pertaining to hospitals and the process for getting
                      the same. There are also many approvals which need to be
                      obtained from the local civic body which are mandatory for
                      any commercial establishment. This article studies the
                      various acts, licences and approvals that are required to
                      establish an inpatient healthcare center in Hyderabad,
                      Telangana
                    </p>
                    <p>
                      Praesent fringilla quis massa et placerat. Mauris eu dui
                      eget urna pellentesque gravida vitae quis nibh. Ut at
                      augue tortor. Pellentesque quis suscipit magna.
                    </p>
                    <p>
                      {" "}
                      vel, dapibus hendrerit mauris curabitur dictum pharetra.
                    </p>

                    <p>
                      Etiam mollis sem sed bibendum blandit. Aenean quis luctus
                      ligula, vitae suscipit dui. Nunc diam orci, tincidunt eget
                      consectetur sit amet, vulputate.
                    </p>
                  </div>
                </div>
              </div>

              <aside className="col-12 col-lg-4 pl-lg-8 pt-5   ">
                <div className=" shadow p-3 mb-5">
                  <p class="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </aside>
            </div>
          </div>
        </section>

        <section className="bg-light py-5 Documents-section">
          <div className="container ">
            <h1 className="text-center ">Documents Required for DMHO</h1>
            <div className="container text-center">
              <div className="row">
                <div id="flag" className="col-md-4  py-3 pl-5">
                  <h4 className="subheadingtitle  ">
                    &nbsp;Pvt. Ltd. /Public Ltd./OPC{" "}
                  </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      Company PAN card
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      MOA, AOA &amp; Incorporation Certificate
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Company Address Proof
                    </p>
                  </ul>
                </div>
                <div className="col-md-4 py-3  pl-5 ">
                  <h4 className="subheadingtitle ">
                    LLP &amp; Partnership Firm{" "}
                  </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Firm PAN card
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      LLP Agreement/ Partnership deed
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Firm Address Proof
                    </p>
                  </ul>
                </div>
                <div className="col-md-4  py-3 pl-5  ">
                  <h4 className="subheadingtitle">Proprietorship Firm </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Proprietor's PAN card
                    </p>
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      Shop Act / Ghumasta License
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Address proof of Firm
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cardssec">
          <div className="container  py-5">
            <div className=" container col-md-7 text-center">
              <h4 className=" secu">Read more about DMHO License</h4>
              <p className="text-light">
                The process of trademark begins with a trademark search. We can
                help you conduct a trademark search using the tool below to find
                marks across classes. The government trademark search tool can
                also be used to find identical or similar trademarks under the
                same class. Once the search is complete, you will proceed with
                the trademark application based on the details of identical or
                similar trademark applications already filed with the
                government.
              </p>
            </div>
            <div className="row mt-3">
              <div className=" col-lg-4   pb-4">
                <div className="card text-white h-100 item1">
                  <h5 className="card-title text-center">
                    Trademark application allotment
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      Once the trademark registration application is filed with
                      the trademark registrar, a trademark application allotment
                      number is provided within one or two working days. The
                      application can then be tracked online through the online
                      trademark search facility. On obtaining the allotment
                      number, the owner of the trademark can affix the TM symbol
                      next to the logo.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    pb-4 ">
                <div className="card text-white  h-100 item2">
                  <h5 className="card-title text-center">
                    Vienna codification
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      The Vienna codification established by the Vienna
                      agreement (1973) is an international classification of the
                      figurative elements of marks. Once the application is
                      filed, the trademark registrar will apply the Vienna
                      classification to the trademark based on the figurative
                      elements of marks. While this work is in progress, the
                      trademark application status usually reflects as "sent for
                      Vienna codification"
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4   pb-4 ">
                <div className="card text-white  h-100  item3">
                  <h5 className="card-title text-center">
                    Trademark Examination
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Once the Vienna codification is complete, the trademark
                      registration application will be allotted to an officer in
                      the trademark registrar office. The trademark officer
                      would then review the trademark application for
                      correctness and issue a trademark examination report. The
                      officer will either accept the application and allow for
                      journal publication or object the same.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4  mt-3 ">
                <div className="card text-white  h-100  item4">
                  <h5 className="card-title text-center">Objection</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      If the trademark registration application is objected by
                      the Trademark Officer, the trademark applicant has the
                      right to appear before the officer and address the
                      objections. If the officer is satisfied with the
                      justifications of the trademark applicant, the trademark
                      would be allowed for trademark journal publication. In
                      case the officer is not satisfied with the justifications,
                      the trademark applicant has the right to appeal the
                      decision of the Trademark officer before the Intellectual
                      Property Appellate Board.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    mt-3 ">
                <div className="card text-white  h-100 item5">
                  <h5 className="card-title text-center ">
                    Journal Publication
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      If the trademark registration application is opposed by a
                      third-party, a hearing will be called for by the trademark
                      hearing officer. Both the applicant and the opposing party
                      have the chance to appear at the hearing and provide
                      justifications for registration or rejection of the
                      trademark application. Based on the hearings and the
                      evidence presented, the hearing officer will determine if
                      the application should be accepted or rejected. The
                      decision of the hearing officer can also be challenged by
                      escalating it to the Intellectual Property Appellate Board
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    mt-3">
                <div className="card text-white  h-100 item6 ">
                  <h5 className="card-title text-center">
                    Trademark Registration
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      If there are no objections or oppositions to the
                      application, the trademark manuscript and trademark
                      registration certificate will be prepared. Once the
                      trademark registration certificate is issued, the mark is
                      considered to be a registered trademark of the owner,
                      granting the owner exclusive use of the mark. The symbol
                      can now be placed next to the logo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* --------------accordion for Faqs------------*/}
        <section className="py-3 faqs">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <img
                  src={FFImg1}
                  alt="Lopamudra"
                  className="embed-responsive"
                ></img>
              </div>
              <div className="wrap col-lg-7 shadow">
                <div className="container demo text-justify">
                  <h1 className="pb-3 text-light secu">FAQs on DMHO</h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div
                    className="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div className="panel panel-default mt-3">
                      <div className="panel-heading" role="tab" id="headingOne">
                        <h4 className="panel-title text-light">
                          <a
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            className="seccccc "
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            What is bio medical wastage ?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseOne"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingOne"
                      >
                        <div className="panel-body ">
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. 3 wolf moon
                          officia aute, non cupidatat skateboard dolor brunch.
                          Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                          wolf moon tempor, sunt aliqua put a bird on it squid
                          single-origin coffee nulla assumenda shoreditch et.
                          Nihil anim keffiyeh helvetica, craft beer labore wes
                          anderson cred nesciunt sapiente ea proident. Ad vegan
                          excepteur butcher vice lomo. Leggings occaecat craft
                          beer farm-to-table, raw denim aesthetic synth nesciunt
                          you probably haven't heard of them accusamus labore
                          sustainable VHS.
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTwo">
                        <h4 className="panel-title">
                          <a
                            className="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            className="seccccc"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Collapsible Group Item #2
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseTwo"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div className="panel-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. 3 wolf moon
                          officia aute, non cupidatat skateboard dolor brunch.
                          Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                          wolf moon tempor, sunt aliqua put a bird on it squid
                          single-origin coffee nulla assumenda shoreditch et.
                          Nihil anim keffiyeh helvetica, craft beer labore wes
                          anderson cred nesciunt sapiente ea proident. Ad vegan
                          excepteur butcher vice lomo. Leggings occaecat craft
                          beer farm-to-table, raw denim aesthetic synth nesciunt
                          you probably haven't heard of them accusamus labore
                          sustainable VHS.
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingThree"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            className="seccccc"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Collapsible Group Item #3
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseThree"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingThree"
                      >
                        <div className="panel-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. 3 wolf moon
                          officia aute, non cupidatat skateboard dolor brunch.
                          Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                          wolf moon tempor, sunt aliqua put a bird on it squid
                          single-origin coffee nulla assumenda shoreditch et.
                          Nihil anim keffiyeh helvetica, craft beer labore wes
                          anderson cred nesciunt sapiente ea proident. Ad vegan
                          excepteur butcher vice lomo. Leggings occaecat craft
                          beer farm-to-table, raw denim aesthetic synth nesciunt
                          you probably haven't heard of them accusamus labore
                          sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingfour"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsefour"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            className="seccccc"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Collapsible Group Item #4
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsefour"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingfour"
                      >
                        <div className="panel-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. 3 wolf moon
                          officia aute, non cupidatat skateboard dolor brunch.
                          Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                          wolf moon tempor, sunt aliqua put a bird on it squid
                          single-origin coffee nulla assumenda shoreditch et.
                          Nihil anim keffiyeh helvetica, craft beer labore wes
                          anderson cred nesciunt sapiente ea proident. Ad vegan
                          excepteur butcher vice lomo. Leggings occaecat craft
                          beer farm-to-table, raw denim aesthetic synth nesciunt
                          you probably haven't heard of them accusamus labore
                          sustainable VHS.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingfive"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsefive"
                            aria-expanded="false"
                            aria-controls="collapsefive"
                            className="seccccc"
                          >
                            Collapsible Group Item #5
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsefive"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingfive"
                      >
                        <div className="panel-body">
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. 3 wolf moon
                          officia aute, non cupidatat skateboard dolor brunch.
                          Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                          wolf moon tempor, sunt aliqua put a bird on it squid
                          single-origin coffee nulla assumenda shoreditch et.
                          Nihil anim keffiyeh helvetica, craft beer labore wes
                          anderson cred nesciunt sapiente ea proident. Ad vegan
                          excepteur butcher vice lomo. Leggings occaecat craft
                          beer farm-to-table, raw denim aesthetic synth nesciunt
                          you probably haven't heard of them accusamus labore
                          sustainable VHS.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default DMHO;
