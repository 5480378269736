import React from "react";
import RAGAVAImg from "../../assets/images/RAGAVA.jpeg";
import RAMImg from "../../assets/images/RAM.jpeg";
import ANUImg from "../../assets/images/ANU.jpeg";
import RAMANAImg from "../../assets/images/RAMANA.jpeg";
class team extends React.Component {
  render() {
    return (
      <div>
        <section id="team" className="  py-5 ">
          <div className="container py-5 justify-content-center ">
            <div className="row ">
              <div className="col-12 col-lg-8 align-self-top text-center text-md-left text w-50">
                <div className="row intro">
                  <div className="col-12 p-0">
                    <span className="pre-title3 m-auto m-md-0">
                      We like what we do
                    </span>
                    <h2>
                      <span className="featured ">
                        <span className="bgd">&nbsp;Team&nbsp;</span>
                      </span>{" "}
                      of Experts
                    </h2>
                    <p>
                      Ethics and integrity are the bases on which our
                      professionals build their careers.
                      <br />
                      They are fundamentals that become daily attitudes.
                    </p>
                  </div>
                </div>
                <div className="row items text-left intro pl-4 ">
                  <div className="col-12 col-md-6 p-0 pt-4 ">
                    <div className="row item ">
                      <div className="col-4 p-0 pr-3 align-self-center ">
                        <img src={RAMImg} alt="Lopamudra" className="person " />
                      </div>
                      <div className="col-8 align-self-center text-left py-3">
                        <h4 id="names"> K.Ramesh Reddy</h4>
                        <p>M.D</p>
                        <div className="social-icons">
                          <a href="https://www.facebook.com/ramnjoy1947">
                            {" "}
                            <i
                              id="icon"
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </a>

                          <a href="">
                            {" "}
                            <i
                              id="icon"
                              className="fa fa-twitter"
                              aria-hidden="true"
                            ></i>
                          </a>

                          <a href="https://www.linkedin.com/in/ramesh-reddy-8740b383/">
                            {" "}
                            <i
                              id="icon"
                              className="fa fa-linkedin"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="row item">
                      <div className="col-4 p-0 pr-3 pt-4 align-self-center">
                        <img src={ANUImg} alt="Lopamudra" className="person" />
                      </div>
                      <div className="col-8 align-self-center text-left ">
                        <h4 id="names"> Chinnam Anoohya</h4>
                        <p>ACA, CS</p>
                        <div className="social-icons">
                          <a href="https://www.facebook.com/anoohya.chinnam">
                            {" "}
                            <i
                              id="icon"
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </a>

                          <a href="https://twitter.com/CAnoohya?s=08">
                            {" "}
                            <i
                              id="icon"
                              className="fa fa-twitter"
                              aria-hidden="true"
                            ></i>
                          </a>

                          <a href="https://www.linkedin.com/in/chinnam-anoohya-aca-cs-66044582/">
                            {" "}
                            <i
                              id="icon"
                              className="fa fa-linkedin"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 p-0 pt-4">
                    <div className="row item">
                      <div className="col-4 p-0 pr-3 align-self-center">
                        <img
                          src={RAGAVAImg}
                          alt="Lopamudra"
                          className="person"
                        />
                      </div>
                      <div className="col-8 align-self-center text-left py-3">
                        <h4 id="names">B.Raghava Reddy</h4>
                        <p>LLB,MBA,ACS.</p>
                        <div className="social-icons">
                          <a href="https://www.facebook.com/raghava.bana">
                            {" "}
                            <i
                              id="icon"
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </a>

                          <a href="#">
                            {" "}
                            <i
                              id="icon"
                              className="fa fa-twitter"
                              aria-hidden="true"
                            ></i>
                          </a>

                          <a href="https://www.linkedin.com/in/cs-raghavendra-reddy-bana-llb-mba-acs-9b13574b/">
                            {" "}
                            <i
                              id="icon"
                              className="fa fa-linkedin"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row item">
                      <div className="col-4 p-0 pr-3 align-self-center py-4">
                        <img
                          src={RAMANAImg}
                          alt="Lopamudra"
                          className="person"
                        />
                      </div>
                      <div className="col-8 align-self-center text-left">
                        <h4 id="names">O.Ramana Reddy </h4>
                        <p> ASSOCIATE</p>
                        <div className="social-icons">
                          <a href="https://www.facebook.com/profile.php?id=100008503096347">
                            {" "}
                            <i
                              id="icon"
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </a>

                          <a href="#">
                            {" "}
                            <i
                              id="icon"
                              className="fa fa-twitter"
                              aria-hidden="true"
                            ></i>
                          </a>

                          <a href="https://www.linkedin.com/in/ramana-reddy-obulreddy-2a9199186/">
                            {" "}
                            <i
                              id="icon"
                              className="fa fa-linkedin"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 align-self-end w-50 ">
                <div className="quote ">
                  <div className="quote-content">
                    <h4 id="A" className="text-light">
                      MD Speech
                    </h4>
                    <p id="cont">
                      " The prime intention of our organisation is to provide
                      OneStop solution for all startup/Organizations needs.
                    </p>
                    <p id="cont">
                      Lopamudra Corporate Solutions a leading global compliance
                      imperator started with the motto of helping all the
                      business entities to grow in positive way through
                      adherence of Law.
                    </p>
                    <p id="cont">
                      We take your compliance responsibility and make you feel
                      comfortable by rendering our services with hundred percent
                      accuracy "
                    </p>
                    <h5 id="B" className="text-light">
                      Ramesh Reddy.K{" "}
                      <span className="quotes text-dark">
                        <i
                          className="fa fa-quote-right align-top"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default team;
