import React from "react";
import Footer from "../Navigation/footer";
import TradeImg from "../../assets/images/Trade.jpg";
import TradeoImg from "../../assets/images/Tradeo.jpg";
import FAQImg1 from "../../assets/images/FAQ-1.png";
class Trademarkobjection extends React.Component {
  render() {
    return (
      <div>
        <section id="page content" className=" p-3 mb-5 bg-light">
          <div className="sidebar-right background-grey ">
            <div className="container pt-4 pb-4 ">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive"
                          src="https://blog.ipleaders.in/wp-content/uploads/2019/10/trademarkregistered-598a056768e1a200116dc33a.jpg"
                          alt="Lopamudra"
                        />
                      </div>

                      <div className="col-lg-7">
                        <h5 className="title">Trademark Objection</h5>
                        <hr />
                        <p className=" text-justify">
                          Lopamudra in India has a dedicated team of company
                          secretarial, legal professionals and Chartered
                          Accountants who possess extensive experience in
                          various corporate laws and commercial / legal
                          documentation. Our team is highly business oriented in
                          approach and has experience across sectors and the
                          acumen to provide complete solutions for all corporate
                          secretarial matters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 mb-5">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container  d-flex sidebar-right ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50 ">
                <h1> Trademark Objection:</h1>
                <p className="text-justify">
                  Trademark registration is a type of intellectual property
                  protection, under which a word or visual symbol used by a
                  business to distinguish it goods or services from other
                  similar goods or services originating from a different
                  business can be protected. To register a trademark, a
                  trademark application must be filed by the applicant with the
                  relevant Trade Mark Registrar in the prescribed format. Once a
                  trademark application is filed, the Trade Marks Registrar
                  would process the application and issue an Examination Report.
                  Among the outcomes, the Trademark Examination Report could
                  allow for the trademark application to be advertised before
                  registration or the Trademark Examiner could raise an
                  objection for registration of the mark.
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={TradeImg}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="contetnt1" className="bg-light">
          <div className=" container py-4 d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid  p-3 mb-5 "
                  src={TradeoImg}
                  alt="Lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50  ">
                <h1>What is Trademark examination Report??</h1>
                <p className="text-justify">
                  Once a trademark application is filed, the Trade Marks
                  Registrar will process your application and issue an
                  Examination Report for Trademark. Examination report could be
                  for advertising your trademark before registration or he can
                  come up with an objection for registration of the trademark.
                  There are no fixed reasons why your Trademark Application may
                  be objected.
                </p>

                <p className="text-justify">
                  The Trade Marks Office can, for various reasons, have
                  objections to your trademark application. This may be because
                  the word or logo is too similar or resembling to an existing
                  trademark, or various other reasons. Law gives you an
                  opportunity to respond professionally and reply to trademark
                  objection raised. You must file your response to this
                  objection within a month from when the Examination Report has
                  been published online. If you don’t, the Registrar may abandon
                  your application.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* ---------------------------check list--------------------------------------------- */}
        <section id="documents" className="py-3">
          <div className="container  py-2">
            <div className="d-flex container col-md-7 text-center">
              <h1 className="secu mb-4 text-dark pb-2">
                Documents Required for Trademark Objection
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow p-3">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3  shadow p-3">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* -----------------------------------sectoin with cards------------------------ */}
        <section className="cardssec">
          <div className="container  py-5">
            <div className=" container col-md-7">
              <h4 className=" secu  text-center">
                Effectively Replying to Trademark Objections
              </h4>
              <h3 className="text-light mb-5 mt-3 text-center">
                <strong>Points to make your decision easy</strong>
              </h3>
            </div>
            <div className="row mt-3">
              <div className=" col-lg-4   pb-4">
                <div className="card text-white h-100 item1">
                  <h5 className="card-title text-center">Brand Protection</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      By the time you receive objection, you might have been
                      using the ™ symbol already. Your business brand would have
                      started creating visibility. You should have started
                      creating value for your brand. Filing a new trademark
                      application may not be relevant. Instead, you could simply
                      identify the list of reasons your logo, slogan or brand
                      name are different
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    pb-4 ">
                <div className="card text-white  h-100 item2">
                  <h5 className="card-title text-center">
                    Reply Professionally
                  </h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Filing a reply to objection does not guarantee for you to
                      sustain your application. However, it is still relevant
                      because you would not wish to see your application
                      abandoned because of few reasons. Expert Professionals
                      like ebizfiling will come handy this time. Drafting and
                      filing objection reply through us will significantly
                      improve your chances of getting through.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4   pb-4 ">
                <div className="card text-white  h-100  item3">
                  <h5 className="card-title text-center">Responding in Time</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Once Examination report is issued, you have one month (30
                      days) to file your reply against the same. There’s no bar
                      on filing reply even after 30 days. However, this
                      significantly reduces your chances of furthering your
                      trademark application. It is therefore advisable to act in
                      time and make sure of submission of reply to objection
                      within defined time frame.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ----------------------------------------faq s------------------------------- */}
        <section>
          <div className="faqs">
            <div className="container py-5">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid ">
                  <h1 className="pb-3 secu text-light">
                    FAQs on Trademark Objection
                  </h1>
                  <h4 className="pb-4">Get answer to all your quiries</h4>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par text-light">
                        <h4>What happens if a trademark is objected? </h4>
                        <p className="text-light">
                          Once an objection is filed the applicant will be given
                          due notice about the objection as well as the grounds
                          of objection. The first thing one must do is file a
                          counter statement to the objection. Failure to file an
                          objection within 2 months will change the status of
                          the application to Abandoned.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>
                          What is difference between Trademark Examination and
                          Trademark Objection?
                        </h4>
                        <p className="text-light">
                          There is no difference; they are one and the same.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4> Can we use objected trademark?</h4>
                        <p className="text-light">
                          When the status of the trademark in the Indian Trade
                          Mark Registry website is shown as 'objected', this
                          means that the trade mark examiner has raised an
                          objection against the Trade mark in the examination
                          report.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>How to Respond to Trademark Objection?</h4>
                        <p className="text-light">
                          Registered trademarks are valid for 10 years from the
                          date of filing. The owner of the mark can file for
                          renewal to keep the trademark protection current prior
                          to the end of its validity.
                        </p>
                      </li>

                      <li className="col-lg-12 faq_par">
                        <h4>Still, have confusion?</h4>
                        <p className="text-light">
                          Don’t worry!! Our expert will help you to choose a
                          best suitable plan for you. Get in touch with our team
                          to get all your queries resolved. Write to us at
                          info@lopamudracs.com or call us @+91 9966534699.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Trademarkobjection;
