import React from "react";
import Footer from "../Navigation/footer";
import INDImg1 from "../../assets/images/IND-1.jpg";
import INDImg2 from "../../assets/images/IND-2.jpg";
import CheckImg from "../../assets/images/Check.png";
import FAQImg1 from "../../assets/images/FAQ-1.png";
class Indian extends React.Component {
  /*  constructor(){
        super();this.stat
    } */
  render() {
    return (
      <div>
        <section id="page content" className=" p-3 bg-light">
          <div className="sidebar-right background-grey ">
            <div className="container pt-4 ">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row align-items-center">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive"
                          src="https://images.unsplash.com/photo-1553484771-4e29a68540f4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDV8fGVudHJlcHJlbmV1cnxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=500&q=60"
                          alt="enterpreneur"
                        />
                      </div>

                      <div className="col-lg-7">
                        <h5 className="title">Indian Subsidiary</h5>
                        <hr />
                        <p className=" text-justify">
                          Lopamudra in India has a dedicated team of company
                          secretarial, legal professionals and Chartered
                          Accountants who possess extensive experience in
                          various corporate laws and commercial / legal
                          documentation. Our team is highly business oriented in
                          approach and has experience across sectors and the
                          acumen to provide complete solutions for all corporate
                          secretarial matters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 mb-2">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container  d-flex sidebar-right ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12 w-50 ">
                <h1>Registration of Indian Subsidiary</h1>
                <p className="text-justify">
                  Incorporating a Public Limited Company is a suitable option
                  for large scale businesses that require huge capital. There
                  should be a minimum of seven members with no limit on maximum
                  number of members/shareholders for starting a Public Limited
                  Company.
                </p>
                <p>
                  Usually, Public Limited Companies get listed with stock
                  exchanges to raise capital from the general public. This is
                  why Public Limited Companies have to comply with multiple
                  regulations of the government and starting a public limited
                  company becomes a cumbersome process.
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={INDImg1}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="contetnt1" className="bg-light ">
          <div className="container d-flex">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid  "
                  src={INDImg2}
                  alt="Lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12 w-50  ">
                <h1>Indian Subsidiary?</h1>

                <p>
                  A Public Limited Company is defined under Section 2(71) of the
                  Companies Act, 2013 as:
                </p>
                <div className="text-warning">
                  <ul>
                    <li>a company which is not a private company</li>
                    <li>
                      a company with a minimum paid-up share capital of INR 5
                      lakhs.
                    </li>
                  </ul>
                </div>
                <p className="text-justify m-t-20 m-b-35">
                  <strong className="text-dark">Note :</strong> Under the
                  Companies Act, 2013, a subsidiary company shall be deemed to
                  be a public company if it is not a subsidiary to a private
                  company, even if it is a private company as per its articles.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="documents" className="py-2">
          <div className="container  py-3">
            <div className="d-flex text-center container col-md-7">
              <h1 className=" secu text-dark pb-2">
                Documents Required for Indian Subsidiary
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow p-3">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3 shadow p-3 ">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="cards">
          <div className="container text-justify py-3">
            <h1 className="text-center py-3 secu container col-md-7">
              9 Articles to Read Before Partnership Registration
            </h1>
            <div className="row mt-4">
              <div className="col-md-4 mb-4">
                <div className="card  h-100  item1">
                  <div className="title text-center">
                    <h4 className="text-light"> Partnership vs LLP</h4>
                  </div>
                  <div className="body p-3 text-center ">
                    <p className="text-light">
                      A comparison of the popular types of business entity in
                      India, viz. Proprietorship vs Partnership vs LLP vs
                      Private Limited Company vs One Person Company.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-4">
                <div className="card h-100  item2">
                  <div className="title text-center">
                    <h4 className="text-light">
                      <strong> Partnership Firm</strong>
                    </h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light">
                      Guide to partnership firm in India including the various
                      types and features. The law relating to partnership firm
                      in India is prescribed in the Indian Partnership Act of
                      1932. This Act lays down the rights and duties of the
                      partners between themselves and other legal relations
                      between partners and third persons, which are incidental
                      to the formation of a partnership.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card   item3 h-100 ">
                  <div className="title text-center ">
                    <h4 className="text-light">Inter-state vs Intra-state</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      The meaning of interstate and intrastate supply under GST
                      with illustrations. In GST, the terms interstate and
                      intrastate have tremendous significance in the
                      determination of IGST, CGST or SGST. Interstate supply
                      attracts IGST, while intrastate supply attracts CGST and
                      SGST.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-4 mb-4">
              <div className="col-md-4 mb-4">
                <div className="card  item4 h-100 ">
                  <div id="title" className="title text-center">
                    <h4 className="text-light"> GST Invoice Format</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light ">
                      Procedure to create a GST invoice as per the GST Act or
                      download a sample GST invoice as per GST Invoice Rules.
                      Under GST, GSTIN of the supplier is mandatorily required
                      to be mentioned in the tax invoice along with a
                      consecutive serial number that is unique for each
                      financial year.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-4">
                <div className="card  h-100 item5">
                  <div className="title text-center">
                    <h4 className="text-light"> GST Composition scheme</h4>
                  </div>
                  <div className="body p-3 mb-4">
                    <p className="text-light">
                      GST composition scheme helps small businesses with
                      turnover of less than Rs.150 lakhs by simplifying the GST
                      tax return filing procedure and allowing quarterly filing
                      of GST returns.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card  item6  h-100">
                  <div className="title text-center ">
                    <h4 className="text-light"> GST return filing</h4>
                  </div>
                  <div className="body p-3 ">
                    <p className="text-light">
                      File GST return online through Lopamudra with GST Expert
                      Support. You can prepare and file GSTR-1, GSTR-2, GSTR-3
                      and GSTR-4 return online lpcs. In addition to filing GST
                      returns, you can also issue GST invoices and record
                      purchases on LEDGERS to automatically file GST returns.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-4 mb-4">
                <div className="card  h-100  item1">
                  <div className="title text-center">
                    <h4 className="text-light"> Partnership vs LLP</h4>
                  </div>
                  <div className="body p-3 text-center ">
                    <p className="text-light">
                      A comparison of the popular types of business entity in
                      India, viz. Proprietorship vs Partnership vs LLP vs
                      Private Limited Company vs One Person Company.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-4">
                <div className="card h-100  item2">
                  <div className="title text-center">
                    <h4 className="text-light">
                      <strong> Partnership Firm</strong>
                    </h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light">
                      Guide to partnership firm in India including the various
                      types and features. The law relating to partnership firm
                      in India is prescribed in the Indian Partnership Act of
                      1932. This Act lays down the rights and duties of the
                      partners between themselves and other legal relations
                      between partners and third persons, which are incidental
                      to the formation of a partnership.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card   item3 h-100 ">
                  <div className="title text-center ">
                    <h4 className="text-light">Inter-state vs Intra-state</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      The meaning of interstate and intrastate supply under GST
                      with illustrations. In GST, the terms interstate and
                      intrastate have tremendous significance in the
                      determination of IGST, CGST or SGST. Interstate supply
                      attracts IGST, while intrastate supply attracts CGST and
                      SGST.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="faqs">
            <div className="container py-3">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid">
                  <h1 className="pb-3 text-light secu">
                    FAQs on Indian Subsidiary
                  </h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par">
                        <h4> What is a sole proprietorship??</h4>
                        <p className="text-light">
                          A sole proprietorship qualifies as a company, but it
                          only has one owner and isn’t required to register with
                          state enemies. Beginning a sole proprietorship is
                          extremely simple and doesn’t require a formal filing
                          or paperwork process. All you have to do is simply go
                          into business.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Is GST mandatory for proprietorship??</h4>
                        <p className="text-light">
                          Sole Proprietorship Registration through GST
                          Registration. ... Every registered business has to
                          compulsorily collect the tax from the customers and
                          file the GST returns. If a sole proprietor has a
                          turnover of less than Rs. 20 Lakhs, it is not
                          mandatory for him to get registered and collect GST.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>How many owners are in a proprietorship?</h4>
                        <p className="text-light">
                          A proprietorship can legally have only one owner.
                          That's because the business serves as part of the
                          business owner's personal identity and vice versa.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>What is the advantage of proprietorship?</h4>
                        <p className="text-light">
                          One of the functional advantages of sole
                          proprietorships is that they are easier to set up than
                          other business entities. A person becomes a sole
                          proprietor simply by running a business. Another
                          functional advantage of a sole proprietorship is that
                          the owner maintains 100% control and ownership of the
                          business.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Still, have Confusion ?</h4>
                        <p className="text-light">
                          Don’t worry!! we are here to help you .Get in touch
                          with our team to get all your queries resolved. Write
                          to us at info@lopamudracs.com or 9966534699.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Indian;
