import React from "react";
import AdMp4 from "../../assets/images/Ad.mp4";
class Adv extends React.Component {
  render() {
    return (
      <div>
        <section>
          <div className="container ">
            <div className="py-2 m-20">
              <div className=" white-heading text-center">
                <h5 className="pre-title ">INTRODUCTION VIDEO</h5>
                <h2>
                  Advisory <span className="bgc">&nbsp;Performance&nbsp;</span>
                </h2>
              </div>
              <div>
                <iframe
                  className="video-center  embed-responsive"
                  width="auto"
                  height="auto"
                  src={AdMp4}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Adv;
