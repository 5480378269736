import React from "react";
import Footer from "../Navigation/footer";
import FFImg1 from "../../assets/images/FF-1.png";
class MSME extends React.Component {
  render() {
    return (
      <div>
        <section id="GST">
          <div className="jumbotron6 jumbotron-fluid">
            <div className="container">
              <h2 className=" pb-0 text-center  text-light ">
                <span className="bgc">&nbsp;MSME&nbsp;</span>
                <h2 className="text-dark">Registration</h2>
              </h2>
              <p></p>
            </div>
          </div>
        </section>
        <section id="single">
          <div className="container py-5 ">
            <div className="row">
              <div className="col-12 col-lg-8 p-0 text-fluid ">
                <div className="row intro m-0">
                  <div className="col-12">
                    <span className="pre-title m-0">
                      Know what the law requires
                    </span>
                    <h2>
                      <span> MSME Registration </span>
                    </h2>
                    <p>
                      MSME is a Ministry of Micro, Small, Medium Enterprises
                      which is at present headed by Shri Nitin Jairam Gadkari.
                    </p>
                    <p>
                      Micro, Small and Medium Enterprises (MSME) sector has
                      emerged as a highly vibrant and dynamic sector of the
                      Indian economy over the last five decades. MSMEs not only
                      play a crucial role in providing large employment
                      opportunities at comparatively lower capital cost than
                      large industries but also help in industrialization of
                      rural & backward areas, thereby, reducing regional
                      imbalances, assuring more equitable distribution of
                      national income and wealth. MSMEs are complementary to
                      large industries as ancillary units and this sector
                      contributes enormously to the socio-economic development
                      of the country.
                    </p>
                    <p className="text-primary">
                      {" "}
                      It is classified in two Classes:
                    </p>

                    <h4>Manufacturing Enterprises :</h4>
                    <p>
                      The enterprises engaged in the manufacturing or production
                      of goods. The Manufacturing Enterprise are defined in
                      terms of investment in Plant & Machinery.
                    </p>
                    <p>
                      In the Manufacturing sector micro, small and Medium
                      enterprises are determined by the investment in plant and
                      machineries
                    </p>
                    <ul>
                      <li>
                        <p>
                          . Micro enterprise- Does not exceed 25 lakh rupees
                        </p>
                      </li>
                      <li>
                        <p>
                          Small enterprise- More than 25 lakh rupees but does
                          not exceed 5 crore rupees
                        </p>
                      </li>
                      <li>
                        <p>
                          Medium enterprise- More than 5 crore rupees but does
                          not exceed 10 crore rupees
                        </p>
                      </li>
                    </ul>
                    <h4>Service Enterprises: </h4>
                    <p>
                      The enterprises engaged in providing or rendering of
                      services and are defined in terms of investment in
                      equipment.
                    </p>
                    <p>
                      In Service sector micro, small and Medium enterprise are
                      determine by the investment in plant and machinery
                    </p>
                    <ul>
                      <li>
                        <p>Micro enterprise- Does not exceed 10 lakh rupees </p>
                      </li>
                      <li>
                        <p>
                          Small enterprise- More than 10 lakh rupees but does
                          not exceed 2 crore rupees{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                          Medium enterprise- More than 2 crore rupees but does
                          not exceed 5 crore rupees
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <aside className="col-12 col-lg-4 pl-lg-8 pt-5   ">
                <div className="shadow p-3 mb-5">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2 " id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </aside>
            </div>
          </div>
        </section>

        <section className="bg-light py-5 Documents-section">
          <div className="container ">
            <h1 className="text-center ">Documents Required for MSME</h1>
            <div className="container text-center">
              <div className="row">
                <div id="flag" className="col-md-4  py-3 pl-5">
                  <h4 className="subheadingtitle  ">
                    &nbsp;Pvt. Ltd. /Public Ltd./OPC{" "}
                  </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      Company PAN card
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      MOA, AOA &amp; Incorporation Certificate
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Company Address Proof
                    </p>
                  </ul>
                </div>
                <div className="col-md-4 py-3  pl-5 ">
                  <h4 className="subheadingtitle ">
                    LLP &amp; Partnership Firm{" "}
                  </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Firm PAN card
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      LLP Agreement/ Partnership deed
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Firm Address Proof
                    </p>
                  </ul>
                </div>
                <div className="col-md-4  py-3 pl-5  ">
                  <h4 className="subheadingtitle">Proprietorship Firm </h4>
                  <ul id="proofs">
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Proprietor's PAN card
                    </p>
                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>{" "}
                      Shop Act / Ghumasta License
                    </p>

                    <p>
                      {" "}
                      <i
                        className="fa fa-check-circle text-primary mr-3"
                        aria-hidden="true"
                      ></i>
                      Address proof of Firm
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cardssec">
          <div className="container  py-5">
            <div className="text-center container col-md-6 ">
              <h4 className=" secu  ">Benifits of MSME Registration</h4>
              <p className="text-light">
                <h4>Points to make your Decision Easy</h4>
              </p>
            </div>
            <div className="row mt-3">
              <div className=" col-lg-4   pb-4">
                <div className="card text-white h-100 item1">
                  <h5 className="card-title text-center">Micro Enterprises</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      Micro enterprises are the smallest entities. Micro
                      manufacturing & service enterprises have an investment of
                      less than or up to Rs 1 crore and turnover up to Rs 5
                      crore.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    pb-4 ">
                <div className="card text-white  h-100 item2">
                  <h5 className="card-title text-center">Small Enterprises</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Businesses with an investment of less than Rs 10 crore and
                      turnover less than Rs. 50 crore will now be classified as
                      small enterprises.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4   pb-4 ">
                <div className="card text-white  h-100  item3">
                  <h5 className="card-title text-center">Medium Enterprises</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      The definition for medium enterprises has been revised
                      upwards to an investment of Rs 50 crore and a turnover of
                      Rs 250 crore.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4  mt-3 ">
                <div className="card text-white  h-100  item4">
                  <h5 className="card-title text-center">Registration</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      MSME registration is not mandatory but it is always
                      suggested to small and medium enterprises to get it done
                      as it provides a variety of benefits.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    mt-3 ">
                <div className="card text-white  h-100 item5">
                  <h5 className="card-title text-center ">Major Benifits</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify">
                      Major benefits Bank Loans (Collateral Free), Subsidy on
                      Patent Registration., ISO Certification Charges
                      Reimbursement.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4    mt-3">
                <div className="card text-white  h-100 item6 ">
                  <h5 className="card-title text-center">No Return Filing</h5>
                  <div className="card-body csaa">
                    <p className="text-light text-justify ">
                      For Micro, Small & Medium Enterprises, An MSME Certificate
                      once applied to need no renewals. It is valid for life
                      time. Further, there are no periodic returns to be filed
                      in respect of it.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* --------------accordion for Faqs------------*/}
        <section className="py-3 faqs">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <img
                  src={FFImg1}
                  alt="Lopamudra"
                  className="embed-responsive"
                ></img>
              </div>
              <div className="wrap col-lg-7 shadow">
                <div className="container demo text-justify">
                  <h1 className="pb-3 text-light secu">FAQs on MSME</h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div
                    className="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div className="panel panel-default mt-3">
                      <div className="panel-heading" role="tab" id="headingOne">
                        <h4 className="panel-title text-light">
                          <a
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            className="seccccc "
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Is the MSME registration mandatory?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseOne"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingOne"
                      >
                        <div className="panel-body ">
                          No. MSME registration is not made mandatory by the
                          Government yet but registration is beneficial to avail
                          the advantages set by the government.
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="headingTwo">
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Who Can Apply for MSME Registration?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseTwo"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                      >
                        <div className="panel-body">
                          Any entrepreneur having valid Aadhaar Number can apply
                          for MSME Registration Online.
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingThree"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            Is physical copy of Certificate will be issued?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseThree"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingThree"
                      >
                        <div className="panel-body">
                          In the interest of the environment, no physical copy
                          of MSME Certificate will be issued. The government
                          believes in paperless work.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingfour"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsefour"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i className="more-less glyphicon glyphicon-plus"></i>
                            How is the value of plant & machinery computed?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsefour"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingfour"
                      >
                        <div className="panel-body">
                          It is computed as per the MSMED act and rules provided
                          their under, but while computing the value of plant &
                          machinery their original value is to be taken into
                          consideration.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        role="tab"
                        id="headingfive"
                      >
                        <h4 className="panel-title">
                          <a
                            className="collapsed seccccc"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapsefive"
                            aria-expanded="false"
                            aria-controls="collapsefive"
                          >
                            Still, have confusion?
                            <i
                              className="fa fa-chevron-down float-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapsefive"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="headingfive"
                      >
                        <div className="panel-body">
                          Don’t worry!! we are here to help you .Get in touch
                          with our team to get all your queries resolved. Write
                          to us at info@lopamudracs.com or 9966534699
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default MSME;
