import React from "react";
import Carousel from "react-bootstrap/Carousel";
import oneImg9 from "../../assets/images/one-9.jpg";
import LtstImg from "../../assets/images/Ltst.jpg";
import LtstImg1 from "../../assets/images/Ltst-1.jpg";
import LtstImg2 from "../../assets/images/Ltst-2.jfif";
import LtstImg3 from "../../assets/images/Ltst-3.jpg";
import LtstImg4 from "../../assets/images/Ltst-4.jpg";
import LtstImg5 from "../../assets/images/Ltst-5.jpg";
import LtstImg6 from "../../assets/images/Ltst-6.jpg";
class latestnews extends React.Component {
  render() {
    return (
      <div>
        <section className="py-5" id="slide">
          <div className="container ">
            <div className="col-12 p-0">
              <span className="pre-title3 m-auto m-md-0 py-4">
                OUR EDITORIAL CONTENT
              </span>
              <h2>
                <span className="featured ">Latest </span>
                <span className="bgd">&nbsp;News&nbsp;</span>{" "}
              </h2>
              <p>
                Every week we publish content about what is best in the business
                world.
                <a id="sees" href="#" className="btn float-right text-light">
                  SEE ALL
                </a>
              </p>
            </div>
          </div>

          <div className="container my-4">
            <br />
            <div
              id="multi-item-example"
              className="carousel slide carousel-multi-item"
              data-ride="carousel"
            >
              <div className="controls-top">
                <a
                  className="btn-floating"
                  href="#multi-item-example"
                  data-slide="prev"
                >
                  <i className="fa fa-chevron-left"></i>
                </a>
                <a
                  className="btn-floating "
                  href="#multi-item-example"
                  data-slide="next"
                >
                  <i className="fa fa-chevron-right"></i>
                </a>
              </div>

              <ol className="carousel-indicators  ">
                <li
                  data-target="#multi-item-example"
                  data-slide-to="0"
                  className="active "
                ></li>
                <li data-target="#multi-item-example" data-slide-to="1"></li>
                <li data-target="#multi-item-example" data-slide-to="2"></li>
              </ol>

              <div className="carousel-inner" role="listbox">
                <div className="carousel-item active">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="post-slide mb-2 h-100">
                        <img
                          className="card-img-top"
                          src={oneImg9}
                          alt="Lopamudra"
                        />
                        <div className="card-body">
                          <h4 className="card-title text-dark">PAN Search</h4>
                          <p className="card-text">
                            Learn about how to check PAN card details, status by
                            name, date of birth, PAN number online/offline at
                            NSDL Portal. Also know how to verify ...
                          </p>
                          <span className="post-date">
                            <i className="fa fa-clock-o"></i>Out 27, 2019
                          </span>
                          <a
                            href="https://www.incometaxindiaefiling.gov.in/home"
                            className="btn btn-primary read-more"
                            target="_blank"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4  d-none d-md-block">
                      <div className="post-slide mb-2 h-100">
                        <img
                          className="card-img-top"
                          src={LtstImg}
                          alt="Lopamudra"
                        />
                        <div className="card-body">
                          <h4 className="card-title text-dark">TAN Search</h4>
                          <p className="card-text">
                            The applicants may track the status of their TAN
                            application using 14 digit unique Acknowledgment
                            Number after three days of application using the
                            status track ...
                          </p>
                          <span className="post-date">
                            <i className="fa fa-clock-o"></i>Out 27, 2019
                          </span>
                          <a
                            href="https://www.incometaxindiaefiling.gov.in/home"
                            className="btn btn-primary read-more"
                            target="_blank"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4  d-none d-md-block">
                      <div className="post-slide mb-2 h-100">
                        <img
                          className="card-img-top"
                          src={LtstImg1}
                          alt="Lopamudra"
                        />
                        <div className="card-body">
                          <h4 className="card-title text-dark">
                            CURRENCY Converter
                          </h4>
                          <p className="card-text">
                            Free currency converter or travel reference card
                            using daily OANDA Rate® data. Convert currencies
                            using interbank, ATM, credit card, and kiosk cash
                            rates.
                          </p>
                          <span className="post-date">
                            <i className="fa fa-clock-o"></i>Out 27, 2019
                          </span>
                          <a
                            href="https://www1.oanda.com/currency/converter/"
                            className="btn btn-primary read-more"
                            target="_blank"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="post-slide mb-2 h-100">
                        <img
                          className="card-img-top"
                          src={LtstImg6}
                          alt="Lopamudra"
                        />
                        <div className="card-body">
                          <h4 className="card-title text-dark">
                            Online Payment of Taxes{" "}
                          </h4>
                          <p className="card-text">
                            e-payment : Pay Taxes Online. Pay Taxes Online -
                            e-Payment facilitates payment of direct taxes online
                            by taxpayers. To avail of this facility the taxpayer
                            is ...
                          </p>
                          <span className="post-date">
                            <i className="fa fa-clock-o"></i>Out 27, 2019
                          </span>
                          <a
                            href="https://www.incometaxindia.gov.in/Pages/default.aspx"
                            className="btn btn-primary read-more"
                            target="_blank"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4  d-none d-md-block">
                      <div className="post-slide mb-2 h-100">
                        <img
                          className="card-img-top"
                          src={LtstImg2}
                          alt="Lopamudra"
                        />
                        <div className="card-body">
                          <h4 className="card-title text-dark">e-filing</h4>
                          <p className="card-text">
                            E-filing or electronic filing is submitting your
                            income tax returns online. There are two ways to
                            file your income tax returns.
                          </p>
                          <span className="post-date">
                            <i className="fa fa-clock-o"></i>Out 29, 2019
                          </span>
                          <a
                            href="https://www.incometaxindiaefiling.gov.in/home"
                            className="btn btn-primary read-more"
                            target="_blank"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4  d-none d-md-block">
                      <div className="post-slide mb-2 h-100">
                        <img
                          className="card-img-top"
                          src={LtstImg3}
                          alt="Lopamudra"
                        />
                        <div className="card-body">
                          <h4 className="card-title text-dark">Card title</h4>
                          <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <span className="post-date">
                            <i className="fa fa-clock-o"></i>Out 27, 2019
                          </span>
                          <a className="btn btn-primary read-more">Read More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="post-slide mb-2 h-100">
                        <img
                          className="card-img-top"
                          src={LtstImg4}
                          alt="Lopamudra"
                        />
                        <div className="card-body ">
                          <h4 className="card-title text-dark">
                            Challan Status Enquiry
                          </h4>
                          <p className="card-text">
                            On entering Challan Identification Number (CIN i.e.
                            details such as BSR Code of Collecting Branch,
                            Challan Tender Date & Challan Serial No.) and amount
                            (optional),the tax payer can view the following
                            details
                          </p>
                          <span>
                            <i className="fa fa-clock-o"></i>Out 27, 2019
                          </span>
                          <a
                            href="https://www.tin-nsdl.com/"
                            className="btn btn-primary read-more"
                            target="_blank"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="col-md-4  d-none d-md-block">
                      <div className="post-slide mb-2 h-100">
                        <img
                          className="card-img-top"
                          src={LtstImg5}
                          alt="Lopamudra"
                        />
                        <div className="card-body">
                          <h4 className="card-title text-dark">Card title</h4>
                          <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <span className="post-date">
                            <i className="fa fa-clock-o"></i>Out 27, 2019
                          </span>
                          <a className="btn btn-primary read-more">Read More</a>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4  d-none d-md-block">
                      <div className="post-slide mb-2 h-100">
                        <img
                          className="card-img-top"
                          src={LtstImg6}
                          alt="Lopamudra"
                        />
                        <div className="card-body">
                          <h4 className="card-title text-dark">MCA21(ROC)</h4>
                          <p className="card-text">
                            Consequently, eForm MGT-7 has been revised on MCA21
                            Company Forms Download page w.e.f 1st January, 2021.
                            Stakeholders are advised to check the latest ...
                          </p>
                          <span className="post-date">
                            <i className="fa fa-clock-o"></i>Out 27, 2019
                          </span>
                          <a
                            href="http://www.mca.gov.in/mcafoportal/login.do"
                            className="btn btn-primary  read-more"
                            target="_blank"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default latestnews;
