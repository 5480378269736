import React from "react";
import Footer from "../Navigation/footer";
import CheckImg from "../../assets/images/Check.png";
import USAImg1 from "../../assets/images/USA-2.jpg";
import USAImg2 from "../../assets/images/USA-1.jpg";
import FAQImg1 from "../../assets/images/FAQ-1.png";
import { CardImg, Col } from "react-bootstrap";
class USA extends React.Component {
  /*  constructor(){
        super();this.stat
    } */

  render() {
    return (
      <div>
        <section id="page content" className=" p-3  bg-light">
          <div className="sidebar-right background-grey ">
            <div className="container pt-4  ">
              <div className="row">
                <div className="content col-lg-9">
                  <div className="product">
                    <div className="row align-items-center">
                      <div className="col-lg-5">
                        <img
                          className="embed-responsive"
                          src="https://images.unsplash.com/photo-1553484771-4e29a68540f4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDV8fGVudHJlcHJlbmV1cnxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=500&q=60"
                          alt="enterpreneur"
                        />
                      </div>

                      <div className="col-lg-7">
                        <h5 className="title">USA Company Registration</h5>
                        <hr />
                        <p className=" text-justify">
                          Lopamudra in India has a dedicated team of company
                          secretarial, legal professionals and Chartered
                          Accountants who possess extensive experience in
                          various corporate laws and commercial / legal
                          documentation. Our team is highly business oriented in
                          approach and has experience across sectors and the
                          acumen to provide complete solutions for all corporate
                          secretarial matters.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 shadow p-3 ">
                  <p className="widget-title ">CONTACT US</p>
                  <form>
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <br />
                    <div className="col-pt-4-pb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <br />
                    <div className="col-auto-pt-4-pb-4">
                      <button type="submit" className="btn  mb-2" id="sees">
                        Get Started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="content">
          <div className=" container  d-flex  ">
            <div className="row align-items-center">
              <div className="text col-lg-6 col-md-6 col-12  w-50 ">
                <h1>Registration of USA Company</h1>
                <p className="text-justify">
                  With rapid development in technology, shipment times across
                  the world being reduced and an enormous english speaking
                  population, India businesses are in a unique position to
                  explore the American market or establish global businesses
                  based out of USA. With one of the lowest corporate tax rates,
                  well established business laws and practices - USA is the
                  right choice for most Indian businesses with global ambitions
                  or foreign customer base
                </p>
              </div>
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid "
                  src={USAImg1}
                  alt="Lopamudra"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="contetnt1" className="bg-light py-2">
          <div className="container d-flex py-4">
            <div className="row align-items-center">
              <div className="img col-lg-6 col-md-6 col-12 w-50 ">
                <img
                  className="embed-responsive img-fluid p-3 "
                  src={USAImg2}
                  alt="Lopamudra"
                />
              </div>
              <div className="text col-lg-6 col-md-6 col-12  w-50  ">
                <h1>USA Entry Options :</h1>

                <p>
                  A US business can be setup in a number of like
                  sole-proprietorship, general partnership, LLC or a
                  Corporation. However, a the entry route for Indian nationals
                  or Indian entities is limited to a LLC or a C-Corp in the USA.
                </p>
                <p>
                  In the USA the laws and regulations pertaining to
                  incorporation and management of an LLC or Corporation differ
                  from state to state. However, we incorporate all LLC or
                  Corporations for Indian Nationals in Delaware due to various
                  advantages like low taxes, minimal maintenance fees and
                  excellent corporate laws.{" "}
                </p>
                <p>
                  {" "}
                  Delaware is not only a good option for Indian Nationals and
                  companies - but even for American Companies as more than half
                  of all publicly traded and Fortune 500 companies in the USA
                  are incorporated in Delaware.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="documents" className="py-2">
          <div className="container  py-4">
            <div className="d-flex container col-md-7 text-center">
              <h1 className=" mb-4 secu text-dark pb-2">
                Documents Required for USA Company Registration
              </h1>
            </div>
            <div className="row ">
              <div className="col-lg-5   py-3 chek shadow p-3">
                <h4 className="text-center Quick ">Quick checklist</h4>

                <i
                  className="fa fa-check-square-o mr-3  "
                  aria-hidden="true"
                ></i>
                <strong>Form-16 </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Salary Slips</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>
                  Interest certificates from Post Offices &amp; Banks
                </strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 16A/16B/16C</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Form 26AS</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Tax-Savings Investment Proofs</strong>
                <br />
                <i
                  className="fa fa-check-square-o mr-3 "
                  aria-hidden="true"
                ></i>
                <strong>Deductions under Section 80D to 80U</strong>
              </div>

              <br />
              <hr />

              <div className="text col-lg-5  py-3  shadow p-3">
                <div className="panel text-center  ">
                  <h4 className=" Quick">Specific requirements</h4>
                  <p className=" text-justify chek">
                    A Company must have a registered office in India. Documents
                    like bank statement or electricity bill should not be older
                    than 2 months. Along with that utility bill, rent agreement
                    or sale deed and a No Objection Letter (NOC) from the
                    landlord with his/her consent to use the office as a
                    registered office of a company must be submitted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section id="cards">
          <div className="container text-justify pb-3">
            <h1 className="text-center py-5 secu">
              9 Articles to Read Before Partnership Registration
            </h1>
            <div className="row">
              <div className="col-md-4">
                <div className="card  h-100  item1">
                  <div className="title text-center">
                    <h4 className="text-dark"> Partnership vs LLP</h4>
                  </div>
                  <div className="body p-3 text-center ">
                    <p className="text-light">
                      A comparison of the popular types of business entity in
                      India, viz. Proprietorship vs Partnership vs LLP vs
                      Private Limited Company vs One Person Company.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card h-100  item2">
                  <div className="title text-center">
                    <h4 className="text-dark">
                      <strong> Partnership Firm</strong>
                    </h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light">
                      Guide to partnership firm in India including the various
                      types and features. The law relating to partnership firm
                      in India is prescribed in the Indian Partnership Act of
                      1932. This Act lays down the rights and duties of the
                      partners between themselves and other legal relations
                      between partners and third persons, which are incidental
                      to the formation of a partnership.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card   item3 h-100 ">
                  <div className="title text-center ">
                    <h4 className="text-dark">Inter-state vs Intra-state</h4>
                  </div>
                  <div className="body p-3  ">
                    <p className="text-light">
                      The meaning of interstate and intrastate supply under GST
                      with illustrations. In GST, the terms interstate and
                      intrastate have tremendous significance in the
                      determination of IGST, CGST or SGST. Interstate supply
                      attracts IGST, while intrastate supply attracts CGST and
                      SGST.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-4 mb-4">
              <div className="col-md-4">
                <div className="card  item4 h-100 ">
                  <div id="title" className="title text-center">
                    <h4 className="text-dark"> GST Invoice Format</h4>
                  </div>
                  <div className="body p-3">
                    <p className="text-light ">
                      Procedure to create a GST invoice as per the GST Act or
                      download a sample GST invoice as per GST Invoice Rules.
                      Under GST, GSTIN of the supplier is mandatorily required
                      to be mentioned in the tax invoice along with a
                      consecutive serial number that is unique for each
                      financial year.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card  h-100 item5">
                  <div className="title text-center">
                    <h4 className="text-dark"> GST Composition scheme</h4>
                  </div>
                  <div className="body p-3 mb-4">
                    <p className="text-light">
                      GST composition scheme helps small businesses with
                      turnover of less than Rs.150 lakhs by simplifying the GST
                      tax return filing procedure and allowing quarterly filing
                      of GST returns.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card  item6  h-100">
                  <div className="title text-center ">
                    <h4 className="text-dark"> GST return filing</h4>
                  </div>
                  <div className="body p-3 ">
                    <p className="text-light">
                      File GST return online through Lopamudra with GST Expert
                      Support. You can prepare and file GSTR-1, GSTR-2, GSTR-3
                      and GSTR-4 return online lpcs. In addition to filing GST
                      returns, you can also issue GST invoices and record
                      purchases on LEDGERS to automatically file GST returns.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>---------*/}

        <section>
          <div className="faqs">
            <div className="container py-3">
              <div className="row">
                <div className="col-sm-4 col-12  w-50 pt-5 align-center d-flex">
                  <img
                    className="img-fluid embeded-image"
                    src={FAQImg1}
                    alt="Lopamudra"
                  />
                </div>

                <div className="col-sm-8 col-12 w-50 text-fluid">
                  <h1 className="pb-3 text-light secu">
                    FAQs on USA company Registration
                  </h1>
                  <h5 className="pb-4">Get answer to all your quiries</h5>

                  <div className="myBox shadow p-3 mb-3 ">
                    <ul>
                      <li className="col-lg-12 faq_par">
                        <h4> What is a sole proprietorship??</h4>
                        <p className="text-light">
                          A sole proprietorship qualifies as a company, but it
                          only has one owner and isn’t required to register with
                          state enemies. Beginning a sole proprietorship is
                          extremely simple and doesn’t require a formal filing
                          or paperwork process. All you have to do is simply go
                          into business.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Is GST mandatory for proprietorship??</h4>
                        <p className="text-light">
                          Sole Proprietorship Registration through GST
                          Registration. ... Every registered business has to
                          compulsorily collect the tax from the customers and
                          file the GST returns. If a sole proprietor has a
                          turnover of less than Rs. 20 Lakhs, it is not
                          mandatory for him to get registered and collect GST.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>How many owners are in a proprietorship?</h4>
                        <p className="text-light">
                          A proprietorship can legally have only one owner.
                          That's because the business serves as part of the
                          business owner's personal identity and vice versa.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>What is the advantage of proprietorship?</h4>
                        <p className="text-light">
                          One of the functional advantages of sole
                          proprietorships is that they are easier to set up than
                          other business entities. A person becomes a sole
                          proprietor simply by running a business. Another
                          functional advantage of a sole proprietorship is that
                          the owner maintains 100% control and ownership of the
                          business.
                        </p>
                      </li>
                      <li className="col-lg-12 faq_par">
                        <h4>Still, have Confusion ?</h4>
                        <p className="text-light">
                          Don’t worry!! we are here to help you .Get in touch
                          with our team to get all your queries resolved. Write
                          to us at info@lopamudracs.com or 9966534699
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default USA;
