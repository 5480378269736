import React from "react";
class Newsletter extends React.Component {
  render() {
    return (
      <div>
        <section id="subscribe" className="section   py-5 ">
          <div className="container col-md-9 smaller align-center py-3  d-flex">
            <div
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              <div className="row">
                {" "}
                <div className="col-12 col-md-6 m-md-0 intro ">
                  <span className="pre-title m-0">Newsletter</span>
                  <h2 className="text-light">
                    <span className="bgc">&nbsp;Know&nbsp;</span> First
                  </h2>
                  <p className="text-light">
                    Follow closely and receive content about our company and the
                    news of the current market.
                  </p>
                </div>
                <div className="col-12 col-md-6 shadow p-3  ">
                  <form>
                    <div className=" m-auto items">
                      <input
                        id="inputs"
                        type="text"
                        className="form-control mb-4 bg-dark "
                        placeholder=" Name "
                      />

                      <input
                        id="inputs"
                        type="text"
                        className="form-control mb-4 bg-dark"
                        placeholder="Email"
                      />

                      <button id="sees" type="submit" className="btn  mb-4">
                        SUBSCRIBE
                      </button>
                    </div>
                  </form>
                </div>
              </div>{" "}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Newsletter;
