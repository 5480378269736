import React from "react";
import TECHImg from "../../assets/images/TECH.jpg";
import DRGImg from "../../assets/images/DRG.jpg";
import CHEMImg from "../../assets/images/CHEM.jpg";
import GRImg from "../../assets/images/GR.jpg";
import TOBImg from "../../assets/images/TOB.jpg";
import BPOImg from "../../assets/images/BPO.jpg";
import TELImg from "../../assets/images/TEL.jpg";
import RETImg from "../../assets/images/RET.jfif";
import OTImg from "../../assets/images/OT.jfif";
import INdImg from "../../assets/images/INd.jpg";
class Industries extends React.Component {
  render() {
    return (
      <div>
        <section id="Industries" className="py-5">
          <div className="container ">
            {" "}
            <span className="pre-title3 m-auto m-md-0">We like what we do</span>
            <div data-aos="fade-up">
              <h2 className="py-3">
                <span className="featured ">
                  <span className="bgd">&nbsp;Industries&nbsp;</span>
                </span>{" "}
                We Serve
              </h2>
            </div>
            <h1 className="text-center "> </h1>
            <div className="row ">
              <div className="col-md-4 col-sm-6 text-center">
                <div className="services-box ">
                  <img
                    src={TECHImg}
                    className="embed-responsive"
                    alt="Lopamudra"
                  ></img>
                  <h4 className="text-light py-2">TECHNOLOGY</h4>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 text-center">
                <div className="services-box">
                  <img
                    src={DRGImg}
                    className="embed-responsive"
                    alt="Lopamudra"
                  ></img>
                  <h4 className="text-light py-2">PHARMA/SEEDS/R&D</h4>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 text-center">
                <div className="services-box">
                  <img
                    src={CHEMImg}
                    className="embed-responsive"
                    alt="lopamudra"
                  ></img>
                  <h4 className="text-light py-2">CHEMICAL</h4>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-4 col-sm-6 text-center">
                <div className="services-box">
                  <img
                    src={GRImg}
                    className="embed-responsive"
                    alt="lopamudra"
                  ></img>
                  <h4 className="text-light py-2">FMCG</h4>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 text-center">
                <div className="services-box">
                  <img
                    src={TOBImg}
                    className="embed-responsive"
                    alt="lopamudra"
                  ></img>
                  <h4 className="text-light py-2">TOBACCO</h4>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 text-center">
                <div className="services-box">
                  <img
                    src={BPOImg}
                    className="embed-responsive"
                    alt="lopamudra"
                  ></img>
                  <h4 className="text-light py-2">BPO</h4>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-4 col-sm-6 text-center">
                <div className="services-box">
                  <img
                    src={TELImg}
                    className="embed-responsive"
                    alt="lopamudra"
                  ></img>
                  <h4 className="text-light py-2">TELECOMMUNICATION</h4>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 text-center">
                <div className="services-box">
                  <img
                    src={RETImg}
                    className="embed-responsive"
                    alt="lopamudra"
                  ></img>
                  <h4 className="text-light py-2">RETAIL TRADE</h4>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 text-center">
                <div className="services-box ">
                  <img
                    src={INdImg}
                    className="embed-responsive "
                    alt="lopamudra"
                  ></img>
                  <h4 className="text-light py-2">OTHERS</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Industries;
